const firebaseConfig = {
    // apiKey: "AIzaSyBoSZIkufwcZzuRmSwqzEYTg2LO9aawVuQ",
    // authDomain: "elmediodemedios.firebaseapp.com",
    // databaseURL: "https://elmediodemedios.firebaseio.com",
    // projectId: "elmediodemedios",
    // storageBucket: "elmediodemedios.appspot.com",
    // messagingSenderId: "1041306826347",
    // appId: "1:1041306826347:web:01e8299e58afd4379fe32e",
    // measurementId: "G-QPEGKGLGZB",

    //DEVELOPMENT
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

export default firebaseConfig;