import React, { useState } from "react";
import styled from "styled-components";

import { Hr } from "../modules";
import ModalArticulo from "./ModalArticulo";


const OtroArticuloCont = styled.div`
  text-decoration: none;
  height: 120px;
  max-width: 800px;
  width:100%;
  margin: 0 auto;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .otroCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    align-items:center;
    justify-content:space-between;
    .porcentaje{
      width:calc(100% - 105px);
      height: 80%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content:space-between;
      .title{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        align-items:center;
        h3{
          font-family: DM Serif Display;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 22px;
          text-align:left;
        }
      } 
    }
    .image{
      height:90px;
      width:90px;
      position:relative;
      img{
        width:100%;
        height:100%;
        object-fit:cover;
        border-radius:5px;
      }
    }
  }
`;
const ArticuloPie = ({ title, articulo, section }) => { 
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <OtroArticuloCont>
      <div className="otroCont" onClick={() => setShowModal(true)}>
          <div className="porcentaje">
            <div className='title'>
              <h3>{title}</h3>
            </div>
          </div>
          <div className="image">
            <img src={articulo.photoURL} alt=""/>
          </div>
        </div>
        <Hr/>
      </OtroArticuloCont>
      <ModalArticulo
        img={articulo.photoURL}
        titulo={title}
        cuerpo={articulo.cuerpo}
        state={showModal}
        setSate={setShowModal}
        section={section}
      />
    </>
  );
};

export default ArticuloPie;
