import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";

import { RegisterAltContainer } from '../../styles'
import Input from "../auth/Input";
import InputPass from "../auth/InputPass";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { signupuid } from "../../ModuloFirebaseCliente/SignUpAndLogin";
import { useHistory } from "react-router-dom";
import STRIPE_SERVICE from "../../services/stripeServices";

const ModalRegister = () => {

  const history = useHistory();
  const [passwordError, setPasswordError] = useState(null);

  const { openStore, setItem } = useStorageSQLite();
  const validationRegister = Yup.object().shape({
    name: Yup.string().required("Ingresa tu nombre completo."),
    correo: Yup.string()
      .required("Ingresa tu correo.")
      .email("El correo no es válido."),
    password: Yup.string()
      // .min(6, 'Password must be at least 6 characters')
      .required("Ingresa una contraseña."),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden. ")
      .required("Ingrese una contraseña de confirmación."),
    terminos: Yup.bool().oneOf([true], "Accepta los Ts & Cs"),
  });

  const {
    handleSubmit: handleSubRegist,
    register: registerRegist,
    errors: errorsRegist,
    reset: resetRegist,
  } = useForm({
    resolver: yupResolver(validationRegister),
  });

  const submitRegister = (data) => {
    console.log(data)
    if (data.password !== data.passwordConfirm) {
      setPasswordError("Las contraseñas no coinciden")
    } else if (!/(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{6,}/g.test(data.password)) {
      setPasswordError("Mínimo seis carácteres, una mayúscula y un número.")
    } else {
      signupuid(data.name, data.correo, data.password)
        .then(async (res) => {
          await STRIPE_SERVICE.createCustomer(res.uid);
          const resOpen = await openStore({});
          if (resOpen) {
            await setItem("correo", data.correo);
            await setItem("nombre", data.name);
            await setItem("token", res.token);
            await setItem("uid", res.uid);
            history.push('/membership');
          }
        })
        .catch((e) => {
          if (e.code === "auth/email-already-in-use") {
            alert("El correo ya ha sido registrado");
          }
        });
    }
  };
  
  return (
    <RegisterAltContainer>
      <div className="back">
        <BackButton onClick={() => history.push('/')} />
      </div>
      <div className="content">

        <h3>Regístrate</h3>


        <form
          className="modalRegisterCont-body-inputs"
          onSubmit={handleSubRegist(submitRegister)}
          onReset={resetRegist}
        >
          <Input
            name="name"
            placeholder="Ingresa tu correo "
            title="Correo"
            refT={false}
            register={registerRegist()}
            error={errorsRegist.name}
            textError={errorsRegist.name?.message}
          />
          <Input
            name="correo"
            placeholder="Ingresa tu correo"
            title="Confirmar correo"
            type="email"
            register={registerRegist()}
            refT={false}
            error={errorsRegist.correo}
            textError={errorsRegist.correo?.message}
          />
          <InputPass
            name="password"
            placeholder="Ingresa tu contraseña"
            title="Contraseña"
            refT={false}
            register={registerRegist()}
            error={passwordError}
            textError={passwordError}
          />
          <InputPass
            name="passwordConfirm"
            placeholder="Confirma tu contraseña"
            title="Confirma tu contraseña"
            refT={false}
            register={registerRegist()}
            error={errorsRegist.passwordConfirm}
            textError={errorsRegist.passwordConfirm?.message}
          />
          {passwordError === 'Las contraseñas no coinciden' && <p className='error'> {passwordError} </p>}
          <div className="terms">
            <input
              className={errorsRegist.terminos && "error"}
              type="checkbox"
              name="terminos"
              ref={registerRegist()}
            />
            <p>He leído y acepto los
              <span className="remName" onClick={() => history.push('/terminos')}>
                Términos y Condiciones
              </span>
            </p>
          </div>
          <Button />
        </form>
      </div>
      <br />
    </RegisterAltContainer>
  );
};

export default ModalRegister;
