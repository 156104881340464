import React from 'react';
import { IonModal, IonContent } from "@ionic/react";

import { ModalSuccessCont } from "../../styles";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";

import { ReactComponent as IconSuccess } from "../../assets/icons/successIcon.svg";

const ModalSuccessPass = ({ navegation, setNavegation }) => {
  return(
    <IonContent>
      <IonModal
        isOpen={navegation === "successPass" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalSuccessCont>
          <div className="modalSuccessCont">
            <div className="modalSuccessCont-header">
              <BackButton onClick={() => setNavegation("login")} />
            </div>
            <div className="modalSuccessCont-body">
              <div className="modalSuccessCont-body-cont">
                <div className="modalSuccessCont-body-cont-icon">
                  <IconSuccess />
                </div>
                <div className="modalSuccessCont-body-cont-title">
                  <p>Tu contraseña se cambió exitosamente</p>
                </div>
              </div>
            </div>
          </div>
        </ModalSuccessCont>
      </IonModal>
    </IonContent>
  )
};

export default ModalSuccessPass;