import React from "react";
import styled from "styled-components";
import { Plugins } from "@capacitor/core";

import { ReactComponent as ArrowD } from "../../assets/icons/redArrowDown.svg";
import { ReactComponent as ArrowU } from "../../assets/icons/greenArrowUp.svg";

const IndicadorEconomicoCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 5%;
  .indicadorCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-width: 640px;
    margin: auto;
    &-tipo {
      text-align: right;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      width: 30%;
      line-height: 19px;
    }
    &-arrow {
      text-align: center;
      svg {
        width: 20px;
        height: 20px;
      }
      margin-left: 2%;
      margin-right: 5%;
    }
    &-price {
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      p{
        text-align: center;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        margin-left: 20px;
        width: 70px;
      }
    }
    &-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      text-align: center;
      cursor: pointer;
      p {
        line-height: 19px;
        margin-left: 40px;
        width: 65px;
        text-decoration: underline;
        color: #235789;
        font-size:12px;
      }
    }
  }
`;
const IndicadorEconomico = ({ tipo, aumento, price, link, fuente }) => {
  const { Browser } = Plugins;
  return (
    <IndicadorEconomicoCont>
      <div className="indicadorCont">
        <div className="indicadorCont-tipo">{tipo.toUpperCase()}</div>
        <div className="indicadorCont-arrow">
          {aumento ? <ArrowU /> : <ArrowD />}
        </div>
        <div className="indicadorCont-price"><p>{price}</p></div>
        <div className="indicadorCont-link">
          <p
            onClick={async () => {
              await Browser.open({
                url: link ? link : "https://es-us.finanzas.yahoo.com/",
              });
            }}
          >
            {fuente ? fuente : "YFinance"}
          </p>
        </div>
      </div>
    </IndicadorEconomicoCont>
  );
};

export default IndicadorEconomico;
