import styled from "styled-components";
import { colors, fontSize } from "../utils";
import UnCheckedPng from "../assets/icons/unchecked.png";
import CheckedPng from "../assets/icons/checked.png";

export const NavbarCont = styled.div `
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width:100%;
  .nav {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 98;
    padding: 0 5%;
    background: ${colors.white};
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
    -webkit-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    width: 100%;
    height: 60px;
    border-radius: 0 0 12px 12px;
    .titulo {
      p {
        font-family: DM Serif Display;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .logo {
      width: 30px;
      height: 23px;
    }
    .propfile {
      width: 26px;
      height: 26px;
    }
  }
  @media (min-width: 768px) {
    .nav {
      padding: 0 10%;
    }
  }
  @media (min-width: 1025px) {
    width: ${e => e.wd ? 'calc(100% - 144px)' : '100%'};
    margin-left: ${e => e.wd ? '144px' : '0'};
    .nav{
      width: ${e => e.wd ? 'calc(100% - 144px)' : '100%'};
    }
  }
`;

export const ButtonStoreCont = styled.button `
  display: flex;
  flex-direction: row;
  background: ${colors.grey};
  border-radius: 2px;
  border: 1px solid ${colors.grey};
  cursor: pointer;
  padding: 10px;
  min-width: 175px;
  align-items: center;
  img {
    width: 30px;
  }
  .textCont {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .btext {
      margin: 0;
      font-size: 11px;
      color: ${colors.white};
      text-align: left;
    }
    .btitle {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      color: ${colors.white};
      text-align: left;
    }
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 480px) {
    min-width: 45%;
    img {
      width: 26px;
    }
    .textCont {
      .btitle {
        font-size: 17px;
      }
    }
  }
`;

export const OptionCont = styled.div `
  background: ${colors.yellowLight};
  min-height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
  .opinionSlide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 566px;
    .textSlide {
      font-family: "Crimson Pro";
      font-size: 28px;
      text-align: justify;
      width: 100%;
    }
    .nameSlide {
      margin-top: 30px;
      text-align: right;
      width: 100%;
      font-size: 16px;
    }
  }
  @media (max-width: 1024px) {
    height: 166px;
    .opinionSlide {
      max-width: 498px;
    }
  }
  @media (max-width: 768px) {
    .opinionSlide {
      max-width: 340px;
    }
  }
  @media (max-width: 480px) {
    height: 125px;
    padding: 0 5%;
    .opinionSlide {
      max-width: 195px;
      .textSlide {
        font-size: 14px;
      }
      .nameSlide {
        font-size: 12px;
      }
    }
  }
`;

export const FooterContMobile = styled.footer `
  display: flex;
  background: ${colors.grey};
  min-height: 174px;
  padding: 20px 5%;
  .footerCont {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    &-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      &-links {
        display: flex;
        flex-direction: column;
        width: 60%;
        height: 100%;
        a {
          text-decoration: none;
          color: ${colors.white};
          font-size: 14px;
          margin-right: 0px;
          margin-bottom: 20px;
          text-align: left;
        }
      }
      &-redes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        height: 100%;
        .group {
          display: flex;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
      }
    }
    &-copyR {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: ${colors.white};
        margin-right: 20px;
        font-size: 11px;
        text-align: right;
      }
    }
  }
`;

export const FooterCont = styled.footer `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: "${colors.grey}";
  min-height: 174px;
  padding: 0 10%;
  .footerCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    &-links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      a {
        text-decoration: none;
        color: "${colors.white}";
        font-size: 14px;
        margin-right: 20px;
      }
    }
    &-redes {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      svg {
        cursor: pointer;
        margin-right: 20px;
      }
    }
    &-copyR {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      p {
        color: "${colors.white}";
        margin-right: 20px;
        font-size: 13px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0 5%;
    .footerCont {
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }
`;

export const BackButtonCont = styled.div `
  .backButtonCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 12px;
    }
    &-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #235789;
    }
  }
`;

export const ShareButtonCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  .shareButtonCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    svg {
      width: 18px;
      height: 18px;
    }
    &-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #235789;
    }
  }
`;

export const TargetCont = styled.div `
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 22px;
  .targetCont {
    display: flex;
    background: ${colors.yellowLight};
    flex-direction: column;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding: 3px 5%;
    p {
      text-align: left;
      font-weight: bold;
      font-family: Crimson Pro;
      text-transform: ${(props) => (props.caps ? "uppercase" : "none")};
      font-size: ${fontSize.titleSmall};
      margin: 0px auto;
      max-width: 640px;
      width: 100%;
    }
  }
`;

export const EncabezadoCont = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  .encabezadoCont {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 3px 5%;
    min-height: 80px;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 149.63%;
      text-align: left;
      margin: 0 16px;
      width: 100%;
    }
    &-link {
      display: flex;
      width: 120px;
      &-image {
        width: 70px;
        min-width: 70px;
        height: 55px;
        border-radius: 2px;
      }
      p {
        display: flex;
        color: ${colors.blueDark};
        font-size: 12px;
        text-align: right;
        font-weight: 600;
        width: 100%;
        justify-content: flex-end;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 768px) {
    .encabezadoCont {
      max-width: 640px;
      margin: auto;
      padding: 0;
      font-size: ${(props) => props.fontSizeValue};
    }
  }
  
`;

export const SemaforoArticuloCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;-webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  .semaforoActiculoCont {
    display: -webkit-box;
    display: -ms-flexbox;
    position:relative;
    display: flex;
    height: 70px;
    padding: 0 5%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    &-arrow {
      position: absolute;
      top: 50%;
      height: 20px;
      right: 20px;
      color: ${colors.blueDark};
      svg {
        height: 20px;
        -webkit-transform: translate(0px, -50%);
        -ms-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
      }
    }
    &-span {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background: ${(props) => props.span};
    }
    &-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width:100%;
      h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        line-height: 149.63%;
        font-size: 15px;
        margin-left: 41px;
        text-align:left;
        margin-right:20px;
        width:90%;
      }
    }
  }
  @media (min-width: 768px) {
    .semaforoActiculoCont {
      padding: 0;
    }
  }
`;

export const LoginCont = styled.div `
  height: 100vh;
  width: 100vw;
  padding: 20px 5% 20px 5%;
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  flex-direction: column;
  -webkit-box-orient: vertical;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .loginCont {
    display: flex;
    flex-direction: column;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    max-height: calc(100vh - 40px);
    width: 100%;
    justify-content: center;
    -ms-flex-pack: distribute;
    max-width: 420px;
    margin: auto;
    overflow: auto;
    &-logo {
      width: 100%;
      svg {
        margin-bottom: 20px;
        width: 200px;
      }
    }
    &-logButtons {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 45px;
      .buttonTitle {
        font-family: Open sans;
        font-size: ${fontSize.textRegular};
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 59px;
      }
      .buttonLog {
        align-self: center;
        border-radius: 6px;
        margin-bottom: 24px;
        max-width: 327px;
      }
      .loginCont-register {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 16.34px;
        }
        .registerLog {
          color: ${colors.mdmInteraction};
          font-size: 13px;
          font-weight: 600;
          line-height: 17.7px;
          margin-left: 5px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .loginCont {
      margin-top: 15px;
      &-logo {
        svg {
          width: 350px;
          min-height: 60px;
        }
      }
      &-logButtons {
        .buttonTitle {
          font-size: ${fontSize.textMedium};
          line-height: 24.51px;
          margin-bottom: 80px;
        }
        .buttonLog {
          height: 50px;
          max-width: 638px;
          margin-bottom: 28px;
        }
        .loginCont-register {
          margin-top: 80px;
          p {
            font-size: ${fontSize.textBig};
            margin-bottom: 10px;
          }
          .registerLog {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .loginCont {
      display: flex;
      flex-direction: row;
      margin: 0;
      max-width: inherit;
      &-logo {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        padding-right: 30px;
        svg {
          height: 50px;
          width: 350px;
        }
      }
      &-logButtons {
        width: 50vw;
        .buttonTitle {
          margin-bottom: 50px;
          font-size: ${fontSize.textMedium};
          line-height: 24.51px;
        }
        .buttonLog {
          height: 55px;
          max-width: 468px;
          margin-bottom: 30px;
        }
        .loginCont-register {
          margin-top: 50px;
          p {
            font-size: ${fontSize.textMedium};
            margin-bottom: 5px;
          }
          .registerLog {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-height: 568px) {
    .loginCont {
      &-logButtons {
        margin: 0;
      }
    }
  }
`

export const ModalRemenberPassCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top));
  .modalRemenberPassCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 80px;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 100%;
      padding: 0 5%;
      width: 100%;
      max-width: 580px;
      margin: auto;
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 120px;
        h3 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
        }
      }
      &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 120px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 149.63%;
        }
      }
      &-inputs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        height: 80px;
        .inputNumber {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 52px;
          width: 52px;
          input {
            height: 100%;
            width: 100%;
            font-size: 48px;
            &:focus {
              outline: none;
            }
          }
        }
      }
      &-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 40px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #235789;
          text-decoration-line: underline;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalPassCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const ModalLoginCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  .modalLogCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    &-header {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      -webkit-box-align: center;
      -webkit-box-pack: justify;
      align-items: center;
      background: #fff;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      height: 80px;
      justify-content: space-between;
      margin-top: 40px;
      min-height: 80px;
      padding: 0 5%;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
    }
    &-body {
      -ms-flex-direction: column;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      left: 0;
      margin: auto;
      margin-top: 45px;
      max-height: calc (100vh - 80px);
      max-width: 580px;
      overflow: auto;
      padding: 0 5% 20px 5%;
      position: fixed;
      right: 0;
      top: 80px;
      width: 100%;
      &-title {
        -ms-flex-align: center;
        -ms-flex-direction: column;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -webkit-box-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        min-height: 100px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: black;
        }
        h3 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
        }
      }
      &-inputs {
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        height: 100%;
        min-height: 500px;
        .inputForm {
          margin-bottom: 15px;
          width: 100%;
        }
        &-rem {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          max-width: 327px;
          margin-bottom: 63px;
          width: 100%;
          .remName {
            margin-left: 5px;
            color: #235789;
            -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
            cursor: pointer;
          }
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            width: auto;
            color: #323232;
          }
          input[type="checkbox"] {
            margin-right: auto;
            height: 18px;
            width: 18px;
            &:before {
              content: "";
              height: 18px;
              width: 18px;
              display: inline-block;
              background: white url("${UnCheckedPng}") center center no-repeat;
              background-size: cover;
            }
            &:checked:before {
              content: "";
              height: 18px;
              width: 18px;
              background: white url("${CheckedPng}") center center no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .modalLogCont {
      &-header {
        padding: 0 10%;
      }
      &-body {
        &-title {
          margin: 80px 0;
        }
        &-inputs {
          &-rem {
            .remName {
              margin-bottom: 90px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .modalLogCont {
      &-header {
        padding: 0 10%;
      }
      &-body {
        &-title {
          margin: 60px 0 40px;
          p {
            font-size: 18px;
          }
        }
        &-inputs {
          &-rem {
            .remName {
              font-size: 14px;
              margin-bottom: 90px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

export const ModalRegisterCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  .modalRegisterCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height:100%;
    width: 100%;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      overflow: auto;
      padding: 0 5% 0px 5%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: calc(100vh - 80px);
      width: 100%;
      max-width: 580px;
      margin: auto;
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 60px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #323232;
        }
      }
      &-inputs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-height: 600px;
        .inputForm {
          margin-bottom: 10px;
        }
        &-rem {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          width: 100%;
          margin-bottom: 44px;
          .remName {
            color: #235789;
            margin-left: 5px;
            -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
            cursor: pointer;
          }
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            width: auto;
            color: #323232;
          }
          .error {
            &:before {
              -webkit-box-shadow: 0px 0px 5px 0 rgba(219, 44, 43, 1);
              box-shadow: 0px 0px 5px 0 rgba(219, 44, 43, 1);
            }
          }
          input[type="checkbox"] {
            margin-right: auto;
            height: 18px;
            width: 18px;
            position: relative;
            &:before {
              content: "";
              height: 18px;
              width: 18px;
              display: inline-block;
              background: white url("${UnCheckedPng}") center center no-repeat;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
            }
            &:after {
              content: "";
              height: 20px;
              width: 20px;
              display: inline-block;
              background: white;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              -webkit-transform: translate(-2px, -2px);
              -ms-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
              z-index: 1;
            }
            &:checked:before {
              content: "";
              height: 18px;
              width: 18px;
              background: white url("${CheckedPng}") center center no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalRegisterCont {
      &-header {
        padding: 0 10%;
      }
      &-body{
        padding: 0 0% 0px 0%;
      }
    }
  }
`;

export const RegisterAltContainer = styled.div `
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-top));
  overflow: scroll;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  align-items: center;
  .back {
    position: fixed;
    z-index:100;
    top: 0;
    left:0;
    right:0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items:center;
    padding: 0px 5%;
    background-image: linear-gradient(
      rgba(255,255,255,1), 
      rgba(255,255,255,1), 
      rgba(255,255,255,1),
      rgba(255,255,255,0));
  }
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
    margin: 0;
  }
  .content {
    padding: 40px 5% 40px;
    width: 100%;
    max-width: 700px;
    position:absolute;
    top:80px;
    left:0;
    right:0;
    overflow:auto;
    margin:auto;
    h3 {
      font-size: ${fontSize.titleSmall};
      margin-bottom: 20px;
    }
    form {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
        .inputForm {
          min-width: 300px;
          max-width: 330px;
          margin-bottom: 10px;
        }
        .terms {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: center;
          width: 100%;
          margin-bottom: 44px;
          .remName {
            color: #235789;
            margin-left: 5px;
            -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
            cursor: pointer;
          }
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            width: auto;
            color: #323232;
          }
          .error {
            &:before {
              -webkit-box-shadow: 0px 0px 5px 0 rgba(219, 44, 43, 1);
              box-shadow: 0px 0px 5px 0 rgba(219, 44, 43, 1);
            }
          }
          input[type="checkbox"] {
            margin-right: 5px;
            height: 18px;
            width: 18px;
            position: relative;
            &:before {
              content: "";
              height: 18px;
              width: 18px;
              display: inline-block;
              background: white url("${UnCheckedPng}") center center no-repeat;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
            }
            &:after {
              content: "";
              height: 20px;
              width: 20px;
              display: inline-block;
              background: white;
              background-size: cover;
              position: absolute;
              top: 0;
              left: 0;
              -webkit-transform: translate(-2px, -2px);
              -ms-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
              z-index: 1;
            }
            &:checked:before {
              content: "";
              height: 18px;
              width: 18px;
              background: white url("${CheckedPng}") center center no-repeat;
              background-size: cover;
            }
          }
        }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .content {
      margin-top: 50px;
      h3 {
        margin-bottom: 80px;
      }
      form {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        .inputForm {
          min-width: 300px;
          max-width: 330px;
          margin-bottom: 10px;
        }
        .terms {
          margin-bottom: 120px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .content {
      margin-top: 30px;
      h3 {
        font-size: 18px;
      }
      form {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        .inputForm {
          min-width: 300px;
          max-width: 370px;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export const ModalTerminosCont = styled.div `
  .modalTerminosCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - env(safe-area-inset-top));
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      padding: 0 5%;
      height: 100%;
      width: 100%;
      max-width: 580px;
      margin: auto;
      overflow: auto;
      &-title {
        h3 {
          font-size: ${fontSize.titleSmall};
          margin-bottom: 20px;
        }
      }
      &-text {
        p {
          text-align: left;
          margin-bottom: 15px;
          font-size: ${fontSize.textRegular};
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalTerminosCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const TerminosAltContainer = styled.div `
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-top));
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  .back {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100px;
    display: flex;
    padding: 30px 5%;
    background-image: linear-gradient(
      rgba(255,255,255,1), 
      rgba(255,255,255,1), 
      rgba(255,255,255,1),
      rgba(255,255,255,0));
  }
  .content {
    padding: 90px 5% 90px;
    max-width: 700px;
    .main-title {
      font-size: ${fontSize.titleSmall};
      margin-bottom: 20px;
    }
    h3 {
      font-size: ${fontSize.titleSmall};
      text-align: left;
      margin-bottom: 20px;
    }
    p {
      text-align: left;
      margin-bottom: 15px;
      font-size: ${fontSize.textRegular};
    }
  }
`

export const ModalMembershipCont = styled.div `
.modalMembershipCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      min-height:80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: flex-start;
      padding: 0 5%;
      position: fixed;
      top: 0;
      background: white;
      z-index: 100;
      width: 100%;
      // @media (min-width: 768px){
      //   margin-left:-316px;
      //   position: absolute;
      //   flex-direction:column;
      //   &-bar{
      //     height: 80px;
      //     width: 100vw;
      //     background-color: red;
      //   }
      // }
    }
    &-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      min-height: 530px;
      margin: 0 auto;
      position:relative;
      top:80px;
      @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        top:150px;
        max-width: 80vw;
        &-desktopDescription{
          display: flex; 
          align-items: center;
          justify-content: center;
          width: 80%;
          margin-left: -340px;
        }
        &-desktopPrice{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 80%;
        }
      }
      @media (min-width: 1440px) {
        display: flex;
        flex-direction: row;
        top:150px;
        max-width: 80vw;
        &-desktopDescription{
          display: flex; 
          align-items: center;
          justify-content: center;
          width: 80%;
          margin-left: -570px;
        }
        &-desktopPrice{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 80%;
        }
      }
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: ${fontSize.titleSmall};
          line-height: 19px;
          color: #323232;
        }
      }
      &-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        &-target {
          @media (max-width: 640px){
            margin-left: -20px;
            width: 80%;
          }

          @media (min-width: 640px){
            margin-left: -20px;
            width: 80%;
          }
              
          @media (min-width: 768px){
            margin-left: -200px;
            width: 70%;
          }
            
          @media (min-width: 1024px){
            margin-left: -200px;
            width: 100%;
          }
          @media (min-width: 1040px){
            margin-left: -200px;
            width: 60%;
          }
          background: ${colors.yellowLight};
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: flex-end;
          height: 59px;
          margin-bottom: 10px;
          border-radius: 0px 90px 90px 0px;
          p {
            margin-right: 20px;
            font-family: DM Serif Display;
            font-size: 18px;
            @media (min-width: 640px){
              font-size: 24px;
            }
              
            @media (min-width: 768px){
              font-size: 24px;
            }
            
            @media (min-width: 1024px){
              font-size: 24px;
            }
            font-weight: 700;
            font-style: normal;
            line-height: 24.68px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            color: #323232;
          }
        }
        &-desc {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0 7%;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 45px;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          SVG {
            height: 22px;
            width: 22px;
          }
          p {
            text-align: left;
            font-family: Open Sans;
            font-size: ${fontSize.textSmall};
            @media (min-width: 1024px){
              font-size: 18px;
            }
            font-weight: 600;
            line-height: 16.34px;
            color: #323232;
            width: 85%;
            font-style: italic;
          }
        }
        &-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 0 5%;
          margin-bottom: 15px;
          margin-top: 25px;
          p {
            font-family: Open Sans;
            font-size: 12px;
            @media (min-width: 1024px){
              font-size: 18px;
            }
            font-weight: 400;
            line-height: 16.34px;
          }
        }
        &-check {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 30px;
          width: 100%;
          padding: 0 5%;
          
          span {
            margin: 0 10px;
            font-size: ${fontSize.textRegular};
            width: 80px;
            -webkit-transition: ease-in-out 0.4s;
            -o-transition: ease-in-out 0.4s;
            transition: ease-in-out 0.4s;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }
          .on {
            font-size: 18px;
            font-weight: bold;
            color: ${colors.blueDark};
          }
        }
        &-discont {
          height: 30px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19.07px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fa4443;
          animation: opacityDiscont 0.4s forwards;
        }
        &-price {
          height: 55px;
          width: 100%;
          padding: 0 5%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          span {
            font-size: 48px;
            font-weight: bold;
          }
          .price {
            width: 120px;
            text-align: right;
            transform: translate(-3px, 0);
          }
          .decimal {
            font-size: 18px;
            height: 100%;
            height: 45px;
          }
        }
      }
    }
    &-footer {
      display: flex;
      width: 100%;
      padding: 20px 5%;
      position:relative;
      bottom: 0;
      background: ${colors.white};
      &-button {
        width: 100%;
        max-width: 580px;
        margin: auto;
      }
    }
  }
  @media (min-width: 768px) {
    .modalMembershipCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
  @keyframes opacityDiscont {
    to {
      opacity: 1;
    }
  }
`;

export const ModalPaymentCont = styled.div `
  .modalPaymentCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - env(safe-area-inset-top));
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      padding: 0 5% 60px 5%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
      width: 100%;
      max-width: 580px;
      margin: auto;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: ${fontSize.titleSmall};
          line-height: 19px;
          color: #323232;
          .blueText {
            color: #235789;
          }
        }
      }
      &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 45px;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-size: ${fontSize.textRegular};
          color: #323232;
          .blueText {
            color: #235789;
            margin: 0 5px;
          }
        }
      }
      &-inputCont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 22px;
        .input {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          width: 100%;
          height: 72px;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          &-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            p {
              font-size: ${fontSize.textRegular};
            }
          }
          &-cont {
            border: 0.5px solid #868585;
            border-radius: 2px;
            height: 46px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            svg {
              height: 20px;
              width: 28px;
              margin: 0 16px 0 10px;
            }
            input {
              width: 90%;
              border: 0;
              font-family: Open Sans;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              text-align: left;
              font-size: 15px;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      .inputForm {
        margin-bottom: 22px;
      }
      &-inputsCont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 70px;
        .input {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          width: 48%;
          height: 72px;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          &-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            p {
              font-size: ${fontSize.textRegular};
            }
          }
          &-cont {
            border: 0.5px solid #868585;
            border-radius: 2px;
            height: 46px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            padding: 11px;
            input {
              width: 90%;
              border: 0;
              font-family: Open Sans;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              text-align: left;
              font-size: 15px;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      &-button {
      }
    }
  }
  @media (min-width: 768px) {
    .modalPaymentCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const ModalSuccessCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .modalSuccessCont {
    height: calc(100vh - env(safe-area-inset-top));
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
    }
    &-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0 5% 60px 5%;
      height: 100%;
      width: 100%;
      max-width: 580px;
      margin: auto;
      &-cont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        &-icon {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 20px;
        }
        &-title {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 20px;
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: ${fontSize.textRegular};
            line-height: 19px;
            text-align: center;
          }
        }
        &-text {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 20px;
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: ${fontSize.textRegular};
            text-align: center;
          }
        }
      }
      &-button {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }
  }
  @media (min-width: 768px) {
    .modalSuccessCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const ProfileCont = styled.div `
  .profileCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: calc(100vh - 80px - env(safe-area-inset-top));
    &-profile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      &-fotoCover {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 210px;
        width: 100%;
        max-width:520px;
        margin:auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding:0 5%;
        &-text{
          width:calc(100% - 156px);
          height:156px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          padding-left:10px;
          h3{
            font-family: DM Serif Display;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 33px;
            text-align:left;
          }
          span{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding:3px 9px;
            background: #EEEEEE;
            border-radius: 23px;
          } 
        }
        &-contButton {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          position: relative;
          width: 156px;
          height: 156px;
          border-radius: 8px;
          overflow: hidden;
          &-button {
            width: 156px;
            height: 156px;
            border-radius: 8px;
            left: 0;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            position: absolute;
            background: url("${(props) => props.imgFile}") center center
              no-repeat;
            background-size: cover;
            bottom: 0;
            .upload {
              position: absolute;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
            }
            input[type="file"] {
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
          &-img {
            background: url("${(props) => props.imgFile}") center center
              no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
      &-pass {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 70px;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 5%;
        &-cont {
          width: 100%;
          height: 100%;
          max-width: 520px;
          margin: auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          position: relative;
          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            path{
              fill:#323232;
            }
          }
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
      &-letra {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        height: 70px;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 5%;
        &-cont {
          width: 100%;
          height: 100%;
          max-width: 520px;
          margin: auto;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          position: relative;
          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            path{
              fill:#323232;
            }
          }
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
    &-cancelAcout {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 70px;
      width: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      &-cont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .profileCont {
      &-profile {
        &-fotoCover {
          padding:0;
          &-text{
            align-items:center;
            h3{

            }
            span{

            }
          }
        }
      }
    }
  }
`;

export const ModalTextCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top));
  width: 100%;
  .modalTextCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 80px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
      position:fixed;
      top:0;
      left:0;
      right:0;
      width:100%;
      z-index:100;
      background: ${colors.white};
    }
    &-body {
      /* display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; */
      display:block;
      padding: 0 5%;
      /* -ms-flex-pack: distribute; */
      /* justify-content: space-around; */
      /* height: calc(100vh - 140px); */
      width: 100%;
      max-width: 580px;
      margin: auto;
      margin-top:80px;
      &-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 10px 0;
        h3 {
          font-family: Crimson Pro;
          font-size: ${(props) =>
    props.fontS === "small"
      ? "24px"
      : props.fontS === "regular"
        ? "26px"
        : props.fontS === "big" && "28px"};
        }
      }
      &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 10px 0;
        p {
          text-align: left;
          font-size: ${(props) =>
    props.fontS === "small"
      ? "14px"
      : props.fontS === "regular"
        ? "16px"
        : props.fontS === "big" && "18px"};
          margin-bottom: 20px;
        }
      }
      &-textSmall {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 10px 0;
        p {
          text-align: center;
          font-size: ${(props) =>
    props.fontS === "small"
      ? "12px"
      : props.fontS === "regular"
        ? "14px"
        : props.fontS === "big" && "16px"};
        }
      }
    }
    &-footer {
      display: flex;
      width: 100%;
      height:80px;
      max-width: 580px;
      margin: auto;
      position:fixed;
      bottom:0;
      left:0;
      right:0;
      z-index:100;
      background: ${colors.white};
      &-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        align-items:center;
        &-button {
          width: 33.33%;
          height: 40px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          font-family: Crimson Pro;
          color: #868585;
        }
        .small {
          font-size: 22px;
        }
        .regular {
          font-size: 28px;
        }
        .big {
          font-size: 36px;
        }
        .on {
          color: #fa4443;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalTextCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const ModalPassCont = styled.div `
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top));
  .modalPassCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
      -webkit-box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
      box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
      height: 60px;
      border-radius: 0 0 12px 12px;
      h3{
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.03em;
      }
    }
    &-body {
      padding: 0 5%;
      height: 100%;
      width: 100%;
      max-width: 580px;
      margin: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: end;
      align-items:center;
      &-cont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items:center;
        &-text {
          height: 120px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
          }
          h3 {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
          }
        }
        .inputForm {
          margin-bottom: 40px;
          /* max-width:none;
          .inputCont{
            width:100%;
          } */
        }
      }
      .buttonNext {
        margin-bottom: 60px;
      }
    }
  }
  @media (min-width: 768px) {
    .modalPassCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

export const ButtonNewsCont = styled.div `
  display:flex;
  display: -webkit-box;
  display: -ms-flexbox;
  width:160px;
  margin-top:10px;
  margin-right:0px;
  &:nth-child(1){
    margin-right:auto;
  }
  .button{
    position:relative;
    background:${(props) => props.color};
    color:${(props) => props.colorText};
    width:160px;
    height:34px;
    border:0;
    border-radius:7px;
    padding:0 15px;
    cursor: pointer;
    &:focus{
      outline:none;
    }
    
    h3{
      text-align:left;
    }
    .svg-right{
      height: 16px;
      width: 8px;
      position: absolute;
      top:0;
      left:auto;
      bottom:0;
      right:5px;
      margin:auto;
      path {
        fill: ${(props) => props.colorText};
      }
    }
  }
  @media (min-width:768px){
    &:nth-child(1){
      margin-right:20px;
    }
  }
`;

export const NewsCont = styled.div `
  .newsCont {
    background: ${(props) => props.color};
    height: 38px;
    display: flex;
    padding: 0 5%;
    position: absolute;
    transition: ease-in-out 0.3s;
    top:0;
    right:${(props) => (props.modal ? 0 : '-100%')};
    width:100%;
    svg {
      height: 20px;
      width: 12px;
      position: absolute;
      transition: ease-in-out 0.3s;
      path {
        fill: ${(props) => props.colorText};
      }
    }
    .svg-right {
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: ${(props) => (props.modal ? 0 : 1)};
    }
    .svg-left {
      left: 5%;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: ${(props) => (props.modal ? 1 : 0)};
    }
    &-title {
      display: flex;
      align-items: center;
      margin-right: auto;
      transition: ease-in-out 0.3s;
      margin-left: ${(props) => (props.modal ? "50%" : 0)};
      transform: ${(props) =>
    props.modal ? "translate(-50%, 0)" : "translate(0, 0)"};
      h3 {
        color: ${(props) => props.colorText};
        font-family: Crimson Pro;
        font-size: 16px;
      }
    }
  }
  .newsModal {
    position: absolute;
    height: calc(100vh - 268px - env(safe-area-inset-top));
    width: 100%;
    height: calc(100% - 38px);
    background: white;
    z-index: 10;
    transition: ease-in-out 0.3s;
    transform: ${(props) =>
    props.modal ? "translate(0,0)" : "translate(100%, 0)"};
    overflow-y: auto;
    top:38px;
    right:0;
  }
  @media (min-width: 768px) {
    .newsCont {
      padding: 0 10%;
      .svg-right {
        right: 10%;
      }
      .svg-left {
        left: 10%;
      }
    }
  }
`;

export const AcordionButtonCont = styled.div `
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  .acordionCont {
    position: relative;
    background: ${(props) => (props.state ? "#FAFAFA" : "white")};
    top: 0;
    height: 70px;
    background: white;
    padding: 0 5%;
    display: flex;
    transition: 0.3s;
    align-items: center;
    svg {
      ellipse {
        fill: ${(props) => props.color};
      }
    }
    .arrow {
      transition: 0.3s;
      transform: ${(props) =>
    props.state ? "rotate(-90deg)" : "rotate(90deg)"};
      height: 20px;
      width: 12px;
      position: absolute;
      right: calc(5% + 3px);
      top: 0;
      bottom: 0;
      margin: auto;
      path {
        fill: #235789;
      }
    }
    &-title {
      width: 80%;
      margin-left: 18px;
      h4 {
        font-family: Crimson Pro;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
      }
    }
  }
  .acordionTextCont {
    transition: max-height 0.3s ease-out;
    max-height: ${(props) => (props.state ? props.heightText : "0")};
    overflow: hidden;
    &-text {
      padding: 10px 5%;
      p {
        overflow-wrap: break-word;
        text-align: justify;
        text-align: left;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 149.63%;
        list-style: disc outside none;
        display: list-item;
        margin-bottom: 15px;
        margin-left: 20px;
        max-width:820px;
      }
    }
  }
  @media (min-width: 768px) {
    .acordionCont {
      max-width: 640px;
      padding: 0 0%;
      margin: auto;
    }
    .acordionTextCont {
      &-text {
        padding: 10px 10%;
        p{
          margin: 15px auto;
        }
      }
    }
  }
`;

export const BtnSemaforoCont = styled.div `
  position:relative;
  height:20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .name{
    background: ${e => e.btnColor};
    position:absolute;
    top:0;
    left:0;
    padding:0px 7px;
    border-radius:10px;
    p{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 149.63%;
      color: white;
    }
  }
  .text{
    background: #eee;
    border-radius: 24px;
    margin-right: 15px;
    transition:0.4s;
    opacity: ${e => e.state ? '1' : '0'};
    height:100%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height:22px;
    p{
      transition:0.4s;
      margin-left:${e => e.state ? '90px' : '0'};
      margin-right:${e => e.state ? '20px' : '0'};
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 149.63%;;
      height:22px;
    }
  }
`;