import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();

const obtenerPie = (datos) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query pieDiaDeHoy($tipo: String) {
            pieDiaDeHoy(tipo: $tipo) {
              porcentaje
              articulo {
                titulo
                subtitulo
                cuerpo
                autor
                testigos
                palabrasClave
                cuerpo
                photoURL
                testigos
              }
            }
          }
        `,
                variables: datos,
            })
            .then((result) => {
                resolve(result.data.pieDiaDeHoy);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const obtenerPortadaporId = (data) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query portadaById($id: MongoID!) {
            portadaById(_id: $id) {
              _id
              panoramaNac
              panoramaInt
              photoUrl
              resumen
              categoria
            }
          }
        `,
                variables: data,
            })
            .then((result) => {
                resolve(result.data.portadaById);
            });
    });
};

const obtenerPortadaDiaDeHoy = (data) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query portadaDiaDeHoy($tipo: String) {
            portadaDiaDeHoy(tipo: $tipo) {
              panoramaNac
              panoramaInt
              destacado
              _id
            }
          }
        `,
                variables: data,
            })
            .then((result) => {
                resolve(result.data.portadaDiaDeHoy);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const destacadoDiaDeHoy = (data) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query portadaDiaDeHoy($tipo: String) {
            destacadoDiaDeHoy(tipo: $tipo) {
              titulo
              subtitulo
              cuerpo
              autor
              categoria
              photoURL
              palabrasClave
              testigos
              _id
            }
          }
        `,
                variables: data,
            })
            .then((result) => {
                resolve(result.data.destacadoDiaDeHoy);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export {
    obtenerPortadaporId,
    obtenerPie,
    obtenerPortadaDiaDeHoy,
    destacadoDiaDeHoy,
};