import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "swiper/swiper-bundle.css";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render( < Router / > , document.getElementById("mdm-app"));

// EVENT LISTENER TO DELETE CACHE AND GET LAST RELEASE
window.addEventListener("activate", function(event) {
    event.waitUntil(
        caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
            window.location.href = "./";
        })
    );
});

if ('serviceWorker' in navigator) {
    caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName);
        });
    });
}

serviceWorker.register();