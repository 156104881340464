import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import Encabezado from "../auth/Encabezado";
import { colors } from "../../utils";
import SlideTest from "../auth/SlideTest";
import { encabezadoDiaDeHoy } from "../../consumo_apollo_client/clienteEncabezado";
import contexto from "../../Contexto/contexto";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";

import { useHistory } from "react-router-dom";

const EncabezadosCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 190px - env(safe-area-inset-top));
  background: ${colors.white};
  overflow: auto;
  .encabezadosCont {
    display: block;
    div {
      .title {
        width: 100%;
        padding: 0 5%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        h3 {
          font-family: DM Serif Display;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 115.87%;
          text-transform: capitalize;
          height: 50px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .encabezadosCont {
      div {
        .title {
          max-width: 640px;
          margin: auto;
          padding: 0 20px;
        }
      }
    }
  }
  @media (min-width: 1025px) {
    height: calc(100vh - 110px - env(safe-area-inset-top));
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: center;
    padding: 0 10%;
  }
`;
const Encabezados = ({ refresh, setRefresh }) => {
  const history = useHistory();

  const { openStore, getItem, removeItem, clear } = useStorageSQLite();

  const [encabezados, setEncabezados] = useState(null);
  const [encabezadosInter, setEncabezadosInter] = useState(null);
  const interArr = [];
  const nacionalArr = [];
  const { appFontSize } = useContext(contexto);

  const logout = async () => {
    await removeItem("uid");
    await removeItem("token");
    await removeItem("tipoUsuario");
    await removeItem("correo");
    await removeItem("letra");
    await removeItem("img");
    await clear();
    history.push("/");
  };

  useEffect(() => {
    const validateSessionToken = async () => {
      await openStore({});
      const token = await getItem("sessionToken");
      const uid = await getItem("uid");
      if (refresh === true) {
        encabezadoDiaDeHoy()
          .then((res) => {
            if (res) {
              if (refresh) {
                setRefresh(false);
                res.forEach((e) => {
                  if (
                    e.nombre === "The New York Times / EU " ||
                    e.nombre === "The Washington Post " ||
                    e.nombre === "El País - España"
                  ) {
                    interArr.push(e);
                  } else {
                    nacionalArr.push(e);
                  }
                });
                setEncabezados([
                  {
                    name: "nacional",
                    encabezados: nacionalArr.sort(
                      (a, b) => a.indice - b.indice
                    ),
                  },
                ]);
                setEncabezadosInter([
                  {
                    name: "internacional",
                    encabezados: interArr.sort((a, b) => a.indice - b.indice),
                  },
                ]);
              }
            } else {
              if (refresh) {
                setEncabezados(null);
              }
            }
          })
          .catch((e) => {
            if (refresh) {
              setEncabezados(null);
            }
          });
      }
      var mounted = true;
      encabezadoDiaDeHoy({ Token: token, uid: uid })
        .then((res) => {
          if (res) {
            if (mounted) {
              res.forEach((e) => {
                if (
                  e.nombre === "The New York Times / EU " ||
                  e.nombre === "The Washington Post " ||
                  e.nombre === "El País - España"
                ) {
                  interArr.push(e);
                } else {
                  nacionalArr.push(e);
                }
              });
              setEncabezados([
                {
                  name: "nacional",
                  encabezados: nacionalArr.sort((a, b) => a.indice - b.indice),
                },
              ]);
              setEncabezadosInter([
                {
                  name: "internacional",
                  encabezados: interArr.sort((a, b) => a.indice - b.indice),
                },
              ]);
            }
          } else {
            if (mounted) {
              logout();
              // setEncabezados(null);
            }
          }
        })
        .catch((e) => {
          if (mounted) {
            setEncabezados(null);
          }
        });

      return function cleanup() {
        mounted = false;
      };
    };

    validateSessionToken();
    // eslint-disable-next-line
  }, [refresh]);

  if (encabezados == null && encabezadosInter == null)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <EncabezadosCont appFontSize={appFontSize}>
      <div className="encabezadosCont">
        {encabezados
          .sort((a, b) => a.indice - b.indice)
          .map((sect, o) => (
            <div
              key={o}
              style={{ minHeight: `${sect.encabezados.length * 71 + 20}px` }}
            >
              <div className="title">
                <h3> {sect.name} </h3>
              </div>
              {sect.encabezados.length === 0 ? (
                <SlideTest text={"No hay información para mostrar"} />
              ) : (
                sect.encabezados.map((enca, i) => (
                  <Encabezado
                    fontSizeValue={appFontSize}
                    img={enca.imagen}
                    title={enca.titular}
                    url={enca.link}
                    key={i}
                  />
                ))
              )}
            </div>
          ))}
      </div>
      <div className="encabezadosCont">
        {encabezadosInter
          ?.sort((a, b) => a.indice - b.indice)
          .map((sect, o) => (
            <div
              key={o}
              style={{ minHeight: `${sect.encabezados.length * 71 + 20}px` }}
            >
              <div className="title">
                <h3> {sect.name} </h3>
              </div>
              {sect.encabezados.length === 0 ? (
                <SlideTest text={"No hay información para mostrar"} />
              ) : (
                sect.encabezados.map((enca, i) => (
                  <Encabezado
                    fontSizeValue={appFontSize}
                    img={enca.imagen}
                    title={enca.titular}
                    url={enca.link}
                    key={i}
                  />
                ))
              )}
            </div>
          ))}
        {/* {encabezadosDummy.map((sect, o) => (
          <div key={o} style={{ minHeight: `${(sect.encabezados.length * 71) + 52}px` }}>
            <div className="title"> <h3> {sect.name} </h3> <Hr /> </div>
            {sect.encabezados.length === 0 ? (
              <SlideTest text={"No hay información para mostrar"} />
            ) : (
              sect.encabezados.map((enca, i) => (
                <Encabezado
                  fontSizeValue={appFontSize}
                  img={enca.img}
                  title={enca.title}
                  url={enca.url}
                  key={i}
                />
              ))
            )}
          </div>
        ))} */}
      </div>
    </EncabezadosCont>
  );
};

export default Encabezados;
