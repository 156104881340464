import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { IonModal, IonContent } from "@ionic/react";

import { ModalPassCont } from "../../styles";
import InputPass from "../auth/InputPass";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";
import { loginUserInstance } from "../../ModuloFirebaseCliente/SignUpAndLogin";
import { cambiarContraseña } from "../../ModuloFirebaseCliente/SignUpAndLogin";

const ModalPass = ({ navegation, setNavegation, getItem }) => {
  const [passLogin, setPassLogin] = useState(null);
  const validationSchema = Yup.object().shape({
    newPass: Yup.string().required("Ingresa una contraseña."),
    newPassConfirm: Yup.string()
      .oneOf([Yup.ref("newPass"), null], "Las contraseñas no coinciden. ")
      .required("Ingrese una contraseña de confirmación."),
  });
  const {
    handleSubmit: handleChangePass,
    register: registerChangePass,
    errors: errorsChangePass,
    reset: resetChangePass,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const submitChangePass = (data) => {
    cambiarContraseña(data.newPass)
      .then((res) => {
        setNavegation("success");
      })
      .catch(() => {
        alert("Error al cambiar contraseña");
      });
  };

  return (
    <>
      <IonContent>
        <IonModal
          isOpen={navegation === "pass" && true}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="fullscreen"
        >
          <ModalPassCont>
            <div className="modalPassCont">
              <div className="modalPassCont-header">
                <BackButton onClick={() => setNavegation("")} />
                <div className="title">
                  <h3>CAMBIAR CONTRASEÑA</h3>
                </div>
                <div className=""></div>
              </div>
              <div className="modalPassCont-body">
                <div className="modalPassCont-body-cont">
                  <div className="modalPassCont-body-cont-text">
                    <p>
                      Para poder cambiar tu contraseña primero ingresa tu
                      contraseña actual
                    </p>
                  </div>
                  <InputPass
                    name="password"
                    placeholder="Ingresa aquí tu contraseña"
                    title="Contraseña"
                    setValue={setPassLogin}
                    value={passLogin}
                  />
                </div>
                <Button
                  onClick={async () => {
                    loginUserInstance(await getItem("correo"), passLogin)
                      .then((u) => {
                        setNavegation("changePass");
                      })
                      .catch((e) => {
                        console.log(e);
                        alert("Contraseña incorrecta");
                      });
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </ModalPassCont>
        </IonModal>
      </IonContent>
      <IonContent>
        <IonModal
          isOpen={navegation === "changePass" && true}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="fullscreen"
        >
          <ModalPassCont>
            <div className="modalPassCont">
              <div className="modalPassCont-header">
                <BackButton onClick={() => setNavegation("pass")} />
                <div className="title">
                  <h3>CAMBIAR CONTRASEÑA</h3>
                </div>
                <div className=""></div>
              </div>
              <form
                className="modalPassCont-body"
                onSubmit={handleChangePass(submitChangePass)}
                onReset={resetChangePass}
              >
                <div className="modalPassCont-body-cont">
                  <div className="modalPassCont-body-cont-text">
                    <p>Porfavor ingresa tu nueva contraseña</p>
                  </div>
                  <InputPass
                    name="newPass"
                    placeholder="Ingresa aquí tu nueva contraseña"
                    title="Ingresa tu nueva contraseña"
                    refT={false}
                    register={registerChangePass()}
                    error={errorsChangePass.newPass}
                    textError={errorsChangePass.newPass?.message}
                  />
                  <InputPass
                    name="newPassConfirm"
                    placeholder="Ingresa aquí tu nueva contraseña"
                    title="Confirma tu nueva contraseña"
                    refT={false}
                    register={registerChangePass()}
                    error={errorsChangePass.newPassConfirm}
                    textError={errorsChangePass.newPassConfirm?.message}
                  />
                </div>
                <Button />
              </form>
            </div>
          </ModalPassCont>
        </IonModal>
      </IonContent>
    </>
  );
};

export default ModalPass;
