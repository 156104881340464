import React from "react";
import styled from "styled-components";

const SlideTestCont = styled.div`
  min-height: calc(100vh - 230px - env(safe-area-inset-top));
  width: ${props => props.ancho};
  background: ${(props) => props.bg};
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction:column;
  padding:10px 40px;
  overflow:auto;
  p {
    font-weight: bold;
    color:${props => props.color};
  }
`;
const SlideTest = ({ bg, text, ancho= '100%', color= 'black'}) => {
  return (
    <SlideTestCont bg={bg} ancho={ancho} color={color}>
      <p>{text}</p>
    </SlideTestCont>
  );
};

export default SlideTest;
