import React from "react";
import { IonModal, IonContent } from "@ionic/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalRemenberPassCont } from "../../styles";
import { recuperarContraseña } from "../../ModuloFirebaseCliente/SignUpAndLogin";
import Input from "../auth/Input";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";
import { useForm } from "react-hook-form";

const ModalRemenberPass = ({ navegation, setNavegation }) => {
  const validationRegister = Yup.object().shape({
    correo: Yup.string()
      .required("Ingresa tu correo.")
      .email("El correo no es válido."),
  });
  const {
    handleSubmit: handleSubRegist,
    register: registerRegist,
    errors: errorsRegist,
    reset: resetRegist,
    setError,
  } = useForm({
    resolver: yupResolver(validationRegister),
  });
  const submitRegister = (data) => {
    recuperarContraseña(data.correo)
      .then((res) => {
        alert("Correo enviado");
        setNavegation("login");
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          setError("correo", {
            type: "manual",
            message: "Correo no encontrado.",
          });
        }
      });
  };

  return (
    <IonContent>
      <IonModal
        isOpen={navegation === "remenberPass" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalRemenberPassCont>
          <div className="modalRemenberPassCont">
            <div className="modalRemenberPassCont-header">
              <BackButton onClick={() => setNavegation("login")} />
            </div>
            <div className="modalRemenberPassCont-body">
              <div className="modalRemenberPassCont-body-title">
                <h3>Recuperar contraseña</h3>
              </div>
              <div className="modalRemenberPassCont-body-text">
                <p>
                  Ingresa tu correo, recibiras un email con un link para
                  reestablecer tu contraseña.
                </p>
              </div>
              <form
                onSubmit={handleSubRegist(submitRegister)}
                onReset={resetRegist}
              >
                <div className="modalRemenberPassCont-body-text">
                  <Input
                    name="correo"
                    placeholder="Ingresa tu correo"
                    title="Correo"
                    register={registerRegist()}
                    refT={false}
                    error={errorsRegist.correo}
                    textError={errorsRegist.correo?.message}
                  />
                </div>
                <Button disabled={false} />
              </form>
            </div>
          </div>
        </ModalRemenberPassCont>
      </IonModal>
    </IonContent>
  );
};

export default ModalRemenberPass;
