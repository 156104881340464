import React, { useEffect, useState } from "react";
import styled from "styled-components";

import OtroArticulo from "../auth/OtroArticulo";
import { date } from "../../utils/DiaDeHoy";
import {Hr} from '../modules'
import SlideTest from "../auth/SlideTest";
import { obtenerArticulosPorCategoria } from "../../consumo_apollo_client/clienteArticulos";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { agendaDiaDummy } from '../../utils/infoDummy'
import { useHistory } from 'react-router-dom';


const OtrosArticulosCont = styled.div`
  height: calc(100vh - 190px - env(safe-area-inset-top));
  overflow: auto;
  width: 100%;
  .subText {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 5%;
    width: 100%;
    min-height:50px;
    max-width: 640px;
    margin: 0px auto;
    .cont{  
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      p {
        text-align: left;
        width: 100%;
        max-width: 640px;
      }
      .date{
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #757575;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        width:120px;
      }
    }
    .news{
      display: -webkit-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display:flex;
    }
  }
  @media (min-width: 769px){
    .subText{
      padding: 10px 0%;
    }
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
`;
const OtrosArticulos = ({ nombre, refresh, setRefresh, section }) => {
  const history = useHistory();
  const [articulos, setArticulos] = useState(null);
  const { openStore, getItem, removeItem, clear } = useStorageSQLite();

  // eslint-disable-next-line
  const logout = async () => {
    await removeItem("uid");
    await removeItem("token");
    await removeItem("tipoUsuario");
    await removeItem("correo");
    await removeItem("letra");
    await removeItem("img");
    await clear();
    history.push("/");
  }

  useEffect(() => {
    const getArticles = async () => {
      await openStore({});
      const token = await getItem("sessionToken");
      const uid = await getItem("uid");

      if (refresh === true) {
        obtenerArticulosPorCategoria({ tipo: nombre })
          .then((res) => {
            if (refresh) {
              setArticulos(res)
              setRefresh(false);
            }
          })
          .catch((e) => {
            if (refresh) setArticulos(null);
          });
      }

      let mounted = true;
      obtenerArticulosPorCategoria({ tipo: nombre, Token: token, uid: uid })
        .then((res) => {
          if(res === null){
            logout();
          }
          console.log('estos los articulos')
          if (mounted) setArticulos(res);
        })
        .catch((e) => {
          if (mounted) setArticulos(null);
        });
      return function cleanup() {
        mounted = false;
      };// eslint-disable-next-line
    }

    getArticles();
 
  }, [nombre, refresh,getItem, logout, openStore, setRefresh]);



  if (articulos === null)
    return <SlideTest text={"No hay información para mostrar"} />;
  if (articulos.length === 0)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <OtrosArticulosCont>
      <div className="subText">
        <div className="cont">
          <p className="date"> {date()} </p>
          <p style={{ fontSize: '12px' }}> {agendaDiaDummy.subText} </p>
        </div>
      </div>
      <Hr/>
      {articulos.map((arti, i) => (
        <OtroArticulo title={arti.titulo} articulo={arti} key={i} section={section} />
      ))}
    </OtrosArticulosCont>
  );
};

export default OtrosArticulos;
