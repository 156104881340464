import React from "react";
import { IonModal, IonContent } from "@ionic/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { ModalLoginCont } from "../../styles";
import Button from "../auth/Button";
import Input from "../auth/Input";
import InputPass from "../auth/InputPass";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";
import { login } from "../../ModuloFirebaseCliente/SignUpAndLogin";

const ModalLogin = ({
  navegation,
  setNavegation,
  openStore,
  setItem,
  validarInner,
}) => {
  const validationLogin = Yup.object().shape({
    correo: Yup.string().required("Ingresa tu correo."),
    password: Yup.string().required("Ingresa una contraseña."),
  });
  const {
    handleSubmit: handleSubLogin,
    register: registerLogin,
    errors: errorsLogin,
    setError: setErrorLogin,
  } = useForm({
    resolver: yupResolver(validationLogin),
  });
  const submitLogin = (data) => {
    login(data.correo, data.password)
      .then(async (res) => {
        const resOpen = await openStore({});
        if (resOpen) {
          await setItem("uid", res.uid);
          await setItem("token", res.token);
          await setItem("tipoUsuario", "correo");
          await setItem("nombre", res.nombre);
          await setItem("correo", data.correo);
          validarInner(res.uid);
        }
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          setErrorLogin("correo", {
            type: "manual",
            message:
              "No hemos encontrado una cuenta registrada con ese correo.",
          });
        }
        if (e.code === "auth/wrong-password") {
          setErrorLogin("password", {
            type: "manual",
            message: "Contraseña incorrecta.",
          });
        }
        if (e.code === "auth/too-many-requests") {
          setErrorLogin("password", {
            type: "manual",
            message: "Espera unos instantes antes de intentarlo de nuevo.",
          });
        }
        if (e.code === "auth/user-disabled") {
          setErrorLogin("correo", {
            type: "manual",
            message: "Tu cuenta ha sido cancelada.",
          });
        }
      });
  };
  return (
    <IonContent>
      <IonModal
        isOpen={navegation === "login" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalLoginCont>
          <div className="modalLogCont">
            <div className="modalLogCont-header">
              <BackButton onClick={() => setNavegation("")} />
            </div>
            <div className="modalLogCont-body">
              <div className="modalLogCont-body-title">
                <p>Ingresa</p>
              </div>
              <form
                className="modalLogCont-body-inputs"
                onSubmit={handleSubLogin(submitLogin)}
              >
                <Input
                  name="correo"
                  placeholder="Ingresa tu correo"
                  title="Correo"
                  register={registerLogin()}
                  refT={false}
                  error={errorsLogin.correo}
                  textError={errorsLogin.correo?.message}
                />
                <InputPass
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  title="Contraseña"
                  register={registerLogin()}
                  refT={false}
                  error={errorsLogin.password}
                  textError={errorsLogin.password?.message}
                />
                <div className="modalLogCont-body-inputs-rem">
                  <p
                    className="remName"
                    onClick={() => {
                      setNavegation("remenberPass");
                    }}
                  >
                    Olvidé mi contraseña
                  </p>
                </div>
                <div className="buttonContLog">
                  <Button />
                </div>
              </form>
            </div>
          </div>
        </ModalLoginCont>
      </IonModal>
    </IonContent>
  );
};

export default ModalLogin;
