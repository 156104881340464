import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as IconEye } from "../../assets/icons/Eyeopen.svg";
import { ReactComponent as IconEyeClose } from "../../assets/icons/Eyeclose.svg";
import { colors } from "../../utils";

const InputCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 327px;
  height: 94px;
  .inputCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    &-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 30px;
      p {
        color: ${colors.greyLight};
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 7px;
      }
    }
    &-input {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border: 1px solid ${(props) => (props.error ? "#DB2C2B" : "#abb3bb")};
      border-color: ${props => props.error ? "#fa4443" : ""};
      border-radius: 6px;
      height: 46px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      input {
        color: ${props => props.error ? colors.red : ""};
        border: none;
        width: 100%;
        height: 100%;
        font-family: Open Sans;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        height: 20px;
        margin: 0 11px;
        font-size: ${(props) => (props.pass ? "15px" : "15px")};
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          font-size: 15px;
        }
        &::-moz-placeholder {
          font-size: 15px;
        }
        &:-ms-input-placeholder {
          font-size: 15px;
        }
        &::-ms-input-placeholder {
          font-size: 15px;
        }
        &::placeholder {
          font-size: 15px;
        }
      }
      .iconEye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        background: ${colors.white};
        path {
          fill: ${props => props.error ? colors.red : colors.greyLight};
        }
      }
      .iconEyeClose {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        background: ${colors.white};
        path {
          fill: ${colors.mdmInteraction};
        }
      }
    }
    &-error {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 20px;
      p {
        width: 100%;
        color: #db2c2b;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .inputCont {
      width: 420px;
    }
  }

  @media (min-width: 1023px) {
    .inputCont {
      width: 370px;
    }
  }
`;
const Input = ({
  name,
  register,
  className = "inputForm",
  title,
  placeholder,
  setValue,
  value,
  error,
  textError,
  refT = true,
}) => {
  const [pass, setPass] = useState(true);
  return (
    <InputCont className={className} pass={pass} error={error}>
      <div className="inputCont">
        <div className="inputCont-title">
          <p> {title} </p>
        </div>
        <div className={`inputCont-input${error ? " error" : ""}`}>
          <input
            name={name}
            type={pass ? "password" : "text"}
            ref={register}
            placeholder={placeholder}
            onChange={(e) => (refT ? setValue(e.target.value) : null)}
            value={refT ? value : null}
          />
          {pass ?
            <IconEye className="iconEye" onClick={() => setPass(!pass)} /> 
          :         
            <IconEyeClose className="iconEyeClose" onClick={() => setPass(!pass)} /> 
          }
        </div>
        <div className="inputCont-error">
          <p> {error && textError} </p>
        </div>
      </div>
    </InputCont>
  );
};

export default Input;
