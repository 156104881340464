import React from 'react';
import { SemaforoArticuloCont } from '../../styles';
import { ReactComponent as NextButton } from "../../assets/icons/nextButton.svg";
import { Hr } from '../modules';

export const SemaforoArticulo = ({ span, title }) => {
    return (
      <SemaforoArticuloCont span={span}>
        <div className="semaforoActiculoCont">
          <span className="semaforoActiculoCont-span"></span>
          <div className="semaforoActiculoCont-title">
            <h3> {title} </h3>
          </div>
          <div className="semaforoActiculoCont-arrow">
            <NextButton />
          </div>
        </div>
        <Hr/>
      </SemaforoArticuloCont>
    );
  };

export default SemaforoArticulo;