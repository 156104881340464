import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_ENDPOINT;

const MY_SERVICE = axios.create({
  baseURL
});

const STRIPE_SERVICE = {
  redirectToStripePortal: async data => {
    return await MY_SERVICE.post('/create-customer-portal-session', data)
  },

  getPaymentMethods: async customerID => {
    return await MY_SERVICE.get(`/paymenths/list/${customerID}`)
  },

  attachPaymethMethod: async data => {
    return await MY_SERVICE.post('/paymenths/attach', data)
  },

  setDefaultPayment: async (customerID, paymentMethodID) => {
    return await MY_SERVICE.get(`/paymenths/default/${customerID}/${paymentMethodID}`)
  },

  retryPaymeth: async (subscriptionID) => {
    return await MY_SERVICE.get(`/paymenths/retry/${subscriptionID}`)
  },

  createCustomer: async (uid) => {
    return await MY_SERVICE.post(`/create-customer/${uid}`, {}, {withCredentials: true})
  }

}

export default STRIPE_SERVICE;