import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import draftToHtml from "draftjs-to-html";
import { colors, fontSize } from "../../utils";
import { destacadoDiaDeHoy } from "../../consumo_apollo_client/clientePortadas";
import { obtenerBullets } from "../../consumo_apollo_client/clienteBullets";
import SlideTest from "../auth/SlideTest";
import contexto from "../../Contexto/contexto";

const DestacadoCont = styled.div`
  height: calc(100vh - 190px - env(safe-area-inset-top));
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: auto;
  .destCont {
    display: flex;
    flex-direction: column;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &-desk{
      display:none;
    }
    &-mob{
      display: flex;
      flex-direction: column;
      display: -webkit-flex;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      &-img {
        display: -webkit-flex;
        display: -webkit-box;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: auto;
        width: 100%;
        img {
          width: 90%;
          height: auto;
          margin-top:20px;
          border-radius:8px;
          margin:auto;
        }
        span{
          font-family: Open Sans;
          font-style: italic;
          font-weight: normal;
          font-size: 9px;
          line-height: 12px;
          width: 90%;
          text-align: right;
          margin: auto;
          margin-top: 10px;
        }
      }
      &-subText {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding: 3px 5%;
        margin: 10px 0;
        p {
          font-size: ${fontSize.textSmall};
          text-align: left;
        }
      }
      &-title {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding: 3px 5%;
        h3 {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 30px;
          font-family: DM Serif Display;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding: 3px 5%;
        margin: 15px 0;
        * {
          margin-bottom: 10px;
          text-align: justify;
          font-size: ${(props) =>
      props.appFontSize ? props.appFontSize : "10px"} !important;
        }
      }
      &-fuente{
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        padding:0 5%;
        flex-wrap:wrap;
        max-width: 640px;
        width:100%;
        margin:0 auto;
        span{
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #585858;
          margin-right: 10px;
        }
        .funt{
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #235789;
          background: #EEECEC;
          border-radius: 4px;
          padding:3px 8px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding:0 10%;
    .destCont {
      width: 100%;
      &-img {
        img {
          width: 80%;
          height: 100%;
          max-height: 340px;
          margin: 0 10%;
          object-fit: cover;
        }
        span{
          margin: 0 10%;
          margin-top: 10px;
          width: 80%;
        }
      }
      &-date {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 15px 0;
      }
      &-subText {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 15px 0;
      }
      &-title {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 15px 0;
      }
      &-text {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 15px 0;
      }
      .fuente{
        padding:0;
      }
    }
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
  @media (min-width: 1280px){
    .destCont{
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-direction:row;
      flex-direction:row;
      margin-top:20px;
      &-desk{
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        
        &-img {
          display: -webkit-flex;
          display: -webkit-box;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          height: auto;
          width: 100%;
          img {
            width: 360px;
            height: 360px;
            margin-top:3px;
            border-radius:8px;
            object-fit:cover;
          }
          span{
            font-family: Open Sans;
            font-style: italic;
            font-weight: normal;
            font-size: 9px;
            line-height: 12px;
            width: 100%;
            text-align: right;
            margin: auto;
            margin-top: 10px;
            margin-bottom:40px;
          }
        }
        &-fuente{
          display: flex;
          display: -webkit-flex;
          display: -webkit-box;
          padding:0 0%;
          flex-wrap:wrap;
          max-width: 640px;
          width:100%;
          margin:0 auto;
          justify-content:flex-end;
          span{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #585858;
            margin-right:   0px;
            margin-left: 10px;
          }
          .funt{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #235789;
            background: #EEECEC;
            border-radius: 4px;
            padding:3px 8px;
          }
        }
      }
      &-mob{
        width:calc(100% - 360px);
        &-img{
          display:none;
        }
        &-fuente{
          display:none;
        }
      }
    }
  }
`;

const Destacado = ({ nombre, refresh, setRefresh }) => {
  const [articulo, setArticulo] = useState(null);
  const [bullets, setBullets] = useState(null);
  const { appFontSize } = useContext(contexto);
  useEffect(() => {
    if (refresh === true) {
      destacadoDiaDeHoy({ tipo: nombre })
        .then((res) => {
          if (res) {
            if (refresh) {
              setArticulo(res);
              setRefresh(false)
            }
          } else {
            if (refresh) {
              setArticulo(null);
            }
          }
        })
        .catch((e) => {
          if (refresh) {
            setArticulo(null);
          }
        });

      obtenerBullets()
        .then((res) => {
          if (res) {
            if (refresh) {
              setBullets(res);
            }
          } else {
            if (refresh) {
              setBullets(null);
            }
          }
        })
        .catch((e) => {
          if (refresh) {
            setBullets(null);
          }
        });
    }

    let mounted = true;
    destacadoDiaDeHoy({ tipo: nombre })
      .then((res) => {
        if (res) {
          if (mounted) {
            setArticulo(res);
          }
        } else {
          if (mounted) {
            setArticulo(null);
          }
        }
      })
      .catch((e) => {
        if (mounted) {
          setArticulo(null);
        }
      });

    obtenerBullets()
      .then((res) => {
        if (res) {
          if (mounted) {
            setBullets(res);
          }
        } else {
          if (mounted) {
            setBullets(null);
          }
        }
      })
      .catch((e) => {
        if (mounted) {
          setBullets(null);
        }
      });
    return function cleanup() {
      mounted = false;
    };// eslint-disable-next-line
  }, [nombre, refresh]);
  const ubicacion = 'INCAE';
  const fuente = [
    'Reforma',
    'Universal',
    'Milenio'
  ];
  const DestacadoProp = () => {
    return (
      <>
        <div className="destCont-desk">
          <div className="destCont-desk-img">
            <img src={articulo.photoURL} alt="img" />
            <span> Foto: {ubicacion}  </span>
          </div>
          <div className="destCont-desk-fuente">
            <span>Fuente: </span>
            {fuente.map((data) => (
              <span className="funt">
                {data}
              </span>
            ))
            }
          </div>
        </div>
        <div className='destCont-mob'>
          <div className="destCont-mob-img">
            <img src={articulo.photoURL} alt="img" />
            <span> Foto: {ubicacion}  </span>
          </div>
          <div className="destCont-mob-title">
            <h3> {articulo.titulo} </h3>
          </div>
          <div
            className="destCont-mob-text"
            dangerouslySetInnerHTML={{
              __html: draftToHtml(JSON.parse(articulo.cuerpo)),
            }}
          ></div>
          <div className="destCont-mob-fuente">
            <span>Fuente: </span>
            {fuente.map((data) => (
              <span className="funt">
                {data}
              </span>
            ))
            }
          </div>
        </div>
      </>
    );
  };

  if (articulo == null || bullets == null)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <DestacadoCont appFontSize={appFontSize}>
      <div className="destCont">
        <DestacadoProp />
      </div>
    </DestacadoCont>
  );
};

export default Destacado;
