import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { IonModal, IonContent } from "@ionic/react";
import { ModalRegisterCont } from "../../styles";
import Input from "../auth/Input";
import InputPass from "../auth/InputPass";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";
import { signupuid } from "../../ModuloFirebaseCliente/SignUpAndLogin";

const ModalRegister = ({ navegation, setNavegation, openStore, setItem }) => {
  const validationRegister = Yup.object().shape({
    name: Yup.string().required("Ingresa tu nombre completo."),
    correo: Yup.string()
      .required("Ingresa tu correo.")
      .email("El correo no es válido."),
    password: Yup.string()
      // .min(6, 'Password must be at least 6 characters')
      .required("Ingresa una contraseña."),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden. ")
      .required("Ingrese una contraseña de confirmación."),
    terminos: Yup.bool().oneOf([true], "Accepta los Ts & Cs"),
  });
  const {
    handleSubmit: handleSubRegist,
    register: registerRegist,
    errors: errorsRegist,
    reset: resetRegist,
    setError,
  } = useForm({
    resolver: yupResolver(validationRegister),
  });
  const submitRegister = (data) => {
    signupuid(data.name, data.correo, data.password)
      .then(async (res) => {
        alert("Registro exitoso");
        const resOpen = await openStore({});
        if (resOpen) {
          await setItem("correo", data.correo);
          await setItem("nombre", data.name);
          await setItem("token", res.token);
          await setItem("uid", res.uid);
          setNavegation("register-membership");
        }
      })
      .catch((e) => {
        if (e.code === "auth/email-already-in-use") {
          setError("correo", {
            type: "manual",
            message: "El correo ya ha sido registrado",
          });
        }
      });
  };
  return (
    <IonContent>
      <IonModal
        isOpen={navegation === "register-data" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalRegisterCont>
          <div className="modalRegisterCont">
            <div className="modalRegisterCont-header">
              <BackButton onClick={() => setNavegation("")} />
            </div>
            <div className="modalRegisterCont-body">
              <div className="modalRegisterCont-body-title">
                <p>Ingresa</p>
              </div>
              <form
                className="modalRegisterCont-body-inputs"
                onSubmit={handleSubRegist(submitRegister)}
                onReset={resetRegist}
              >
                <Input
                  name="name"
                  placeholder="Ingresa tu nombre completo "
                  title="Nombre"
                  refT={false}
                  register={registerRegist()}
                  error={errorsRegist.name}
                  textError={errorsRegist.name?.message}
                />
                <Input
                  name="correo"
                  placeholder="Ingresa tu correo"
                  title="Correo"
                  type="email"
                  register={registerRegist()}
                  refT={false}
                  error={errorsRegist.correo}
                  textError={errorsRegist.correo?.message}
                />
                <InputPass
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  title="Contraseña"
                  refT={false}
                  register={registerRegist()}
                  error={errorsRegist.password}
                  textError={errorsRegist.password?.message}
                />
                <InputPass
                  name="passwordConfirm"
                  placeholder="Confirma tu contraseña"
                  title="Confirma tu contraseña"
                  refT={false}
                  register={registerRegist()}
                  error={errorsRegist.passwordConfirm}
                  textError={errorsRegist.passwordConfirm?.message}
                />
                <div className="modalRegisterCont-body-inputs-rem">
                  <input
                    className={errorsRegist.terminos && "error"}
                    type="checkbox"
                    name="terminos"
                    ref={registerRegist()}
                  />
                  <p>He leído y acepto los</p>
                  <p
                    className="remName"
                    onClick={() => setNavegation("terminos")}
                  >
                    Términos y Condiciones
                  </p>
                </div>
                <Button />
              </form>
            </div>
            <br />
          </div>
        </ModalRegisterCont>
      </IonModal>
    </IonContent>
  );
};

export default ModalRegister;
