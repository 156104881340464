import {
  crearCliente
} from "./clientGenerator";
const {
  gql
} = require("@apollo/client");
const client = crearCliente();

const obtenerSemaforoDiaDeHoy = () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql `
          query {
            semaforoDiadeHoy {
              categoria
              luces {
                color
                articulo {
                  titulo
                  subtitulo
                  cuerpo
                  autor
                  categoria
                  photoURL
                  palabrasClave
                  testigos
                }
                _id
              }
            }
          }
        `,
      })
      .then((result) => {
        resolve(result.data.semaforoDiadeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  obtenerSemaforoDiaDeHoy
};