import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
const obtenerArticuloporId = (datos) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query articuloById($id: MongoID!) {
            articuloById(_id: $id) {
              titulo
              subtitulo
              cuerpo
              autor
              piePorciento
              pieColor
              categoria
              semaforo
              testigos
            }
          }
        `,
                variables: datos,
            })
            .then((result) => {
                resolve(result.data.articuloById);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const obtenerArticulosPorCategoria = async(categoria) => {
    return new Promise((resolve, reject) => {
        return client
            .query({
                query: gql `
          query articuloDiadeHoy($tipo: String,$Token: String, $uid: String) {
            articuloDiadeHoy(tipo: $tipo,Token: $Token, uid: $uid) {
              titulo
              subtitulo
              cuerpo
              autor
              categoria
              photoURL
              palabrasClave
              testigos
              _id
              updatedAt
              createdAt
            }
          }
        `,
                variables: categoria,
            })
            .then((res) => {
                resolve(res.data.articuloDiadeHoy);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export { obtenerArticuloporId, obtenerArticulosPorCategoria };