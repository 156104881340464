import React, { useState } from 'react';
import { IonModal, IonContent } from "@ionic/react";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";

import { ModalPaymentCont } from "../../styles";
import Input from "../auth/Input";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";

const ModalPayment = ({ navegation, setNavegation, priceRegister }) => {
  const {
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta,
  } = usePaymentInputs();
  const [nameCard, setNameCard] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [cvc, setCvc] = useState(null);
  return(
    <IonContent>
      <IonModal
        isOpen={navegation === "register-payment" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalPaymentCont>
          <div className="modalPaymentCont">
            <div className="modalPaymentCont-header">
              <BackButton
                onClick={() => setNavegation("register-membership")}
              />
            </div>
            <div className="modalPaymentCont-body">
              <div className="modalPaymentCont-body-title">
                <p>Medio de pago</p>
              </div>
              <div className="modalPaymentCont-body-text">
                <p>
                  Seleccionaste el
                  <b className="blueText">
                    Plan {priceRegister ? "anual" : "mensual"}
                  </b>
                  por ${priceRegister ? "2,999" : "499"}.00 Al termino de tu
                  periodo de los 30 días de prueba, se hará el cargo a tu
                  tarjeta.
                </p>
              </div>
              <Input
                name="name"
                placeholder="Ingresa el nombre del titular"
                title="Nombre del titular"
                setValue={setNameCard}
                value={nameCard}
              />
              <div className="modalPaymentCont-body-inputCont">
                <div className="input">
                  <div className="input-title">
                    <p>Número de tarjeta</p>
                  </div>
                  <div className="input-cont">
                    <svg {...getCardImageProps({ images })} />
                    <input
                      {...getCardNumberProps({
                        onChange: (e) => setCardNumber(e.target.value),
                      })}
                      value={cardNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="modalPaymentCont-body-inputsCont">
                <div className="input">
                  <div className="input-title">
                    <p>CVV</p>
                  </div>
                  <div className="input-cont">
                    <input
                      {...getCVCProps({
                        onChange: (e) => setCvc(e.target.value),
                      })}
                      value={cvc}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-title">
                    <p>Vigencia</p>
                  </div>
                  <div className="input-cont">
                    <input
                      {...getExpiryDateProps({
                        onChange: (e) => setExpiryDate(e.target.value),
                      })}
                      value={expiryDate}
                    />
                  </div>
                </div>
              </div>
              {meta.isTouched && meta.error && (
                <span>Error: {meta.error}</span>
              )}
              <div className="modalPaymentCont-body-button">
                <Button
                  onClick={() => setNavegation("success")}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </ModalPaymentCont>
      </IonModal>
    </IonContent>
  )
};

export default ModalPayment;