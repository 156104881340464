import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useStorageSQLite } from 'react-data-storage-sqlite-hook/dist'
import { getStripeId, getSubscriptionId } from '../ModuloFirebaseCliente/SignUpAndLogin'
import MY_SERVICE from '../services/stripeServices'
import checked from '../assets/icons/checked.png'
import unchecked from '../assets/icons/unchecked.png'
import alert from '../assets/icons/alert.png'
import successSVG from "../assets/icons/success.svg";
import visa from '../assets/icons/visa.svg'
import mastercard from '../assets/icons/mastercard.svg'
import { BackButton } from '../components/modules'

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  display: block;
  margin: 2;
  border-color: white;
`;

const PaymentFailureContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 45px 5% 75px 5%;
  .back-button {
    align-self: flex-start;
  }
  .content {
    .alert {
      width: 57px;
      height: 57px;
      margin-bottom: 13px;
    }
    h1 {
      font-family: Open Sans;
      font-weight: 700;
      font-size: 14px;
      height: 34px;
    }
    p {
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.95px;
      margin-bottom: 42px;
    }
    .second-p {
      margin-bottom: 10px;
    }
    a {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 13px;
      color: #235789;
    }
  }
  .methods {
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 0 30px;
    /* overflow: auto;
    height: 25%; */
  }
  .loading {
    animation: loading 2s linear infinite;
    border-radius: 5px;
  }
  @keyframes loading {
    0%   {background-color: white;}
    50%  {background-color: lightgray;}
    100% {background-color: white;}
  }
  .errorMessage{
    color: red;
    font-size: 14px;
    font-weight: 600;
  }
`

const Method = styled.button`
  border: none;
  background: none;
  outline:none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  .checkbox {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  p {
    font-family: OpenSans;
    font-weight: 700 !important;
    font-size: 14px;
    margin-bottom: 0 !important;
    margin-right: 21px;
  }
  .blue {
    color: #235789;
  }
  .gray {
    color: gray;
  }
  .card {
    min-width: 28px;
    min-height: 20px;
    width: 28px;
    height: 20px;
    background: lightgray;
  }
`

const ButtonCont = styled.button`
  height: 57px;
  width: 100%;
  border: none;
  background: #235789;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background: grey;
  }
  .buttonText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
`

const PaymentFailure = ({ history }) => {

  const [paymentMethods, setPaymentMethods] = useState(null)
  const [stripeId, setStripeId] = useState(null)

  const [loading, setLoading] = useState(false)
  const [changeDefault, setchangeDefault] = useState(false)
  const [showErrorLegend, setShowErrorLegend] = useState(history.location.state ? false : true)
  const [showChangeLegend, setShowChangeLegend] = useState(history.location.state ? true : false)

  const { openStore, getItem, removeItem, clear } = useStorageSQLite()

  useEffect(() => {
    const getStripeCustomer = async () => {
      const store = await openStore({})
      if (store) {
        const uid = await getItem('uid')
        getPaymentMethods(uid)
      }
    }
    getStripeCustomer()
    // eslint-disable-next-line
  }, [getItem, openStore])

  const returnToLogin = () => history.push('/')

  const returnToApp = () => history.push('/app')

  const getPaymentMethods = async uid => {
    const stripeCustomer = await getStripeId(uid, returnToLogin, returnToApp)
    setStripeId(stripeCustomer)
    const { data: cards } = await MY_SERVICE.getPaymentMethods(stripeCustomer)
    setPaymentMethods(cards)
  }

  const getStripeCustomerGlobal = async () => {
    const store = await openStore({})
    if (store) {
      const uid = await getItem('uid')
      getPaymentMethods(uid)
    }
  }

  const handleSetDefault = async (stripeId, method) => {
    await MY_SERVICE.setDefaultPayment(stripeId, method.id)
    getStripeCustomerGlobal()
    setchangeDefault(true)
    setShowErrorLegend(false)
    setShowChangeLegend(true)
  }

  const handleRetryPay = async () => {
    setLoading(true)
    const store = await openStore({})
    if (store) {
      const uid = await getItem('uid')
      const theSub = await getSubscriptionId(uid)
      try {
        await MY_SERVICE.retryPaymeth(theSub)
        setLoading(false)
        history.push('/successful-payment')
      } catch ({ message }) {
        setShowErrorLegend(true)
        setShowChangeLegend(false)
        setLoading(false)
      }
    }
  }

  const logout = async () => {
    await removeItem("uid");
    await removeItem("token");
    await removeItem("tipoUsuario");
    await removeItem("correo");
    await removeItem("letra");
    await removeItem("img");
    await clear();
    history.push("/");
  }

  return (
    <PaymentFailureContainer>

      <div className='back-button'>
        <BackButton onClick={() => logout()} />
      </div>
      <div className='content'>
        {showErrorLegend &&
          <ErrorMessage />
        }
        {showChangeLegend &&
          <PaymethAddedMessage
            paymenthCardChange={changeDefault}
          />
        }
        <p className='second-p'>Estos son tus métodos de pago registrados:</p>

        <div className={paymentMethods ? 'methods' : 'loading methods'}>
          {paymentMethods && paymentMethods.map(method => (
            <Method
              key={method.id}
              className='method'
              onClick={async () => handleSetDefault(stripeId, method)}
            >
              {method.default
                ? <img src={checked} alt='checkbox' className='checkbox' />
                : <img src={unchecked} alt='checkbox' className='checkbox' />}
              <p className={method.default ? 'blue' : 'gray'}>•••• •••• •••• {method.card.last4}</p>
              {method.card.brand === 'visa'
                ? <img src={visa} alt='visa' className='card' />
                : <img src={mastercard} alt='mastercard' className='card' />}
            </Method>
          ))}
        </div>
        <Link to='/new-payment-method'>Añadir nuevo método de pago</Link>
      </div>
      <ButtonCont
        onClick={() => handleRetryPay()}
        disabled={loading}
      >
        {loading ?
          <BeatLoader color={"#ffffff"} loading={true} css={override} size={15} margin={2} />
          :
          <p className="buttonText">Intentar de nuevo</p>
        }
      </ButtonCont>

    </PaymentFailureContainer>
  )
}

const ErrorMessage = () => {
  return (
    <>
      <img
        src={alert}
        alt='alert'
        className='alert'
      />
      <h1>No se ha podido validar tu cuenta</h1>
      <p>Lo sentimos, tu último pago no ha podido ser procesado, por favor verifica tu método de pago o agrega uno nuevo.</p>
    </>
  )
}

const PaymethAddedMessage = ({ paymenthCardChange }) => {
  return (
    <>
      <img
        src={successSVG}
        alt='successSVG'
        className='alert'
      />
      {paymenthCardChange ?
        <h1>Metodo de pago cambiado</h1>
        :
        <h1>Tu método de pago se agregó correctamente</h1>
      }
      <p>Por favor, selecciona la tarjeta con la que deseas reintentar tu pago</p>
    </>
  )
}

export default PaymentFailure
