import React from 'react';

import { TerminosAltContainer } from "../../styles";
import { BackButton } from "../modules";
import { useHistory } from "react-router-dom";

const Terminos = () => {
  const history = useHistory();
  return (
    <TerminosAltContainer>
      <div className="back">
        <BackButton onClick={() => history.push('/register')} />
      </div>

      <div className='content'>

        <h3 className="main-title">Términos y Condiciones.</h3>
        <div>
          <p>
            Este contrato describe los términos y condiciones generales
            (los "Términos y Condiciones") aplicables al uso de los
            contenidos, productos y servicios ofrecidos a través del
              sitio <a href='https://elmediodemedios.com' target='_blank' rel='noopener noreferrer'>www.elmediodemedios.com</a>
              (el "Sitio Web"), del cual es titular
              <strong> Oblekcomp, S.A.P.I. de C.V.</strong> ("Oblek"), una
              sociedad mercantil constituida de conformidad con las leyes
              de los Estados Unidos Mexicanos (“México”), la cual tiene su
              domicilio establecido en Calle Cadetes del 47 número 6, Piso
              1, departamento 2, colonia San Miguel Chapultepec II
              Sección, Alcaldía Miguel Hidalgo, código postal 11850,
              Ciudad de México, México.
            </p>
          <p>
            Cualquier persona que desee acceder o hacer uso del Sitio
            Web o los servicios que en él se ofrecen (el “Usuario”),
            podrá hacerlo sujetándose a los presentes Términos y
            Condiciones. En todo caso, cualquier persona que no acepte
            los presentes Términos y Condiciones, deberá abstenerse de
            utilizar el Sitio Web y/o adquirir los productos y servicios
            que en su caso sean ofrecidos.
            </p>
          <h3>I. OBJETO.</h3>
          <p>
            El objeto de los presentes Términos y Condiciones es regular
            el acceso y la utilización del Sitio Web, entendiendo por
            éste, cualquier tipo de contenido, producto o servicio que
            se encuentre a disposición del público en general dentro del
            dominio: <a href='https://elmediodemedios.com' target='_blank' rel='noopener noreferrer'>www.elmediodemedios.com</a>
          </p>
          <p>
            El Titular se reserva la facultad de modificar en cualquier
            momento y sin previo aviso, los presente Términos y
            Condiciones, la presentación, los contenidos, la
            funcionalidad, los productos, los servicios, y la
            configuración que pudiera estar contenida en el Sitio Web;
            en este sentido, el Usuario reconoce y acepta que
            <strong> Oblekcomp, S.A.P.I. de C.V. </strong>
            en cualquier momento podrá interrumpir,
            desactivar o cancelar cualquiera de los elementos que
            conforman el Sitio Web o el acceso a los mismos.
            </p>
          <p>
            El acceso al Sitio Web por parte del Usuario tiene carácter
            libre y, por regla general es gratuito sin que el Usuario
            tenga que proporcionar una contraprestación para poder disfrutar
            de ello, salvo a cualquier servicio que contrate dentro el
            Sitio Web y en lo referente al costo de la conexión a internet
            suministrada por el proveedor de este tipo de servicios que
            hubiere contratado el mismo Usuario.
            </p>
          <p>
            El acceso a parte de los contenidos y servicios del Sitio Web
            podrá realizarse previa suscripción y pago del Usuario.
        </p>
          <p>
            El Sitio Web se encuentra dirigido exclusivamente a personas que
            cuenten con la mayoría de edad (mayores de 18 años); en este sentido,
            <strong> Oblekcomp, S.A.P.I. de C.V. </strong>
            declina cualquier responsabilidad por el incumplimiento de este requisito.
          </p>
          <p>
            El Sitio Web está dirigido principalmente a Usuarios residentes en México,
          por lo cual, <strong> Oblekcomp, S.A.P.I. de C.V. </strong>no asegura que
          el Sitio Web cumpla total o parcialmente con la legislación de otros países
          o jurisdicciones, de forma que, si el Usuario reside o tiene su domicilio
          establecido en otro país y decide acceder o utilizar el Sitio Web lo hará
          bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación
          cumple con la legislación local que le es aplicable, no asumiendo
          <strong> Oblekcomp, S.A.P.I. de C.V. </strong>
          ninguna responsabilidad que se pueda derivar de dicho acto.
          </p>
          <p>
            Se hace del conocimiento del Usuario que el Titular podrá administrar o
            gestionar el Sitio Web de manera directa o a través de un tercero, lo cual
            no modifica en ningún sentido lo establecido en los presentes Términos y Condiciones.
          </p>
          <h3>II. DEL USUARIO.</h3>
          <p>
            El acceso o utilización del Sitio Web, confiere la condición de Usuario del Sitio Web,
            por lo que quedará sujeto a los presentes Términos y Condiciones, así como a sus modificaciones
            posteriores, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán
            por aceptados desde el momento en el que se accede al Sitio Web. Dada la relevancia de lo anterior,
            se recomienda al Usuario revisar las actualizaciones que se realicen a los presentes Términos y Condiciones.
          </p>
          <p>
            Es exclusiva responsabilidad del Usuario utilizar el Sitio Web de acuerdo a la forma en la que fue
            diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice
            la interacción o descarga de los contenidos o servicios proporcionados a través del Sitio Web.
            Además, el Usuario se compromete a utilizar la información, contenidos o servicios ofrecidos a
            través del Sitio Web de manera lícita, sin contravenir lo dispuesto en los presentes Términos y
            Condiciones, la moral o el orden público, y se abstendrá de realizar cualquier acto que pueda
            suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del Sitio Web.
          </p>
          <p>
            El Usuario reconoce y acuerda que, en caso de contratar alguno de los servicios ofrecidos dentro del
            Sitio Web, ya sea de forma mensual o anual, es de su entera responsabilidad el manejo del servicio
            contratado, que es el único responsable de gestionar la cancelación del mismo y que por ningún motivo
            podrá reclamar el reembolso de ninguna contraprestación en caso que por una omisión suya no haya
            cancelado cualquier servicio previo al cobro por renovación del mismo.
          </p>
          <p>
            El Usuario reconoce y acuerda que, los periodos de contratación de cada servicio están reflejados en el Sitio Web y que por ningún motivo podrá contratar dichos servicios por períodos parciales; reconoce conocer los medios de pago establecidos dentro del Sitio Web, los cuales también son su entera responsabilidad.
          </p>
          <p>
            Asimismo, el Usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en el Sitio Web, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por el propio Sitio Web. En todo caso, el Usuario notificará de forma inmediata al Titular acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.
          </p>
          <p>
            El sólo acceso al Sitio Web no supone el establecimiento de ningún tipo de relación entre el Titular y el Usuario.
          </p>
          <p>
            Al tratarse de un Sitio Web dirigido exclusivamente a personas que cuenten con la mayoría de edad, el Usuario manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes Términos y Condiciones.
          </p>
          <h3>III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</h3>
          <p>
            El Titular no garantiza de ningún modo la continuidad y disponibilidad de los contenidos, productos o servicios ofrecidos a través del Sitio Web, no obstante, el Titular llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan mantener el buen funcionamiento del Sitio Web, sin que esto suponga alguna responsabilidad de parte de  <strong> Oblekcomp, S.A.P.I. de C.V.</strong>
          </p>
          <p>
            De igual forma <strong> Oblekcomp, S.A.P.I. de C.V. </strong>no será responsable ni garantiza que el contenido o software al que pueda accederse a través del Sitio Web, se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el Usuario accede al Sitio Web.
          </p>
          <p>
            El Titular tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del Sitio Web. En ningún caso <strong> Oblekcomp, S.A.P.I. de C.V. </strong>será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del Sitio Web.
          </p>
          <h3>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</h3>
          <p>
            De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, el Titular se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado, lo invitamos a leer y entender nuestro <strong> Aviso de Privacidad.</strong>
          </p>
          <p>
            En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.
          </p>
          <p>
            El tratamiento de datos personales se limitará al cumplimiento de las finalidades previstas en el <strong> Aviso de Privacidad </strong>el cual se encontrará disponible en la siguiente dirección electrónica:
          </p>
          <a href='https://elmediodemedios.com/aviso-de-privacidad' target='_blank' rel='noopener noreferrer'>www.elmediodemedios.com/aviso-de-privacidad</a>
          <p>
            El Titular se reserva el derecho a modificar su Aviso de Privacidad, de conformidad con sus necesidades o derivado de algún cambio en la legislación. El acceso o utilización del Sitio Web después de dichos cambios, implicará la aceptación de estos cambios.
          </p>
          <p>
            Por otra parte, el acceso al Sitio Web puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el Usuario. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar al Sitio Web, almacenar las preferencias del Usuario, así como la interacción que este tenga con el Sitio Web, como por ejemplo: la fecha y hora en la que se accede al Sitio Web, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etcétera.
          </p>
          <p>
            Este tipo de información será utilizada para mejorar el Sitio Web, detectar errores, y posibles necesidades que el Usuario pueda tener, lo anterior a efecto de ofrecer a los Usuarios servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.
          </p>
          <p>
            En caso de que el Usuario no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el Usuario podrá continuar haciendo uso del Sitio Web, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.
          </p>
          <p>
            Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes Términos y Condiciones, con objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del Sitio Web.
          </p>
          <h3>V. POLÍTICA DE ENLACES.</h3>
          <p>
            El Sitio Web puede contener enlaces, contenidos, servicios o funciones, de otros sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos, comentarios, motores de búsqueda, etc.
          </p>
          <p>
            La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar la experiencia del Usuario al hacer uso del Sitio Web, sin que pueda considerarse una sugerencia, recomendación o invitación para hacer uso de sitios externos. el Titular en ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no hace propios los productos, servicios, contenidos, y cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a través del Sitio Web. Así mismo, el Titular no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos, productos o servicios disponibles en los sitios web no gestionados por el Titular a los que se pueda acceder mediante el Sitio Web.
          </p>
          <p>
            El Usuario o cualquier tercero que realice o publique un enlace web desde una página web externa, a este Sitio Web deberán tomar en cuenta lo siguiente:
          </p>
          <p>
            No se permite la reproducción (total o parcial) de los contenidos, productos o servicios disponibles en el  Sitio Web sin la autorización expresa del Titular o su titular. Tampoco se permitirán manifestaciones falsas, inexactas o incorrectas sobre el  Sitio Web, ni sobre sus contenidos, productos o servicios, pudiendo el Titular restringir el acceso al Sitio Web a toda aquella persona que incurra en este tipo de actos.
          </p>
          <p>
            El establecimiento de un enlace al  Sitio Web desde cualquier sitio externo, no implicará la existencia de alguna relación entre el Titular y el titular del sitio web desde el cual se realice, tampoco implica el conocimiento de el Titular de los contenidos, productos o servicios ofrecidos en los sitios externos desde los cuales se pueda acceder al Sitio Web.
          </p>
          <h3>VI. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h3>
          <p>
            El Titular por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, entendiendo por éste el código fuente que hace posible su funcionamiento así como las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del Sitio Web, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización del Titular.
          </p>
          <p>
            El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial del Titular. No obstante, además de poder visualizar los elementos del Sitio Web podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.
          </p>
          <p>
            Por otro lado, el Usuario, se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido, del Sitio Web, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.
          </p>
          <p>
            En caso que el Usuario o algún tercero consideren que cualquiera de los contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente al Titularpor conducto de los datos de contacto disponibles en el propio Sitio Web y/o a través de los siguientes medios:
          </p>
          <p>Teléfono: 6393-7700</p>
          <p>Correo electrónico: hola@oblekco.com</p>
          <h3>VII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</h3>
          <p>
            El presente Contrato se regirá e interpretará de acuerdo con las leyes federales aplicables de México.
          </p>
          <p>
            Las partes del presente Contrato convienen expresa e irrevocablemente en someterse a la jurisdicción de los tribunales federales competentes ubicados en la Ciudad de México, respecto a cualquier demanda, acción o procedimiento que se derive o se relacione con el presente Contrato y renuncian irrevocablemente a cualquier otra jurisdicción que les pudiera corresponder por razón de sus domicilios presentes o futuros, o por alguna otra causa.
          </p>
        </div>
      </div>

    </TerminosAltContainer>
  )
};

export default Terminos;