import React from "react";
import Switch from "react-switch";
import { IonModal, IonContent } from "@ionic/react";

import { Plugins, Capacitor } from "@capacitor/core";

import { ModalMembershipCont } from "../../styles";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";

import { ReactComponent as IconBullet } from "../../assets/icons/point.svg";

const ModalMembership = ({
  navegation,
  setNavegation,
  priceRegister,
  setPriceRegister,
  getItem,
}) => {
  const RedirectAPI = process.env.REACT_APP_SERVER_ENDPOINT;
  const { App } = Plugins;
  const precios = {
    mensual: process.env.REACT_APP_PRICE_MENSUAL_ID,
    anual: process.env.REACT_APP_PRICE_ANUAL_ID
  };
  return (
    <IonContent>
      <IonModal
        isOpen={navegation === "register-membership" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalMembershipCont>
          <div className="modalMembershipCont">
            <div className="modalMembershipCont-header">
              <BackButton onClick={() => setNavegation("register-data")} />
            </div>
            <div className="modalMembershipCont-body">
              <div className="modalMembershipCont-body-title">
                <p>Selecciona tu membresía</p>
              </div>
              <div className="modalMembershipCont-body-info">
                <div className="modalMembershipCont-body-info-target">
                  <p>¡30 días de prueba gratis!</p>
                </div>
                <div className="modalMembershipCont-body-info-desc">
                  <IconBullet />
                  <p>
                    El mejor monitoreo de medios con las noticias más
                    relevantes.
                  </p>
                </div>
                <div className="modalMembershipCont-body-info-desc">
                  <IconBullet />
                  <p>Análisis a más de 20,000 notas especialmente para ti.</p>
                </div>
                <div className="modalMembershipCont-body-info-desc">
                  <IconBullet />
                  <p>Mantente informado durante todo el día. </p>
                </div>
                <div className="modalMembershipCont-body-info-desc">
                  <IconBullet />
                  <p>Boletines informativos directo en tu celular.</p>
                </div>
                <div className="modalMembershipCont-body-info-text">
                  <p>
                    Para obtener mayor cobertura de notas inscríbete a una
                    versión premium por tan solo
                  </p>
                </div>
                <div className="modalMembershipCont-body-info-check">
                  <span className={priceRegister ? "off" : "on"}>Mensual</span>
                  <Switch
                    onChange={() => setPriceRegister(!priceRegister)}
                    checked={priceRegister}
                    offColor="#235789"
                    onColor="#235789"
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={64}
                    height={24}
                  />
                  <span className={priceRegister && "on"}>Anual</span>
                </div>
                <span className="modalMembershipCont-body-info-discont">
                  {" "}
                  {priceRegister && "AHORRAS 25%"}{" "}
                </span>
                <div className="modalMembershipCont-body-info-price">
                  <span className="dollar">$</span>
                  <span className="price">
                    {priceRegister
                      ? Number("2999").toLocaleString("es-MX")
                      : Number("449").toLocaleString("es-MX")}
                  </span>
                  <span className="decimal">.00</span>
                </div>
              </div>
            </div>
            <div className="modalMembershipCont-footer">
              <div className="modalMembershipCont-footer-button">
                <Button
                  onClick={async () => {
                    var ua = navigator.userAgent.toLowerCase();
                    let ventana = null;
                    if (ua.indexOf("safari") !== -1) {
                      if (!(ua.indexOf("chrome") > -1)) {
                        ventana = window.open("about:blank", "_blank");
                      }
                    }
                    const cargar = async () => {
                      var precio = null;
                      if (priceRegister) {
                        precio = precios.anual;
                      } else {
                        precio = precios.mensual;
                      }
                      var uid = await getItem("uid");
                      var platform = Capacitor.getPlatform();
                      var url = {
                        url:
                          RedirectAPI + "/pagos/procesar/" + uid + "/" + precio,
                      };

                      if (platform === "web") {
                        var ua = navigator.userAgent.toLowerCase();
                        if (ua.indexOf("safari") !== -1) {
                          if (ua.indexOf("chrome") > -1) {
                            window.open(
                              url.url,
                              "_blank",
                              "status=yes, toolbar=no, menubar=no, location=no,addressbar=no"
                            );
                          } else {
                            ventana.location = url.url;
                          }
                        }
                        window.onmessage = function (e) {
                          if (e.data) {
                            if (e.data === "compraOK") {
                              window.location.reload();
                            }
                          }
                        };
                      } else {
                        await App.openUrl(url);
                      }
                    };
                    cargar();
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </ModalMembershipCont>
      </IonModal>
    </IonContent>
  );
};

export default ModalMembership;
