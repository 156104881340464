import {
  imgDummy2,
  imgDummy1,
  na1,
  na2,
  na3,
  na4,
  na5,
  na6,
  na7,
  na8,
  na9,
  in1,
  in2,
} from "../assets/dummy";

export const params = {
  economia: [
    {
      title: "Agenda del día",
      text: "No hay Agenda del día para mostrar",
      id: "agendaDia",
      nombre: "Economia",
    },
    {
      title: "Destacado",
      text: "No hay Destacado para mostrar",
      id: "destacado",
      nombre: "Economia",
    },
    {
      title: "Indicadores económicos",
      text: "No hay Indicadores económicos para mostrar",
      id: "indicadoresEconomicos",
      nombre: "Economia",
    },
    {
      title: "Otros artículos",
      text: "No hay Otros artículos para mostrar",
      id: "otrosArticulos",
      nombre: "Economia",
    },
    {
      title: "Opinión",
      text: "No hay Opiniones para mostrar",
      id: "opinion",
      nombre: "Economia",
    },
  ],
  politica: [
    {
      title: "Artículos",
      text: "No hay Otros artículos para mostrar",
      id: "otrosArticulos",
      nombre: "Politica",
    },
  ],
  agenda: [
    {
      title: "Agenda del día",
      text: "No hay Agenda del día para mostrar",
      id: "agendaDia",
      nombre: "Agenda",
    },
    {
      title: "Destacado",
      text: "No hay Destacado para mostrar",
      id: "destacado",
      nombre: "Agenda",
    },
    {
      title: "Internacional",
      text: "No hay Panorama para mostrar",
      id: "panoramaIn",
      nombre: "Agenda",
    },
    {
      title: "Nacional",
      text: "No hay Panorama para mostrar",
      id: "panoramaNa",
      nombre: "Agenda",
    },
    {
      title: "Encabezados",
      text: "No hay Encabezados para mostrar",
      id: "encabezados",
    },
    {
      title: "Semáforo",
      text: "No hay Semaforos para mostrar",
      id: "semaforo",
    },
    {
      title: "Opinión",
      text: "No hay Opiniones para mostrar",
      id: "opinion",
      nombre: "Agenda",
    },
  ],
  sociedad: [
    {
      title: "Agenda del día",
      text: "No hay Agenda del día para mostrar",
      id: "agendaDia",
      nombre: "Sociedad",
    },
    {
      title: "Destacado",
      text: "No hay Destacado para mostrar",
      id: "destacado",
      nombre: "Sociedad",
    },
    {
      title: "Otros artículos",
      text: "No hay Otros artículos para mostrar",
      id: "otrosArticulos",
      nombre: "Sociedad",
    },
  ],
  internacional: [
    {
      title: "Agenda del día",
      text: "No hay Agenda del día para mostrar",
      id: "agendaDia",
      nombre: "Internacional",
    },
    {
      title: "Indicadores económicos",
      text: "No hay Indicadores económicos para mostrar",
      id: "indicadoresEconomicos",
      nombre: "Internacional",
    },
    {
      title: "Otros artículos",
      text: "No hay Otros artículos para mostrar",
      id: "otrosArticulos",
      nombre: "Internacional",
    },
  ],
};

export const destacadoDummy = {
  img: `${imgDummy2}`,
  title:
    "Peso cierra con ligera caída ante dólar tras registrar su mejor nivel desde marzo",
  subText:
    "Estos son los temas que están dominando la opinión pública el día de hoy",
  text:
    "El peso cotizó en 21.45 unidades por dólar, cerca del cierre de la jornada, con una caída de un 0.5 por ciento, frente a los 21.35 pesos del precio de referencia del miércoles. En operaciones temprano llegó a apreciarse hasta las 21.21 unidades, en sus máximos desde el 11 de marzo. En bancos,  la divisa estaduidense se ofertó hasta en 21.88 pesos, como es el caso de Citibanamex. En tanto, BBVA México lo hizo en 21.73 y Banorte en 21.70 unidades. Te recomendamos: Jorge Arganis Díaz Leal, titular de la SCT, da positivo a coronavirus El peso mexicano se depreció el jueves, pero tocó su mejor nivel de seis meses en una sesión de altibajos marcada inicialmente por un declive del dólar tras conocerse que la cantidad de estadunidenses que presentaron nuevas solicitudes de beneficios por desempleo se mantuvo en niveles altos la semana pasada. En el mercado de deuda local, el rendimiento del bono a 10 años cedió un punto base a un 5.76 por ciento, mientras que la tasa a 20 años subió uno a 6.70 por ciento. ",
};

export const agendaDiaDummy = {
  subText:
    "Estos son los temas que están dominando la opinión pública el día de hoy",
  pie: [
    {
      title: "Mejora pronóstico del PIB: caería 9.97% en 2020",
      articulo: "5f87750c33db540017e44bbb",
      color: "#44CD40",
      porcentaje: 35,
    },
    {
      title: "Alista IP nuevo Plan de Reactivación Económica",
      articulo: "5f87750433db540017e44bba",
      color: "#404FCD",
      porcentaje: 28,
    },
    {
      title: "Este gobierno no será recordado por corrupto: AMLO",
      articulo: "5f87751333db540017e44bbc",
      color: "#C70039",
      porcentaje: 24,
    },
    {
      title: "Crecen remesas 7.2% en julio pese a pandemia",
      articulo: "5f8774fa33db540017e44bb9",
      color: "#EBD22F",
      porcentaje: 13,
    },
  ],
};

export const agendaDiaModalDummy = {
  img: `${imgDummy1}`,
  date: "Martes 07/Oct/2020",
  title: "Este gobierno no será recordado por corrupto:AMLO",
  text:
    "A pesar de que lo presentó bajo un contexto de dos crisis, la económica y sanitaria, el presidente Andrés Manuel López Obrador únicamente dedicó 44 minutos a su Segundo Informe de Gobierno, del cual la novedad fue ausente y casi los aplausos.\n Tras entonarse el Toque de Silencio en memoria de las víctimas de Covid-19 y los honores hacía él, López Obrador, inexpresivo y con voz media que fue sostenida a lo largo del discurso, cumplió lo prometido: comenzó abordando el tema de la corrupción, que junto con su política de austeridad, se miden en 150 mil millones de pesos. \n “Este Gobierno no será recordado por corrupto. Nuestro principal legado será purificar la vida pública de México y estamos avanzando. No hemos emprendido persecuciones facciosas ni venganzas políticas, pero tampoco encubrimos a nadie ni permitimos la impunidad. Ya se acabó la robadera de los de arriba, pero todavía falta desterrar por completo el bandidaje oficial”. \n Como ejemplo de que no hay persecución política, resaltó que en el caso de los expresidentes Enrique Peña Nieto y Felipe Calderón, quienes podrían estar involucrados en los procesos abiertos contra sus colaboradores, no dará línea a la Fiscalía General de la República ni a la Suprema Corte de Justicia, quienes no asistieron al Informe.",
};

export const panorameNaDummy = {
  text:
    "El principio de legalidad, uno de los pilares del Estado de derecho, presupone que el ejercicio del poder público debe estar sujeto a la ley y a su jurisdicción. Esto significa que las autoridades públicas actuarán conforme a lo que expresamente les faculten las leyes. En consecuencia, el Estado debe funcionar de acuerdo a la ley, no en atención a la voluntad de quien detente un cargo público.\n  Este principio, en el régimen jurídico de la responsabilidad del Estado, da certidumbre legal. Debido a su vigencia, por ejemplo, los particulares están obligados a pagar impuestos de forma proporcional y equitativa y la única autoridad facultada para exigirles su cobro es la Secretaría de Hacienda. Un gobernado puede inconformarse si el pago de impuestos no es equitativo o si quien pretende cobrarlos es una autoridad sin atribuciones para ello. \n ¿Es posible que una autoridad viole el principio de legalidad? Sí. En estos casos, el ciudadano tiene la posibilidad de acudir a un tribunal que, si bien es un ente gubernamental, al depender de otro poder está obligado a resolver imparcialmente el conflicto entre la autoridad y el particular. Si un tribunal violara el principio de legalidad, el asunto se somete a la consideración de un tribunal superior quien resolverá de manera definitiva. ",
};

export const panoramaInDummy = {
  text:
    "El principio de legalidad, uno de los pilares del Estado de derecho, presupone que el ejercicio del poder público debe estar sujeto a la ley y a su jurisdicción. Esto significa que las autoridades públicas actuarán conforme a lo que expresamente les faculten las leyes. En consecuencia, el Estado debe funcionar de acuerdo a la ley, no en atención a la voluntad de quien detente un cargo público.\n  Este principio, en el régimen jurídico de la responsabilidad del Estado, da certidumbre legal. Debido a su vigencia, por ejemplo, los particulares están obligados a pagar impuestos de forma proporcional y equitativa y la única autoridad facultada para exigirles su cobro es la Secretaría de Hacienda. Un gobernado puede inconformarse si el pago de impuestos no es equitativo o si quien pretende cobrarlos es una autoridad sin atribuciones para ello. \n ¿Es posible que una autoridad viole el principio de legalidad? Sí. En estos casos, el ciudadano tiene la posibilidad de acudir a un tribunal que, si bien es un ente gubernamental, al depender de otro poder está obligado a resolver imparcialmente el conflicto entre la autoridad y el particular. Si un tribunal violara el principio de legalidad, el asunto se somete a la consideración de un tribunal superior quien resolverá de manera definitiva. ",
};
export const encabezadosDummy = [
  {
    name: "nacional",
    encabezados: [
      {
        img: na1,
        title: "Van 800 mil firmas de consulta: alista AMLO escrito",
        url: "",
      },
      {
        img: na2,
        title:
          "OMS prevé que pandemia del Covid-19 emperoe y muertos aumenten en Europa",
        url: "",
      },
      {
        img: na3,
        title: "López-Gatell: hay sabotaje constante a plan anti-Covid",
        url: "",
      },
      {
        img: na4,
        title: "AMLO plantea cancelar contrato con Braskem-Idesa",
        url: "",
      },
      {
        img: na5,
        title:
          "Sacar de la crisis a Latam será el reto de Claver al frente del BID",
        url: "",
      },
      {
        img: na6,
        title:
          "Gobierno plantea cuota adicional al IEPS de gasolina si petróleo se abarata",
        url: "",
      },
      {
        img: na7,
        title: "López Obrador recibe a familias de mineros de Pasta de Conchos",
        url: "",
      },
      {
        img: na8,
        title: "Promueven candado a precio de gasolina",
        url: "",
      },
      {
        img: na9,
        title: "Tomás Zerón se encuentra en Israel, revela López Obrador",
        url: "",
      },
    ],
  },
  {
    name: "internacional",
    encabezados: [
      {
        img: in1,
        title: "Europa aprende a vivir con el coronavirus",
        url: "",
      },
      {
        img: in2,
        title: "Trump pierde el control de su propia propaganda",
        url: "",
      },
    ],
  },
];

export const semaforosDummy = [
  {
    name: "Economía y Finanzas",
    semaforos: [
      {
        title: "Mejora pronóstico del PIB; caería 9.97% en 2020",
        color: "#50B351",
      },
      {
        title: "Alista IP nuevo plan de reactivación económica",
        color: "#50B351",
      },
    ],
  },
  {
    name: "Internacional",
    semaforos: [
      {
        title: "Visita Trump Kenosha y desdeña reclamo antiracista",
        color: "#FCA311",
      },
      {
        title: "Va EU solo en la búsqueda de la vacuna universal",
        color: "#FCA311",
      },
      {
        title: "Denuncia ex-agente de Ecuador complot contra Correa",
        color: "#FCA311",
      },
    ],
  },
  {
    name: "Pólitico y legislativo",
    semaforos: [
      {
        title: "Este gobierno no será recordado por corrupto: AMLO",
        color: "#50B351",
      },
      {
        title: "Maquilló AMLO cifras, según PAN, PRD y PRI",
        color: "#FCA311",
      },
      {
        title: "Insiste MORENA en recortar 50% a partidos",
        color: "#FCA311",
      },
    ],
  },
  {
    name: "Seguridad y Sociedad",
    semaforos: [
      {
        title: "Acusa Chihuahua a Collado de peculado por 13.7 mdp",
        color: "#DB2C2B",
      },
      {
        title: "Tiene Javier Duarte nueva orden de detención",
        color: "#FCA311",
      },
      {
        title: "Rebasa México 600 mil contagios por COVID-19",
        color: "#DB2C2B",
      },
    ],
  },
];

export const opinionesDummy = [
  {
    title: "¿Que harán Google y  Ashton Kutcher en fondeadora?",
    by: "Bárbara Anderson",
    date: "Martes 07/Oct/2020",
    text:
      "A pesar de que lo presentó bajo un contexto de dos crisis, la económica y sanitaria, el presidente Andrés Manuel López Obrador únicamente dedicó 44 minutos a su Segundo Informe de Gobierno, del cual la novedad fue ausente y casi los aplausos. Tras entonarse el Toque de Silencio en memoria de las víctimas de Covid-19 y los honores hacía él, López Obrador, inexpresivo y con voz media que fue sostenida a lo largo del discurso, cumplió lo prometido: comenzó abordando el tema de la corrupción, que junto con su política de austeridad, se miden en 150 mil millones de pesos. “Este Gobierno no será recordado por corrupto. Nuestro principal legado será purificar la vida pública de México y estamos avanzando. No hemos emprendido persecuciones facciosas ni venganzas políticas, pero tampoco encubrimos a nadie ni permitimos la impunidad. Ya se acabó la robadera de los de arriba, pero todavía falta desterrar por completo el bandidaje oficial”. Como ejemplo de que no hay persecución política, resaltó que en el caso de los expresidentes Enrique Peña Nieto y Felipe Calderón, quienes podrían estar involucrados en los procesos abiertos contra sus colaboradores, no dará línea a la Fiscalía General de la República ni a la Suprema Corte de Justicia, quienes no asistieron al Informe.",
  },
  {
    title: "Legalidad y Tribunal Electoral",
    by: "Francisco Cárdenas",
    date: "Martes 07/Oct/2020",
    text:
      "A pesar de que lo presentó bajo un contexto de dos crisis, la económica y sanitaria, el presidente Andrés Manuel López Obrador únicamente dedicó 44 minutos a su Segundo Informe de Gobierno, del cual la novedad fue ausente y casi los aplausos. Tras entonarse el Toque de Silencio en memoria de las víctimas de Covid-19 y los honores hacía él, López Obrador, inexpresivo y con voz media que fue sostenida a lo largo del discurso, cumplió lo prometido: comenzó abordando el tema de la corrupción, que junto con su política de austeridad, se miden en 150 mil millones de pesos. “Este Gobierno no será recordado por corrupto. Nuestro principal legado será purificar la vida pública de México y estamos avanzando. No hemos emprendido persecuciones facciosas ni venganzas políticas, pero tampoco encubrimos a nadie ni permitimos la impunidad. Ya se acabó la robadera de los de arriba, pero todavía falta desterrar por completo el bandidaje oficial”. Como ejemplo de que no hay persecución política, resaltó que en el caso de los expresidentes Enrique Peña Nieto y Felipe Calderón, quienes podrían estar involucrados en los procesos abiertos contra sus colaboradores, no dará línea a la Fiscalía General de la República ni a la Suprema Corte de Justicia, quienes no asistieron al Informe.",
  },
  {
    title: "Para el 15, jefe, Premio Mayor. Memorias del Coronavirus",
    by: "Gil Gamés",
    date: "Martes 07/Oct/2020",
    text:
      "A pesar de que lo presentó bajo un contexto de dos crisis, la económica y sanitaria, el presidente Andrés Manuel López Obrador únicamente dedicó 44 minutos a su Segundo Informe de Gobierno, del cual la novedad fue ausente y casi los aplausos. Tras entonarse el Toque de Silencio en memoria de las víctimas de Covid-19 y los honores hacía él, López Obrador, inexpresivo y con voz media que fue sostenida a lo largo del discurso, cumplió lo prometido: comenzó abordando el tema de la corrupción, que junto con su política de austeridad, se miden en 150 mil millones de pesos. “Este Gobierno no será recordado por corrupto. Nuestro principal legado será purificar la vida pública de México y estamos avanzando. No hemos emprendido persecuciones facciosas ni venganzas políticas, pero tampoco encubrimos a nadie ni permitimos la impunidad. Ya se acabó la robadera de los de arriba, pero todavía falta desterrar por completo el bandidaje oficial”. Como ejemplo de que no hay persecución política, resaltó que en el caso de los expresidentes Enrique Peña Nieto y Felipe Calderón, quienes podrían estar involucrados en los procesos abiertos contra sus colaboradores, no dará línea a la Fiscalía General de la República ni a la Suprema Corte de Justicia, quienes no asistieron al Informe.",
  },
  {
    title: "Las protestas violentas y su legitimidad",
    by: "Paulina Rivero",
    date: "Martes 07/Oct/2020",
    text:
      "A pesar de que lo presentó bajo un contexto de dos crisis, la económica y sanitaria, el presidente Andrés Manuel López Obrador únicamente dedicó 44 minutos a su Segundo Informe de Gobierno, del cual la novedad fue ausente y casi los aplausos. Tras entonarse el Toque de Silencio en memoria de las víctimas de Covid-19 y los honores hacía él, López Obrador, inexpresivo y con voz media que fue sostenida a lo largo del discurso, cumplió lo prometido: comenzó abordando el tema de la corrupción, que junto con su política de austeridad, se miden en 150 mil millones de pesos. “Este Gobierno no será recordado por corrupto. Nuestro principal legado será purificar la vida pública de México y estamos avanzando. No hemos emprendido persecuciones facciosas ni venganzas políticas, pero tampoco encubrimos a nadie ni permitimos la impunidad. Ya se acabó la robadera de los de arriba, pero todavía falta desterrar por completo el bandidaje oficial”. Como ejemplo de que no hay persecución política, resaltó que en el caso de los expresidentes Enrique Peña Nieto y Felipe Calderón, quienes podrían estar involucrados en los procesos abiertos contra sus colaboradores, no dará línea a la Fiscalía General de la República ni a la Suprema Corte de Justicia, quienes no asistieron al Informe.",
  },
];

export const otrosArticulosDummy = [
  {
    titulo: "Expertos cuestionan las metas fiscales para 2021",
    cuerpo: "ABC",
    photoURL: na8,
  },
  {
    titulo: "Expertos cuestionan las metas fiscales para 2021",
    cuerpo: "ABC",
    photoURL: na8,
  },
  {
    titulo: "Expertos cuestionan las metas fiscales para 2021",
    cuerpo: "ABC",
    photoURL: na8,
  },
  {
    titulo: "Expertos cuestionan las metas fiscales para 2021",
    cuerpo: "ABC",
    photoURL: na8,
  },
];

export const indicadorEDummy = {
  metales: [
    {
      tipo: "COBRE",
      unidad: "USD/MT",
      costo: "6,687.50",
    },
    {
      tipo: "ALUMINIO",
      unidad: "USD/MT",
      costo: "1,816.50",
    },
    {
      tipo: "ACERO",
      unidad: "USD/MT",
      costo: "18,186",
    },
  ],
};

export const acordionDummy = [
  {
    title: "Conmemoran 50 aniversario luctuoso de Lázaro Cárdenas",
    cuerpo: "Amlo selló un timbre postal en conmemoración del 50 aniversario.",
  },
  {
    title: "Ofrecen Orsan, Total y G500 gasolina más barata",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.",
  },
  {
    title: "Realizan a AMLO prueba de Covid cada semana",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.",
  },
  {
    title: "No se debe descalificar al ejército por caso Cienfuegos",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.",
  },
  {
    title: "Celebra AMLO que elecciones se hayan realizado sin violencia",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.",
  },
  {
    title: "Necesiario que puertos estén a cargo de la Semar",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.das dad asdasd asd asdas dasd asdasdas djas dl asdasn dl jasdas   asnd aljslasb lasblab labal ablabl",
  },
  {
    title: "Conmemoran 50 aniversario luctuoso de Lázaro",
    cuerpo:
      "Amlo selló un timbre postal en conmemoración del 50 aniversario luctuoso de Lázaro Cárdenas, a quien considera el mejor presidente de México durante el siglo XX. También se realizará, a las 10:00 am, un acto en el monumento a la revolución.",
  },
];
