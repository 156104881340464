import React from "react";
// import { Plugins } from "@capacitor/core";
import chroma from "chroma-js";
import draftToHtml from "draftjs-to-html";
import styled from "styled-components";
import { Plugins, Capacitor } from "@capacitor/core";

import {
  ButtonStoreCont,
  BackButtonCont,
  ShareButtonCont,
  TargetCont,
  EncabezadoCont,
  SemaforoArticuloCont,
  NewsCont,
  ButtonNewsCont,
  AcordionButtonCont,
  BtnSemaforoCont,
} from "../../styles";

import { ReactComponent as BackButtonSVG } from "../../assets/icons/backButton.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as Bullet } from "../../assets/icons/bullet.svg";
import { ReactComponent as NextButton } from "../../assets/icons/nextButton.svg";

export const AcordionButton = ({ state, setState, title, text, id, color }) => {
  const handleChange = () => {
    if (id === state) {
      setState(null);
    } else {
      setState(id);
    }
  };
  const refText = React.useRef(null);
  return (
    <AcordionButtonCont
      state={id === state ? true : false}
      color={color}
      heightText={
        refText.current ? `${refText.current.offsetHeight}px` : "100px"
      }
      colorText={null}
    >
      <div className="acordionCont" onClick={() => handleChange()}>
        <Bullet />
        <div className="acordionCont-title">
          <h4> {title} </h4>
        </div>
        <ArrowRight className="arrow" />
      </div>
      <div className="acordionTextCont">
        <div className="acordionTextCont-text" ref={refText}>
          {text &&
            text.map((item, index) => {
              return (
                <div
                  className="destCont-text"
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(item),
                  }}
                  key={index}
                ></div>
              );
            })}
        </div>
      </div>
    </AcordionButtonCont>
  );
};

export const News = ({ color, title, data, hidden, destacado }) => {
  const [modal, setModal] = React.useState(false);
  const [acordion, setAcordion] = React.useState(null);
  var colorT = chroma.contrast(color, "white") > 2 ? "white" : "black";
  return (
    <>
      <ButtonNewsCont color={color} colorText={colorT}>
        <button type="button" className="button" onClick={() => setModal(true)}>
          <h3> {title} </h3>
          <ArrowRight className="svg-right" />
        </button>
      </ButtonNewsCont>
      <NewsCont color={color} modal={modal} colorText={colorT}>
        <div className="newsCont" onClick={() => setModal(false)}>
          <ArrowLeft className="svg-left" />
          <div className="newsCont-title">
            <h3> {title} </h3>
          </div>
          <ArrowRight className="svg-right" />
        </div>
        <div className="newsModal">
          {data &&
            data.map((acor, i) => (
              <AcordionButton
                key={i}
                id={i}
                title={acor.titulo}
                text={JSON.parse(acor.cuerpo)}
                state={acordion}
                setState={setAcordion}
                color={color}
              />
            ))}
          {data.length === 0 && <div>No hay información para mostrar</div>}
        </div>
        {!modal && destacado}
      </NewsCont>
    </>
  );
};

export const _blank = (e) => {
  let otherWindow = window.open();
  otherWindow.opener = null;
  otherWindow.location = e;
  return false;
};

export const ButtonStore = ({ text, title, url, img }) => {
  return (
    <ButtonStoreCont onClick={() => _blank(url)}>
      <img src={img} alt="icono" />
      <div className="textCont">
        <p className="btext"> {text} </p>
        <h3 className="btitle"> {title} </h3>
      </div>
    </ButtonStoreCont>
  );
};

export const BackButton = ({ onClick, text = "Regresar" }) => {
  return (
    <BackButtonCont onClick={onClick}>
      <div className="backButtonCont">
        <BackButtonSVG />
        <span className="backButtonCont-text"> </span>
      </div>
    </BackButtonCont>
  );
};

export const Target = ({ text = "nacional", caps = true }) => {
  return (
    <TargetCont caps={caps}>
      <div className="targetCont">
        <p> {text} </p>
      </div>
    </TargetCont>
  );
};

export const Encabezado = ({ img, url, title, fontSizeValue }) => {
  const { App } = Plugins;
  const platform = Capacitor.getPlatform();

  const openURL = async () => {
    const ua = navigator.userAgent.toLowerCase();
    let ventana = null;
    if (ua.indexOf("safari") !== -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        ventana = window.open(url, "about:blank", "_blank");
      }
    }

    if (platform === "web") {
      if (ua.indexOf("safari") !== -1) {
        if (ua.indexOf("chrome") > -1) {
          window.open(
            url,
            "_blank",
            "status=yes, toolbar=no, menubar=no, location=no,addressbar=no"
          );
        } else {
          ventana.location = url;
        }
      }
    } else {
      await App.openUrl(url);
    }
  };

  return (
    <EncabezadoCont fontSizeValue={fontSizeValue}>
      <div className="encabezadoCont">
        <img className="encabezadoCont-image" src={img} alt="logo" />
        <h3 className="encabezadoCont-title"> {title} </h3>
        <div className="encabezadoCont-link">
          <p onClick={openURL}>Ir a nota</p>
        </div>
      </div>
    </EncabezadoCont>
  );
};

export const SemaforoArticulo = ({ span, title }) => {
  return (
    <SemaforoArticuloCont span={span}>
      <div className="semaforoActiculoCont">
        <span className="semaforoActiculoCont-span"></span>
        <div className="semaforoActiculoCont-title">
          <h3> {title} </h3>
        </div>
        <div className="semaforoActiculoCont-arrow">
          <NextButton />
        </div>
      </div>
    </SemaforoArticuloCont>
  );
};

export const ShareButton = ({
  textButton = "Compartir",
  title = "El Medio de Medios",
  text = "La información que realmente importa",
  url = "https://www.elmediodemedios.com/",
}) => {
  // const shareAction = async () => {
  //   const { Share } = Plugins;
  //   await Share.share({
  //     title: title,
  //     text: text,
  //     url: url,
  //     dialogTitle: "El medio de medios",
  //   });
  // };
  const shareAction = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: `${text} \n`,
          url: url,
        })
        .then(() => console.log("Gracias por Compartir!"))
        .catch(console.error);
    } else {
      console.log("Maybe next time ;)");
    }
  };
  return (
    <ShareButtonCont onClick={() => shareAction()}>
      <div className="shareButtonCont">
        <span className="shareButtonCont-text"> {textButton} </span>
        {/* <ShareButtonSVG /> */}
      </div>
    </ShareButtonCont>
  );
};

export const BtnSemaforo = ({ name, color, text, onClick, state, className }) => {
  return (
    <BtnSemaforoCont btnColor={color} onClick={onClick} state={state} className={className}>
      <div className="text">
        <p> {text} </p>
      </div>
      <div className="name">
        <p> {name} </p>
      </div>
    </BtnSemaforoCont>
  );
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    !props.flexDirection ? "column" : props.flexDirection};
  margin: ${(props) => (!props.margin ? "0 10%" : props.margin)};
  color: black;
`;

export const ErrorMessage = styled.span`
  height: 18px;
  width: 100%;
  display: flex;
  margin: 0;
  color: #fa4443;
  font-size: 13px;
  font-family: "Oswald";
`;

export const Hr = styled.hr`
  width: 60%;
  max-width: 420px;
  margin: 0 auto;
  border: 1px solid #e5e5e5;
  background-color: #e5e5e5;
`;
