import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();

const obtenerIndicadores = (data) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query buscarIndicadores($tipo: String, $Token: String, $uid: String) {
            buscarIndicadores(tipo: $tipo,Token: $Token, uid: $uid) {
              nombre
              value
              indice
              isgain
              link
              fuente
            }
          }
        `,
                variables: data,
            })
            .then((result) => {
                resolve(result.data.buscarIndicadores);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export { obtenerIndicadores };