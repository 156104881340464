import React, { useContext } from "react";
import styled from "styled-components";
import { IonModal } from "@ionic/react";
import draftToHtml from "draftjs-to-html";

import { BackButton, ShareButton } from "../modules";
import { fontSize, leaveAnimation, enterAnimation } from "../../utils";
import contexto from "../../Contexto/contexto";

const ModalCont = styled.div`
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  .modalCont {
    display: flex;
    display: -webkit-flex;
    display: -webkit-box;
    flex-direction: column;
    -webkit-box-orient: vertical;
    &-header {
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;
      border-radius: 0 0 12px 12px;
      -webkit-box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
      box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
      h2 {
        font-family: DM Serif Display;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
      }
    }
    &-share {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      justify-content: flex-end;
      align-items: center;
      padding: 0 5%;
      height: 70px;
    }
    &-body {
      display: flex;
      flex-direction: column;
      display: -webkit-flex;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      height: calc(100vh - 130px - env(safe-area-inset-top));
      width: 100%;
      overflow: auto;
      &-contD{
        display:none;
      }
      &-contM{
        display: flex;
        flex-direction: column;
        display: -webkit-flex;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        width: 100%;
        height:100%;
        &-img {
          display: -webkit-flex;
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          flex-direction: column;
          height: auto;
          width: 100%;
          img {
            width: 90%;
            height: 100%;
            max-height: 180px;
            object-fit: cover;
            border-radius: 8px;
            margin: 0 5%;
          }
          span {
            font-family: Open Sans;
            font-style: italic;
            font-weight: normal;
            font-size: 9px;
            line-height: 12px;
            width: 90%;
            text-align: right;
            margin: auto;
            margin-top: 10px;
          }
        }
        &-title {
          display: flex;
          flex-direction: column;
          display: -webkit-flex;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          width: 100%;
          padding: 0 5%;
          margin: 10px 0;
          h3 {
            text-align: left;
            font-size: ${fontSize.titleBig};
            font-family: DM Serif Display !important;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 115.87%;
          }
        }
        &-text {
          display: flex;
          flex-direction: column;
          display: -webkit-flex;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          padding: 0 5%;
          margin: 20px 0;
          * {
            margin-bottom: 10px;
            text-align: justify !important;
            font-size: ${(props) =>
              props.appFontSize ? props.appFontSize : "10px"} !important;
            font-family: Open Sans !important;
          }
        }
        &-fuente{
          display: flex;
          display: -webkit-flex;
          display: -webkit-box;
          padding:0 5%;
          flex-wrap:wrap;
          max-width: 640px;
          width:100%;
          margin:0 auto;
          span{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #585858;
            margin-right: 10px;
          }
          .funt{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #235789;
            background: #EEECEC;
            border-radius: 4px;
            padding:3px 8px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalCont {
      width: 100%;
      &-header {
        padding: 0 10%;
      }
      &-share {
        padding: 0 10%;
      }
      &-body {
        width: 100%;
        &-contM{
          &-img {
            img {
              width: 80%;
              height: 100%;
              max-height: 340px;
              margin: 0 10%;
              object-fit: cover;
            }
            span {
              margin: 0 10%;
              margin-top: 10px;
              width: 80%;
            }
          }
          &-title {
            width: 100%;
            max-width: 640px;
            margin: 0 auto;
            padding: 15px 0;
          }
          &-text {
            width: 100%;
            max-width: 640px;
            margin: 0 auto;
            padding: 15px 0;
          }
          &-fuente{
            padding:0;
          }
        }
      }
    }
  }
  @media(min-width: 1280px){
    .modalCont {
      &-body {
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
        -ms-flex-direction:row;
        flex-direction:row;
        padding:0 10%;
        &-contD{
          display: flex;
          display: -webkit-flex;
          display: -webkit-box;
          -webkit-box-orient:vertical;
          -webkit-box-direction:normal;
          -ms-flex-direction:column;
          flex-direction:column;
          &-img {
            display: -webkit-flex;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            flex-direction: column;
            img {
              width: 360px;
              height: 360px;
              object-fit: cover;
              border-radius: 8px;
            }
            span {
              font-family: Open Sans;
              font-style: italic;
              font-weight: normal;
              font-size: 9px;
              line-height: 12px;
              width: 100%;
              text-align: right;
              margin: auto;
              margin-top: 10px;
            }
          }
          &-fuente{
            display: flex;
            display: -webkit-flex;
            display: -webkit-box;
            padding:0;
            flex-wrap:wrap;
            max-width: 640px;
            width:100%;
            margin-top:20px;
            justify-content:flex-end;
            span{
              font-family: Open Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              color: #585858;
              margin-left: 10px;
            }
            .funt{
              font-family: Open Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              color: #235789;
              background: #EEECEC;
              border-radius: 4px;
              padding:3px 8px;
            }
          }
        }
        &-contM{
          width:calc(100% - 360px);
          padding: 0 20px;
          &-img {
            display:none;
          }
          &-title {
            width: 100%;
            max-width: none;
            margin: 0 ;
            padding: 0  0 15px 0;
          }
          &-text {
            width: 100%;
            max-width: none;
            margin: 0;
            padding: 15px 0;
          }
          &-fuente{
            display:none;
          }
        }
      }
    }
  }
`;
const ModalArticulo = ({
  img,
  titulo,
  cuerpo,
  state,
  setSate,
  active,
  section,
  ubicacion = "Palacio Nacional",
  fuente = [
    'Reforma',
    'Universal',
    'Milenio'
  ],
}) => {
  const { appFontSize } = useContext(contexto);
  const titulos = {
    agenda: "AGENDA",
    internacional: "INTERNACIONAL",
    politica: "POLÍTICA Y LEGISLATIVO",
    economia: "ECONOMÍA Y NEGOCIOS",
    sociedad: "SOCIEDAD Y SEGURIDAD",
    profile: "CONFIGURACIÓN",
  };
  return (
    <IonModal
      isOpen={state}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
      cssClass="fullscreen"
    >
      <ModalCont appFontSize={appFontSize}>
        <div className="modalCont">
          <div className="modalCont-header">
            <BackButton onClick={() => setSate(false)} />
            <h2> {titulos[section]} </h2>
            <div className=""></div>
          </div>
          <div className="modalCont-share">
            <ShareButton />
          </div>
          <div className="modalCont-body">
            <div className="modalCont-body-contD">
              <div className="modalCont-body-contD-img">
                <img src={img} alt="" />
                <span> Foto: {ubicacion} </span>
              </div>
              <div className="modalCont-body-contD-fuente">
                <span>Fuente: </span>
                {fuente.map((data) => (
                  <span className="funt">
                    {data}
                  </span>
                ))
                }
              </div>
            </div>
            <div className="modalCont-body-contM">
              <div className="modalCont-body-contM-img">
                <img src={img} alt="" />
                <span> Foto: {ubicacion} </span>
              </div>
              <div className="modalCont-body-contM-title">
                <h3> {titulo} </h3>
              </div>
              <div
                className="modalCont-body-contM-text"
                dangerouslySetInnerHTML={{
                  __html: active ? cuerpo : draftToHtml(JSON.parse(cuerpo)),
                }}
              ></div>
              <div className="modalCont-body-contM-fuente">
                <span>Fuente: </span>
                {fuente.map((data) => (
                  <span className="funt">
                    {data}
                  </span>
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </ModalCont>
    </IonModal>
  );
};

export default ModalArticulo;
