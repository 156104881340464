import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
const deshabilitarCuenta = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query disableAccount($uid: String!) {
            disableAccount(uid: $uid)
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.disableAccount);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { deshabilitarCuenta };
