import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { colors } from "../../utils";
import { obtenerSemaforoDiaDeHoy } from "../../consumo_apollo_client/clienteSemaforo";
import ArticuloSemaforo from "../auth/ArticuloSemaforo";
import { BtnSemaforo } from '../modules'

const SemaforoCont = styled.div`
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 190px - env(safe-area-inset-top));
  /* width: 100%; */
  background: ${colors.white};
  overflow: auto;
  .semaforoCont {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    /* height: 100%; */
    width: 100%;
    &-semaforos{
      display: -ms-flexbox;
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      height:52px;
      align-items:center;
      padding:0 5%;
    }
    div{
      .title{
        width: 100%;
        padding: 0 5%;
        max-width: 640px;
        margin: auto;
        display:none;
        h3{
          font-family: DM Serif Display;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 115.87%;
          text-transform: capitalize;
          height: 50px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
      }
    }
  }
  @media (min-width:768px){
    .semaforoCont{
      &-semaforos{
        padding:0;
      }
      div{
        .title{
          padding: 0px;
          display:flex;
        }
      }
    }
  }
  @media (min-width: 1025px){
    height: calc(100vh - 60px - env(safe-area-inset-top));
  }
`;
const Semaforo = ({ refresh, setRefresh, section }) => {
  const [semaforo, setSemaforo] = useState(null);
  const textAlert = useRef(null);
  useEffect(() => {
    if (refresh === true) {
      obtenerSemaforoDiaDeHoy().then((res) => {
        if (res) {
          setRefresh(false)
          setSemaforo(res);
        } else {
          setSemaforo(null);
        }
      });
    }

    obtenerSemaforoDiaDeHoy().then((res) => {
      if (res) {
        setSemaforo(res);
      } else {
        setSemaforo(null);
      }
    });// eslint-disable-next-line
  }, [refresh]);

  const [idSemaf, setIdSemaf] = useState(null);
  const typeSemaforos = [
    {
      name: 'Verde',
      color: '#6BAC6C',
      text: 'Positiva'
    },
    {
      name: 'Amarillo',
      color: '#FCA311',
      text: 'Preventiva'
    },
    {
      name: 'Rojo ',
      color: '#DB2C2B',
      text: 'Riesgo'
    }
  ]
  return (
    <SemaforoCont heightAlert={textAlert.current ? textAlert.current.offsetHeight : 200}>
      <div className="semaforoCont">
        <div className="semaforoCont-semaforos">
          {typeSemaforos.map((data, i) => (
            <BtnSemaforo 
              name={data.name} 
              text={data.text} 
              color={data.color}
              state={idSemaf === i}
              onClick={() => idSemaf === i ? setIdSemaf(null) : setIdSemaf(i)}
              className
              />
          ))}
        </div>
        {semaforo != null &&
          semaforo.map((sema, o) => (
            <div key={o}>
              <div className="title">
                <h3> {sema.categoria} </h3>
              </div>
              {sema.luces.map((arti, i) =>  (
                <ArticuloSemaforo key={i} articulo={arti} section={section} />
              ))}
            </div>
          ))}
      </div>
    </SemaforoCont>
  );
};

export default Semaforo;
