import React from 'react';
import MembershipHome from '../components/membership/Membership'

const Membership = () => {
  return(
    <> 
      <MembershipHome />
    </>
  )
};

export default Membership;