import React, {useRef} from "react";
import styled from "styled-components";

const ButtonThumbsCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 50px;
  cursor: pointer;
  .text {
    -webkit-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
    margin: auto;
    color: ${(props) => (props.state ? "#fff" : "#868585")};
    padding: 4px 7px;
    border-bottom: 1px solid ${(props) => (props.state ? "#235789" : "white")};
    background:${(props) => (props.state ? "#235789" : "#eee")};
    font-weight: ${(props) => (props.state ? 600 : 400)};
    font-family: "Open Sans";
    font-size: 14px;
    cursor: pointer;
    border-radius:4px;
    /* max-height:28px; */
    /* white-space: nowrap; */
  }
  @-webkit-keyframes animate {
    to {
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 0%;
      top: 0%;
      left: 0%;
    }
  }
  @keyframes animate {
    to {
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 0%;
      top: 0%;
      left: 0%;
    }
  }
`;
const ButtonThumbs = ({ text, state}) => {
  const width = useRef(null);
  // const wid = width.current ? width.current.offsetWidth + 18 : 100;
  return (
    <ButtonThumbsCont className="buttonThumbs" state={state}>
      <p className="text" ref={width}> {text} </p>
    </ButtonThumbsCont>
  );
};

export default ButtonThumbs;
