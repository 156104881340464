import fire from "firebase/app";
import "@firebase/firestore";
import firebaseConfig from './firebaseCredentials'

fire.initializeApp(firebaseConfig);
const db = fire.firestore();
// const RedirectAPI = "127.0.0.1";
const campos = ["ciudad", "estado", "nombre", "ocupacion"];


const loginuid = (user, pass) => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .signInWithEmailAndPassword(user, pass)
            .then((u) => {
                fire
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                        resolve({
                            token: idToken,
                            uid: u.user.uid,
                        });
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const login = (user, pass) => {
    const sessionToken = Math.random().toString(36).substring(2);
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .signInWithEmailAndPassword(user, pass)
            .then((u) => {
                fire
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(async (idToken) => {
                        db.collection("usuario").doc(u.user.uid).get()
                            .then(user => {
                                if (user.data().sessionToken && user.data().sessionToken.length > 0) {
                                    db.collection("usuario")
                                        .doc(u.user.uid)
                                        .update({
                                            sessionToken: [...user.data().sessionToken, sessionToken]
                                        })
                                    resolve({
                                        token: idToken,
                                        uid: u.user.uid,
                                        nombre: user.get("nombre"),
                                        sessionToken: sessionToken
                                    });
                                } else {
                                    db.collection("usuario")
                                        .doc(u.user.uid)
                                        .update({
                                            sessionToken: [sessionToken]
                                        })
                                    resolve({
                                        token: idToken,
                                        uid: u.user.uid,
                                        nombre: user.get("nombre"),
                                        sessionToken: sessionToken
                                    });
                                }
                            })

                        // const user = await db.collection('usuario').doc(u.user.uid).get()
                        // console.log('await', sessionToken,user.data())
                        // const newArray = user.data().sessionToken
                        // const array = newArray.push(sessionToken)
                        // console.log(array)
                        // db.collection('usuario').doc(u.user.uid).get()
                        //   .then(userUpdated => {
                        //     console.log(userUpdated.data().sessionToken.length, sessionToken)
                        // if (userUpdated.data().sessionToken.length === 4) {
                        //   db.collection("usuario")
                        //     .doc(u.user.uid)
                        //     .update({
                        //       sessionToken: userUpdated.data().sessionToken.splice(1,userUpdated.data().sessionToken.length-1)
                        //     })
                        // }
                        // })
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const loginUserInstance = (user, pass) => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .signInWithEmailAndPassword(user, pass)
            .then((u) => {
                resolve(u);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const cambiarContraseña = (newPass) => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .currentUser.updatePassword(newPass)
            .then(() => {
                resolve(true);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const cerrarSesion = () => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .signOut()
            .then(() => {
                resolve(true);
            })
            .catch((error) => {
                reject(false);
            });
    });
};

const signup = (user, pass, nombre, ocupacion, estado, ciudad, email) => {
    const sessionToken = Math.random().toString(36).substring(2);
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .createUserWithEmailAndPassword(user, pass)
            .then(async (cred) => {
                await db
                    .collection("usuario")
                    .doc(cred.user.uid)
                    .set({
                        uid: cred.user.uid,
                        nombre: nombre,
                        ocupacion: ocupacion,
                        estado: estado,
                        ciudad: ciudad,
                        email: email,
                        sessionToken: [sessionToken]
                    })
                    .then(() => {
                        fire
                            .auth()
                            .currentUser.getIdToken(true)
                            .then(function (idToken) {
                                resolve(idToken);
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const agregarDatos = async (uid, nombre, ocupacion, estado, ciudad, email) => {
    try {
        var docRef = db.collection("usuario").doc(uid);
        let doc = await db.runTransaction((t) => t.get(docRef));
        await doc.ref
            .set({
                nombre: nombre,
                ocupacion: ocupacion,
                estado: estado,
                ciudad: ciudad,
                email: email,
            }, {
                merge: true,
            })
            .then((e) => {
                console.log("Cambio");
            })
            .catch((e) => {
                console.log(e);
            });
        var token = await fire.auth().currentUser.getIdToken();
        return token;
    } catch (e) {
        console.log(JSON.stringify(e));
        throw Error("Error");
    }
};

const signupuid = (username, email, pass) => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .createUserWithEmailAndPassword(email, pass)
            .then(async (cred) => {
                const sessionToken = Math.random().toString(36).substring(2);
                await db
                    .collection("usuario")
                    .doc(cred.user.uid)
                    .set({
                        uid: cred.user.uid,
                        nombre: username,
                        sessionToken: [sessionToken]
                    })
                    .then(() => {
                        fire
                            .auth()
                            .currentUser.getIdToken(true)
                            .then((idToken) => {
                                resolve({
                                    token: idToken,
                                    uid: cred.user.uid,
                                });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const agregarNombre = async (uid, username) => {
    const user = await db.collection('usuario').doc(uid).get()
    const sessionToken = Math.random().toString(36).substring(2);

    if (user.data() === undefined) {
        await db.collection("usuario")
          .doc(uid)
          .set({
              nombre: username,
              uid,
              sessionToken: [sessionToken]
          });

        const idToken = await fire
          .auth()
          .currentUser.getIdToken(true);

        return idToken;
    } else {
        if (user.data().sessionToken) {
           await db.collection("usuario")
              .doc(uid)
              .update({
                  nombre: username,
                  uid,
                  sessionToken: [...user.data().sessionToken, sessionToken]
              });
            const idToken = await fire
              .auth()
              .currentUser.getIdToken(true);

            const userUpdated = await db.collection('usuario').doc(uid).get()
            if (userUpdated.data().sessionToken.length === 4) {
                const newArray = userUpdated.data().sessionToken.slice(1, 4)
                await db.collection("usuario")
                  .doc(uid)
                  .update({
                      sessionToken: newArray
                  })
            }

            return idToken;
        } else {
            await db.collection("usuario")
              .doc(uid)
              .update({
                  nombre: username,
                  uid,
                  sessionToken: [sessionToken]
              });
          const idToken = fire
            .auth()
            .currentUser.getIdToken(true);

          return idToken
        }
    }
};

const obtenerImagen = (uid) => {
    return new Promise((resolve, reject) => {
        var data = fire.auth().currentUser.providerData[0];
        fire.auth().currentUser.updateProfile({ disabled: true });
        if (data.providerId === "google.com") {
            resolve(data.photoURL);
        }
        if (data.providerId === "facebook.com") {
            resolve("https://graph.facebook.com/" + data.uid + "/picture?type=large");
        }
        if (data.providerId === "password") {
            resolve(data.photoURL);
        }
    });
};

const actualizarImagen = (uid, imagen) => {
    return new Promise((resolve, reject) => {
        if (uid && imagen) {
            fire
                .auth()
                .currentUser.updateProfile({ photoURL: imagen })
                .then(() => {
                    resolve(imagen);
                })
                .catch(() => {
                    resolve("");
                });
        }
    });
};

const precios = async () => {
    var producto = await db
        .collection("products")
        .doc("prod_HmooI2kS3Klxg0")
        .get();
    var precios = [];
    var data = await db
        .collection("products")
        .doc(producto.id)
        .collection("prices")
        .get();
    data.forEach(async (doc) => {
        precios.push({
            id: doc.id,
            info: doc.data(),
        });
    });
    return precios;
};

const validarSuscripcion = async (uid) => {
    if (uid === null) {
        return
    }
    const docRef = await db.collection("usuario").doc(uid).collection("subscriptions").get();
    if (docRef.docs.length > 0) {
        for (let doc of docRef.docs) {
            const status = doc.data().status;
            switch (status) {
                case 'active':
                    return 'success'
                case 'trialing':
                    return 'success'
                case 'past_due':
                    return 'stripePortal'
                default:
                    return 'failed';
            }
        }
    } else {
        return 'failed';
    }
};

const linkcompra = async (uid, priceid, f) => {
    const docRef = await db
        .collection("usuario")
        .doc(uid)
        .collection("checkout_sessions")
        .add({
            price: priceid,
            success_url: `${process.env.REACT_APP_SERVER_ENDPOINT}/compraOk`,
            cancel_url: `${process.env.REACT_APP_SERVER_ENDPOINT}/compraCancelada`,
        });
    docRef.onSnapshot((snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
            console.log(`An error occured: ${error.message}`);
        }
        if (sessionId) {
            f(sessionId);
        }
    });
};

const datosCompletos = async (uid) => {
    const usuario = await db.collection("usuario").doc(uid).get();
    const data = await usuario.data();
    for (let c of campos) {
        if (!data[c]) return false;
    }
    return true;
};

const recuperarContraseña = async (email) => {
    return new Promise((resolve, reject) => {
        fire
            .auth()
            .sendPasswordResetEmail(email)
            .then((u) => {
                resolve("OK");
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export {
    login,
    signup,
    precios,
    linkcompra,
    validarSuscripcion,
    datosCompletos,
    recuperarContraseña,
    signupuid,
    agregarDatos,
    loginuid,
    loginUserInstance,
    agregarNombre,
    cambiarContraseña,
    cerrarSesion,
    actualizarImagen,
    obtenerImagen,
};


export const getStripeId = async (uid, returnToLogin, returnToApp) => {
    if (uid) {
        const userDoc = await db.collection('usuario').doc(uid).get()
        const user = userDoc.data()
        const subscription = await db.collection('usuario').doc(uid).collection('subscriptions').get()
        for (const doc of subscription.docs) {
            if (doc.data().status === 'active') returnToApp() // Comment this line to debug paymet-failure flow
            if (user.stripeId === undefined) returnToLogin()
            return user.stripeId
        }
    } else {
        returnToLogin()
    }
}

export const getSubscriptionId = async id => {
    const subscription = await db.collection('usuario').doc(id).collection('subscriptions').get()
    if (subscription) {
        for (const doc of subscription.docs) {
            return doc.id
        }
    }
}