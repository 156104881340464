import React, { useState } from "react";
import SemaforoArticulo from "../auth/SemaforoArticulo";
import ModalArticulo from "./ModalArticulo";

const colores = {
  Rojo: "#db2c2b",
  Verde: "#50B351",
  Amarillo: "#FCA311",
};

const ArticuloPie = ({ articulo, section }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setShowModal(true);
        }}
      >
        <SemaforoArticulo
          title={articulo.articulo.titulo}
          span={colores[articulo.color]}
        />
      </div>
      <ModalArticulo 
        img={articulo.articulo.photoURL}
        titulo={articulo.articulo.titulo}
        cuerpo={articulo.articulo.cuerpo}
        state={showModal}
        setSate={setShowModal}
        active={true}
        section={section}
      />
    </>
  );
};

export default ArticuloPie;
