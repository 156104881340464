import React, { useState, useEffect } from "react";

import ModalLogin from "../components/login/ModalLogin";
import ModalRemenberPass from "../components/login/ModalRemenberPass";
import ModalChangePass from "../components/login/ModalPass";
import ModalSuccessPass from "../components/login/ModalSuccessPass";
import ModalRegister from "../components/login/ModalRegister";
import ModalTerminos from "../components/login/ModalTerminos";
import ModalMembership from "../components/login/ModalMembership";
import ModalPayment from "../components/login/ModalPayment";
import ModalText from "../components/profile/ModalText";
import ModalSuccessRegister from "../components/login/ModalSuccessRegister";
import { Plugins } from "@capacitor/core";


import { validarSuscripcion } from "../ModuloFirebaseCliente/SignUpAndLogin";
import { useHistory } from "react-router-dom";

import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import LoginHome from "../components/login/LoginHome";

const { App } = Plugins;

const Login = () => {

  const [navegation, setNavegation] = useState("");
  const [priceRegister, setPriceRegister] = useState(true);
  const { openStore, getItem, setItem } = useStorageSQLite();
  const history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", async (data) => {
      if (data.url === "mdmapp://compraOK") {
        setNavegation("success");
      }
    });
  }, [openStore, getItem]);

  useEffect(() => {
    const validar = async () => {
      var enter = await openStore({});
      if (enter) {
        const user = await getItem("uid");
        validarInner(user);
      }
    };
    validar();
    // eslint-disable-next-line
  }, [openStore, getItem, history]);

  const validarInner = async (uid) => {
    if (uid) {
      const res = await validarSuscripcion(uid);
      switch (res) {
        case 'failed':
          history.push('/membership');
          break;
        case 'success':
          history.push('/app');
          break;
        case 'stripePortal':
          history.push('/payment-failure')
          break;
        default:
          history.push('/');
          break;
      }
    }
  };

  return (
    <>
      <LoginHome
        validarInner={validarInner}
        openStore={openStore}
        setItem={setItem}
        setNavegation={setNavegation}
      />
      <ModalLogin
        navegation={navegation}
        setNavegation={setNavegation}
        openStore={openStore}
        setItem={setItem}
        validarInner={validarInner}
      />
      <ModalRemenberPass
        navegation={navegation}
        setNavegation={setNavegation}
      />
      <ModalChangePass navegation={navegation} setNavegation={setNavegation} />
      <ModalSuccessPass navegation={navegation} setNavegation={setNavegation} />
      <ModalRegister
        navegation={navegation}
        setNavegation={setNavegation}
        openStore={openStore}
        setItem={setItem}
      />
      <ModalTerminos navegation={navegation} setNavegation={setNavegation} />
      <ModalMembership
        navegation={navegation}
        setNavegation={setNavegation}
        priceRegister={priceRegister}
        setPriceRegister={setPriceRegister}
        getItem={getItem}
      />
      <ModalPayment
        navegation={navegation}
        setNavegation={setNavegation}
        priceRegister={priceRegister}
      />
      <ModalText
        navegation={navegation}
        setNavegation={setNavegation}
        setItem={setItem}
        openStore={openStore}
      />
      <ModalSuccessRegister
        navegation={navegation}
        setNavegation={setNavegation}
        priceRegister={priceRegister}
      />
    </>
  );
};

export default Login;
