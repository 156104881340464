import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";

import { Plugins, Capacitor } from "@capacitor/core";
import { validarSuscripcion } from "../../ModuloFirebaseCliente/SignUpAndLogin";
import { useHistory } from "react-router-dom";

import { ModalMembershipCont } from "../../styles";
import Button from "../auth/Button";
import { BackButton } from "../modules";

import { ReactComponent as IconBullet } from "../../assets/icons/point.svg";

const ModalMembership = () => {
  const [priceRegister, setPriceRegister] = useState(true);
  const { getItem, openStore, removeItem, clear } = useStorageSQLite();
  const RedirectAPI = process.env.REACT_APP_SERVER_ENDPOINT;
  const { App } = Plugins;
  const precios = {
    mensual: process.env.REACT_APP_PRICE_MENSUAL_ID,
    anual: process.env.REACT_APP_PRICE_ANUAL_ID,
  };
  const history = useHistory();
  useEffect(() => {
    const validarInner = async (uid) => {
      if (uid) {
        const res = await validarSuscripcion(uid);
        if (res === 'success') {
          history.push('/app')
        }
      }
    };
    const validar = async () => {
      var enter = await openStore({});
      if (enter) {
        var user = await getItem("uid");
        validarInner(user);
      }
    };
    validar();
  }, [openStore, getItem, history]);

  const logout = async () => {
    await removeItem("uid");
    await removeItem("token");
    await removeItem("tipoUsuario");
    await removeItem("correo");
    await removeItem("letra");
    await removeItem("img");
    await clear();
    history.push("/");
  }

  return (
    <ModalMembershipCont>
      <div className="modalMembershipCont">
        <div className="modalMembershipCont-header">
        <div className='modalMembershipCont-header-bar'>
        </div>
          <BackButton onClick={() => logout()} />
        </div>
        <div className="modalMembershipCont-body">
          <div className="modalMembershipCont-body-info-target">
            <p>¡7 días de prueba gratis!</p>
          </div>

          <div className='modalMembershipCont-body-desktopDescription'>
            <div className="modalMembershipCont-body-info">
              <div className="modalMembershipCont-body-info-desc">
                <IconBullet />
                <p>
                  El mejor monitoreo de medios con las noticias más relevantes.
            </p>
              </div>
              <div className="modalMembershipCont-body-info-desc">
                <IconBullet />
                <p>Análisis a más de 20,000 notas especialmente para ti.</p>
              </div>
              <div className="modalMembershipCont-body-info-desc">
                <IconBullet />
                <p>Mantente informado durante todo el día. </p>
              </div>
              <div className="modalMembershipCont-body-info-desc">
                <IconBullet />
                <p>Boletines informativos directo en tu celular.</p>
              </div>
            </div>
          </div>

          <div className='modalMembershipCont-body-desktopPrice'>
            <div className="modalMembershipCont-body-info-text">
              <p>
                Para obtener mayor cobertura de notas inscríbete a una versión
                premium por tan solo
              </p>
            </div>
            <div className="modalMembershipCont-body-info-check">
              <span className={priceRegister ? "off" : "on"}>Mensual</span>
              <Switch
                onChange={() => setPriceRegister(!priceRegister)}
                checked={priceRegister}
                offColor="#235789"
                onColor="#235789"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                width={64}
                height={24}
              />
              <span className={priceRegister && "on"}>Anual</span>
            </div>
            <span className="modalMembershipCont-body-info-discont">
              {" "}
              {priceRegister && "AHORRAS 25%"}{" "}
            </span>
            <div className="modalMembershipCont-body-info-price">
              <span className="dollar">$</span>
              <span className="price">
                {priceRegister
                  ? Number("799").toLocaleString("es-MX")
                  : Number("89").toLocaleString("es-MX")}
              </span>
              <span className="decimal">.00</span>
            </div>
          </div>

        </div>

        <div className="modalMembershipCont-footer">
          <div className="modalMembershipCont-footer-button">
            <Button
              text={'Seleccionar'}
              onClick={async () => {
                var ua = navigator.userAgent.toLowerCase();
                let ventana = null;
                if (ua.indexOf("safari") !== -1) {
                  if (!(ua.indexOf("chrome") > -1)) {
                    ventana = window.open("about:blank", "_blank");
                  }
                }
                const cargar = async () => {
                  var precio = null;
                  if (priceRegister) {
                    precio = precios.anual;
                  } else {
                    precio = precios.mensual;
                  }
                  var uid = await getItem("uid");
                  var platform = Capacitor.getPlatform();
                  var url = {
                    url: RedirectAPI + "/pagos/procesar/" + uid + "/" + precio,
                  };
                  if (platform === "web") {
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf("safari") !== -1) {
                      if (ua.indexOf("chrome") > -1) {
                        window.open(
                          url.url,
                          "_blank",
                          "status=yes, toolbar=no, menubar=no, location=no,addressbar=no"
                        );
                      } else {
                        ventana.location = url.url;
                      }
                    }else if(ua.indexOf("firefox") > -1){
                      window.open(
                        url.url,
                        "_blank",
                        "status=yes,popup,toolbar=no, menubar=no, location=no,addressbar=no"
                      );
                    }
                    window.onmessage = function (e) {
                      if (e.data) {
                        if (e.data === "compraOK") {
                          window.location.reload();
                        }
                      }
                    };
                  } else {
                    await App.openUrl(url);
                  }
                };
                cargar();
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </ModalMembershipCont>
  );
};

export default ModalMembership;
