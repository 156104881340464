import React, { useState, useContext } from 'react';
import { IonModal, IonContent } from "@ionic/react";

import { ModalTextCont } from "../../styles";
import contexto from "../../Contexto/contexto";
import { leaveAnimation, enterAnimation } from "../../utils";
import { BackButton } from "../modules";

const ModalText = ({ navegation, setNavegation, openStore, setItem, getItem }) => {
  const { setAppFontSize, appFontSize } = useContext(contexto);
  const [fontSize, setFontSize] = useState(appFontSize);
  const sizes = {
    small: "12px",
    regular: "20px",
    big: "30px",
  };
  const tamanos  ={
    '12px': 'small',
    '20px': 'regular',
    '30px': 'big',
    small: 'small',
    regular: 'regular',
    big: 'big',
  }

  const onSubmit = async () => {
      try {
      const resOpen = await openStore({});
      console.log(resOpen)
      if (resOpen) {
        await setItem("letra", sizes[fontSize]);
        setAppFontSize(sizes[fontSize]);
        setNavegation("");
      }
    } catch (err){
      setNavegation("")
    }}
  return(
    <IonContent>
    <IonModal
      isOpen={navegation === "textSize" && true}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
      cssClass="fullscreen"
    >
      <ModalTextCont fontS={tamanos[fontSize]}>
        <div className="modalTextCont">
          <div className="modalTextCont-header">
            <BackButton
              onClick={onSubmit}
            />
          </div>
          <div className="modalTextCont-body">
            <div className="modalTextCont-body-title">
              <h3>Título de muestra</h3>
            </div>
            <div className="modalTextCont-body-text">
              <p>Texto de muestra</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Donec ornare urna nec augue tristique, vel sollicitudin
                ligula finibus. Donec dignissim metus sem, eget imperdiet
                risus lacinia non. Ut bibendum purus non blandit pretium.
                Cras lobortis eu odio eu posuere. Quisque urna enim,
                imperdiet sit amet felis sed, malesuada egestas turpis.
                Donec tempus accumsan commodo. Aliquam enim quam, lacinia
                nec tristique sed, tincidunt a nibh. Pellentesque ultrices
                fermentum odio, sed suscipit neque sagittis sed. Quisque
                semper porta lorem. Pellentesque a dolor non quam{" "}
              </p>
            </div>
            <div className="modalTextCont-body-textSmall">
              <p>Aumenta o reduce el tamaño de texto en la pantalla</p>
            </div>
          </div>
          <div className="modalTextCont-footer">
            <div className="modalTextCont-footer-buttons">
              <div
                className={`modalTextCont-footer-buttons-button small${
                  tamanos[fontSize] === 'small' ? " on" : ""
                }`}
                onClick={() => setFontSize("small")}
              >
                T
              </div>
              <div
                className={`modalTextCont-footer-buttons-button regular${
                  tamanos[fontSize] === 'regular' ? " on" : ""
                }`}
                onClick={() => setFontSize("regular")}
              >
                T
              </div>
              <div
                className={`modalTextCont-footer-buttons-button big${
                  tamanos[fontSize] === "big" ? " on" : ""
                }`}
                onClick={() => setFontSize("big")}
              >
                T
              </div>
            </div>
          </div>
        </div>
      </ModalTextCont>
    </IonModal>
  </IonContent>
  )
};

export default ModalText;