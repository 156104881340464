import { createAnimation } from "@ionic/react";

export const colors = {
  black: "#000000",
  blueDark: "#235789",
  grey: "#333333",
  greyLight: "#575757",
  mdmInteraction: "#235789",
  red: "#FA4443",
  white: "#FFFFFF",
  yellowLight: "#FFE990"
};

export const fontSize = {
  titleBig: "24px",
  titleRegular: "20px",
  titleSmall: "14px",
  textBig: "16px",
  textRegular: "14px",
  textMedium: "18px",
  textLarge: "20px",
  textSmall: "12px",
  buttonRegular: "14px",
  buttonSmall: "12px",
};

export const redes = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  twitter: "https://twitter.com/",
  linkedin: "https://www.linkedin.com/",
};
export const applinks = {
  ios: "https://apps.apple.com/mx/app/google-chrome/id535886823",
  android: "https://play.google.com/store/apps/details?id=com.android.chrome",
};

export const enterAnimation = (baseEl) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector("ion-backdrop"))
    .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".modal-wrapper"))
    .keyframes([
      { offset: 0, opacity: "0", transform: "translate(100%, 0%)" },
      { offset: 1, opacity: "1", transform: "translate(0%, 0%)" },
    ]);

  return createAnimation()
    .addElement(baseEl)
    .easing("ease-out")
    .duration(200)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const leaveAnimation = (baseEl) => {
  return enterAnimation(baseEl).direction("reverse");
};
