const {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    ApolloLink,
} = require("@apollo/client");
const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`,
});
const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
    },
    query: {
        errorPolicy: "all",
        fetchPolicy: "no-cache"
    },
};

function crearCliente() {
    const authLink = new ApolloLink((operation, forward) => {
        //console.log(window.vars.token);
        operation.setContext({
            headers: {
                authorization: "EDITOR_DEV",
            },
        });
        return forward(operation);
    });
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        fetchOptions: {
            mode: "no-cors",
        },
        defaultOptions: defaultOptions,
    });
    return client;
}

export { crearCliente };