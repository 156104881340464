import React, { useState } from "react";
import { IonModal, IonContent } from "@ionic/react";

import { ModalPassCont } from "../../styles";
import Button from "../auth/Button";
import InputPass from "../auth/InputPass";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";

const ModalPass = ({ navegation, setNavegation }) => {
  const [newPass, setNewPass] = useState(null);
  const [newPassConfirm, setNewPassConfirm] = useState(null);
  return (
    <IonContent>
      <IonModal
        isOpen={navegation === "changePass" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalPassCont>
          <div className="modalPassCont">
            <div className="modalPassCont-header">
              <BackButton onClick={() => setNavegation("pass")} />
            </div>
            <div className="modalPassCont-body">
              <div className="modalPassCont-body-cont">
                <div className="modalPassCont-body-cont-text">
                  <h3>Recuperar contraseña</h3>
                </div>
                <InputPass
                  name="newPass"
                  placeholder="Ingresa aquí tu nueva contraseña"
                  title="Ingresa tu nueva contraseña"
                  setValue={setNewPass}
                  value={newPass}
                />
                <InputPass
                  name="newPassConfirm"
                  placeholder="Ingresa aquí tu nueva contraseña"
                  title="Confirma tu nueva contraseña"
                  setValue={setNewPassConfirm}
                  value={newPassConfirm}
                />
              </div>
              <Button
                onClick={() => setNavegation("successPass")}
                disabled={false}
              />
            </div>
          </div>
        </ModalPassCont>
      </IonModal>
    </IonContent>
  );
};

export default ModalPass;
