import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { agendaDiaDummy } from "../../utils/infoDummy";
import { colors } from "../../utils";
import ArticuloPie from "../auth/ArticuloPie";
import { date } from "../../utils/DiaDeHoy";
import SlideTest from "../auth/SlideTest";
import { obtenerPie } from "../../consumo_apollo_client/clientePortadas";
import { News, Hr } from "../modules";
import { obtenerBullets } from "../../consumo_apollo_client/clienteBullets";
const AgendaDiaCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 190px - env(safe-area-inset-top));
  background: ${colors.white};
  overflow: auto;
  .agenCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height:${props => props.heightComponent + 164}px;
    position:relative;
    overflow:hidden;
    hr{
      width:60%;
      max-width:420px ;
      margin: 0 auto;
      border:1px solid #E5E5E5;
      background-color:#E5E5E5;
    }
    &-subText {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 10px 5%;
      width: 100%;
      min-height:50px;
      max-width: 640px;
      margin: 12px auto;
      .cont{  
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        p {
          text-align: left;
          width: 100%;
          max-width: 640px;
        }
        .date{
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #757575;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          text-align: left;
          width:120px;
        }
      }
      .news{
        display: -webkit-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display:flex;
      }
    }
  }
  @media (min-width: 769px){
    .agenCont{
      &-subText{
        padding: 10px 0%;
        .news{
          justify-content:flex-start;
        }
      }
    }
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
`;

const AgendaDia = ({ nombre, refresh, setRefresh, section }) => {

  const [bullets, setBullets] = useState([]);

  useEffect(() => {
    if (refresh === true) {
      obtenerPie({ tipo: nombre })
        .then((res) => {
          setData(res);
          setRefresh(false)
        })
        .catch(() => {
          setData(null);
        });
      obtenerBullets()
        .then((res) => {
          if (res) {
            if (refresh) {
              setBullets(res);
            }
          } else {
            if (refresh) {
              setBullets(null);
            }
          }
        })
        .catch((e) => {
          if (refresh) {
            setBullets(null);
          }
        });
    }

    var mounted = true;
    obtenerPie({ tipo: nombre })
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        setData(null);
      });
    obtenerBullets()
      .then((res) => {
        if (res) {
          if (mounted) {
            setBullets(res);
          }
        } else {
          if (mounted) {
            setBullets(null);
          }
        }
      })
      .catch((e) => {
        if (mounted) {
          setBullets(null);
        }
      });
    return function cleanup() {
      mounted = false;
    };// eslint-disable-next-line
  }, [nombre, refresh]);

  const [data, setData] = useState(null);
  if (data == null)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <AgendaDiaCont heightComponent={(data ? data.length : 5) * 120}>
      <div className="agenCont">
        {/* <Target text="AGENDA DEL DÍA" /> */}
        <div className="agenCont-subText">
          <div className="cont">
            <p className="date"> {date()} </p>
            <p style={{ fontSize: '12px' }}> {agendaDiaDummy.subText} </p>
          </div>
          <div className="news">
            {bullets.length > 0 && nombre === "Agenda" && (
              <News title="Mañanera" color="#9A0330" data={bullets} />
            )}
            {bullets.length > 0 && nombre === "Agenda" && (
              <News title="Covid-19" color="#006261" data={bullets} />
            )}
          </div>
        </div>
        <Hr/>
        {data.map((data, i) => (
          <ArticuloPie
            title={data.articulo.titulo}
            porcentaje={data.porcentaje}
            articulo={data.articulo}
            colorBar={data.articulo.color}
            section={section}
            key={i}
          />
        ))}
      </div>
    </AgendaDiaCont>
  );
};

export default AgendaDia;
