import React from 'react';
import { IonModal, IonContent } from "@ionic/react";
import moment from "moment";

import { ModalSuccessCont } from "../../styles";
import Button from "../auth/Button";
import { BackButton } from "../modules";
import fechaDia from "../../utils/fechaDia";
import { leaveAnimation, enterAnimation } from "../../utils";

import { ReactComponent as IconSuccess } from "../../assets/icons/successIcon.svg";

const ModalSuccessRegister = ({ navegation, setNavegation, priceRegister }) => {
  const fecha = new Date();
  return(
    <IonContent>
      <IonModal
        isOpen={navegation === "success" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalSuccessCont>
          <div className="modalSuccessCont">
            <div className="modalSuccessCont-header">
              <BackButton onClick={() => setNavegation("register-payment")} />
            </div>
            <div className="modalSuccessCont-body">
              <div className="modalSuccessCont-body-cont">
                <div className="modalSuccessCont-body-cont-icon">
                  <IconSuccess />
                </div>
                <div className="modalSuccessCont-body-cont-title">
                  <p>Tu pago se realizó exitosamente</p>
                </div>
                <div className="modalSuccessCont-body-cont-text">
                  <p>
                    Tu periodo de 30 días gratis ha comenzado, tu pago de $
                    {priceRegister ? "2,999" : "499"}.00 se iniciará a partir
                    del {fechaDia(fecha)} y hasta el{" "}
                    {fechaDia(moment().add(1, "M"))}
                  </p>
                </div>
              </div>
              <div className="modalSuccessCont-body-button">
                <Button
                  onClick={() => {
                    setNavegation("textSize");
                  }}
                  disabled={false}
                  text="ACEPTAR"
                />
              </div>
            </div>
          </div>
        </ModalSuccessCont>
      </IonModal>
    </IonContent>
  )
};

export default ModalSuccessRegister;