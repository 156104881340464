import React, { useEffect, useState } from "react";
import Index from "./pages/Index";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import LoginUser from "./pages/LoginUser";
import Register from "./pages/Register";
import Membership from "./pages/Membership";
import Terminos from './pages/Terminos'
import RememberPass from './pages/RememberPass'
import Text from './pages/Text'
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
// import Test from "./pages/Test";
import { validarSuscripcion } from "./ModuloFirebaseCliente/SignUpAndLogin";
import { Plugins } from "@capacitor/core";
import { ProveedorContexto } from "./Contexto/contexto";
import { setupConfig } from "@ionic/react";

import PaymentFailure from './pages/PaymentFailure'
import NewPaymentMethod from "./pages/NewPaymentMethod"
import SuccessfulPayment from "./pages/SuccessfulPayment";

import { Route, BrowserRouter } from "react-router-dom";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import fire from "firebase/app";
import "@firebase/firestore";

const db = fire.firestore()

const { SplashScreen } = Plugins;
setupConfig({
  mode: "md",
});

const Router = () => {
  const [appFontSize, setAppFontSize] = useState("");

  const { openStore, getItem } = useStorageSQLite();
  const value = { appFontSize, setAppFontSize };

  const sizes = {
    small: "12px",
    regular: "20px",
    big: "30px",
  };

  useEffect(() => {
    const validar = async () => {
      const enter = await openStore({});
      if (enter) {
        const user = await getItem("uid");
        const res = await validarSuscripcion(user);
        if (res !== 'failed') {
          // const letra = await getItem("letra");
          // setAppFontSize(!letra ? "26px" : letra);
          SplashScreen.hide();
        }
        if (user !== null) {
          const textSize = await db.collection('usuario').doc(user).get()
          if (textSize.data().fontSize !== undefined) value.setAppFontSize(sizes[textSize.data().fontSize])
          else value.setAppFontSize(sizes['regular']) // WTF is this shit
        }
      }
    };
    validar();
  }, [openStore, getItem, sizes, value]);

  return (
    <BrowserRouter>
      <IonApp>
        <ProveedorContexto value={value}>
          <IonReactRouter>
            <Route exact path="/" component={Login} />
            <Route exact path="/app" component={Index} />
            <Route exact path="/login" component={LoginUser} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/membership" component={Membership} />
            <Route exact path="/terminos" component={Terminos} />
            <Route exact path="/remember-pass" component={RememberPass} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/text" component={Text} />

            {/* FLUJO RE-PAGO */}
            <Route exact path='/payment-failure' component={PaymentFailure} />
            <Route exact path='/new-payment-method' component={NewPaymentMethod} />
            <Route exact path='/successful-payment' component={SuccessfulPayment} />

          </IonReactRouter>
        </ProveedorContexto>
      </IonApp>
    </BrowserRouter>

  );
};

export default Router;
