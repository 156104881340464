import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
const encabezadoDiaDeHoy = (token, uid) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: gql `
          query buscarEncabezados($Token: String, $uid: String) {
            buscarEncabezados(Token: $Token, uid: $uid ) {
              nombre
              link
              titular
              imagen
              _id
              indice
            }
          }
        `,
                variables: token,
                uid
            })
            .then((result) => {
                resolve(result.data.buscarEncabezados);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export { encabezadoDiaDeHoy };