import React from 'react';
//import RegisterHome from '../components/register/Register'
import RegisterAlt from '../components/register/RegisterAlt'

const Register = () => {
  return(
    <>
      <RegisterAlt/>
    </>
  )
};

export default Register;