import React from "react";
import styled from "styled-components";
import { animateScroll } from "react-scroll";
import { ReactComponent as IconEconomia } from "../assets/icons/iconEconomia.svg";
import { ReactComponent as IconPolitica } from "../assets/icons/iconPolitica.svg";
import { ReactComponent as IconAgenda } from "../assets/icons/iconAgenda.svg";
import { ReactComponent as IconSociedad } from "../assets/icons/iconSociedad.svg";
import { ReactComponent as IconInternacional } from "../assets/icons/iconInternacional.svg";

const MenuFooterCont = styled.div`
  position: fixed;
  bottom: 0;
  height: 80px;
  width: 100%;
  background: #fff;
  z-index: 100;
  padding: 5px 5% 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  background: #F9F9F9;
  box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.08);
  .button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    border-top: 4px solid transparent;
    min-width: 15%;
    padding: 0 5px;
    cursor: pointer;
    svg {
      margin-bottom: 3px;
    }
    p {
      font-size: 9px;
      color: #757575;
    }
  }
  .on {
    background: #235789;
    border-radius:5px;
    svg {
      path{
        fill: #ffffff;
      }
    }
    p {
      color: #ffffff;
    }
  }
  @media (min-width:768px){
    padding: 0px 25%;
  }
  @media (min-width:1025px){
    padding: 60px 0px 50vh;
    top:0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width:144px;
    height:100%;
    .button{
      border-radius:0;
      padding:5px 5px;
    }
  }
`;
const MenuFooter = ({ stateFooter, setStateFooter }) => {
  return (
    <MenuFooterCont>
      <div
        className={`button${stateFooter === "economia" ? " on" : ""}`}
        onClick={() => {
          setStateFooter("economia");
          animateScroll.scrollTo(0, { duration: 400 });
        }}
      >
        <IconEconomia />
        <p>Economía</p>
      </div>
      <div
        className={`button${stateFooter === "politica" ? " on" : ""}`}
        onClick={() => {
          setStateFooter("politica");
          animateScroll.scrollTo(0, { duration: 400 });
        }}
      >
        <IconPolitica />
        <p>Política</p>
      </div>
      <div
        className={`button${stateFooter === "agenda" ? " on" : ""}`}
        onClick={() => {
          setStateFooter("agenda");
          animateScroll.scrollTo(0, { duration: 400 });
        }}
      >
        <IconAgenda />
        <p>Agenda</p>
      </div>
      <div
        className={`button${stateFooter === "sociedad" ? " on" : ""}`}
        onClick={() => {
          setStateFooter("sociedad");
          animateScroll.scrollTo(0, { duration: 400 });
        }}
      >
        <IconSociedad />
        <p>Sociedad</p>
      </div>
      <div
        className={`button${stateFooter === "internacional" ? " on" : ""}`}
        onClick={() => {
          setStateFooter("internacional");
          animateScroll.scrollTo(0, { duration: 400 });
        }}
      >
        <IconInternacional />
        <p>Internacional</p>
      </div>
    </MenuFooterCont>
  );
};

export default MenuFooter;
