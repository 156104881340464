import React from "react";
import styled from "styled-components";
import { colors } from "../../utils"

const InputCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 327px;
  height: 94px;
  .inputCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    &-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 30px;
      p {
        color: ${colors.greyLight};
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        margin-bottom: 7px;
      }
    }
    &-input {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border: 1px solid ${(props) => (props.error ? "#DB2C2B" : "#abb3bb")};
      border-radius: 6px;
      height: 46px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      input {
        border: none;
        width: 100%;
        height: 100%;
        font-family: Open Sans;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        font-size: 15px;
        height: 20px;
        margin: 0 11px;
        &:focus {
          outline: none;
        }
      }
    }
    &-error {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 18px;
      p {
        width: 100%;
        color: #db2c2b;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .inputCont {
      width: 420px;
    }
  }

  @media (min-width: 1023px) {
    .inputCont {
      width: 370px;
    }
  }
`;
const Input = ({
  name,
  type = "text",
  register,
  className = "inputForm",
  title,
  placeholder,
  setValue,
  value,
  error,
  textError,
  refT = true,
}) => {
  return (
    <InputCont className={className} error={error}>
      <div className="inputCont">
        <div className="inputCont-title">
          <p> {title} </p>
        </div>
        <div className={`inputCont-input${error ? " error" : ""}`}>
          <input
            name={name}
            type={type}
            ref={register}
            placeholder={placeholder}
            onChange={(e) => (refT ? setValue(e.target.value) : null)}
            value={refT ? value : null}
          />
        </div>
        <div className="inputCont-error">
          <p> {error && textError} </p>
        </div>
      </div>
    </InputCont>
  );
};

export default Input;
