import React from "react";
import styled from "styled-components";

import { ReactComponent as ArrowThumbL } from "../../assets/icons/arrowThumbLeft.svg";

const ArrowThumbLeftCont = styled.div`
  svg {
    width: 6px;
    height: 10px;
    -webkit-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
    opacity: ${(props) => (props.state ? 0 : 1)};
    position: absolute;
    left: 6PX;
    top: 18px;
    z-index: 50;
  }
`;
const ArrowThumbLeft = ({ state }) => {
  return (
    <ArrowThumbLeftCont state={state}>
      <ArrowThumbL />
    </ArrowThumbLeftCont>
  );
};

export default ArrowThumbLeft;
