const meses = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre',
}
const mesesUnCaps = {
  0: 'enero',
  1: 'febrero',
  2: 'marzo',
  3: 'abril',
  4: 'mayo',
  5: 'junio',
  6: 'julio',
  7: 'agosto',
  8: 'septiembre',
  9: 'octubre',
  10: 'noviembre',
  11: 'diciembre',
}
const dias = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
]

const DiaDeHoy = () => {
  var fecha = new Date()
  var dia = fecha.getDay()
  var diames = fecha.getUTCDate()
  var mes = fecha.getMonth()
  var años = fecha.getFullYear()
  return dias[dia] + ' ' + diames + ' de ' + meses[mes] + ' de ' + años
}

export const date = () => {
  var fecha = new Date()
  var diames = fecha.getUTCDate()
  var mes = fecha.getMonth()
  var años = fecha.getFullYear()
  return diames + ' de ' + mesesUnCaps[mes] + ' de ' + años
}

export default DiaDeHoy