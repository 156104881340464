import React, { useEffect, useState } from "react";
import styled from "styled-components";

import IndicadorEconomico from "../auth/IndicadorEconomico";
import IndicadorEconomicoMetales from '../auth/IndicadorEconomicoMetales'
import { Hr } from "../modules";
import { obtenerIndicadores } from "../../consumo_apollo_client/clienteindicadores";
import SlideTest from "../auth/SlideTest";

import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { useHistory } from 'react-router-dom';

const IndicadoresEconomicosCont = styled.div`
  height: calc(100vh - 190px - env(safe-area-inset-top));
  overflow: auto;
  .space {
    height: 30px;
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
`;


const IndicadoresEconomicos = ({ id, nombre, refresh, setRefresh }) => {

  const history = useHistory()

  const [indicadores, setIndicadores] = useState([]);
  const [indicadoresMetales, setIndicadoresMetales] = useState([])
  const indicadoresArr = []
  const indicadoresMetArr = []

  const { openStore, getItem, removeItem, clear } = useStorageSQLite();

  const logout = async () => {
    await removeItem("uid");
    await removeItem("token");
    await removeItem("tipoUsuario");
    await removeItem("correo");
    await removeItem("letra");
    await removeItem("img");
    await clear();
    history.push("/");
  }

  useEffect(() => {
    const getIndicadores = async () => {
      await openStore({});
      const token = await getItem("sessionToken");
      const uid = await getItem("uid");

      if (refresh === true) {
        obtenerIndicadores({ tipo: nombre })
          .then((res) => {
            setIndicadores(res);
            setRefresh(false)
          })
          .catch((e) => {
            setIndicadores([]);
          })
      }
      obtenerIndicadores({ tipo: nombre, Token: token, uid: uid })
        .then((res) => {
          console.log('esta es la funcion')
          if(res){
            res.forEach(ind => {
              if (ind.nombre === "ALUMINIO" || ind.nombre === "COBRE") {
                indicadoresMetArr.push(ind)
              } else {
                indicadoresArr.push(ind)
              }
            })
            setIndicadores(indicadoresArr.sort((a, b) => a.indice - b.indice))
            setIndicadoresMetales(indicadoresMetArr.sort((a, b) => a.indice - b.indice))
          }else{
            logout()
          }
        })
        .catch((e) => {
          setIndicadores([])
          setIndicadoresMetales([])
        });
    }

    getIndicadores();

    // eslint-disable-next-line
  }, [nombre, refresh]);

  if (indicadores.length === 0)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <IndicadoresEconomicosCont>
      {indicadores?.map((indi, i) => (
        <IndicadorEconomico
          tipo={indi.nombre}
          aumento={indi.isgain}
          price={Number(indi.value).toLocaleString("es-MX", {
            minimumFractionDigits: 2,
          })}
          link={indi.link}
          fuente={indi.fuente}
          key={i}
        />
      ))}
      <div style={{ marginTop: '30px' }}>
        <Hr style={{marginBottom: '30px'}}/>
        {indicadoresMetales?.map((indi, i) => (
          <IndicadorEconomicoMetales
            key={i}
            tipo={indi.nombre}
            aumento={indi.aumento}
            price={indi.value} />
        ))}
      </div>
    </IndicadoresEconomicosCont>
  );
};

export default IndicadoresEconomicos;