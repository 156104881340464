import React from "react";
import { NavbarCont } from "../styles";
import { ReactComponent as Profile } from "../assets/icons/profile.svg";

import { Link } from "react-router-dom";
import { BackButton } from "./modules";

const Navbar = ({ title, width }) => {
  const titulos = {
    agenda: "AGENDA",
    internacional: "INTERNACIONAL",
    politica: "POLÍTICA Y LEGISLATIVO",
    economia: "ECONOMÍA Y NEGOCIOS",
    sociedad: "SOCIEDAD Y SEGURIDAD",
    profile: "PERFIL",
  };
  return (
    <NavbarCont wd={width}>
      <div className="nav">
        <Link to="/app">
          {!width &&  <BackButton />}
        </Link>
        <div className="titulo">
          <p> {titulos[title]} </p>
        </div>
        <Link to="/profile">
          <Profile className="profile" />
        </Link>
      </div>
    </NavbarCont>
  );
};
export default Navbar;
