import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ArticuloOpinion from "../auth/ArticuloOpinion";
import SlideTest from "../auth/SlideTest";
import { opionesDiaDeHoy } from "../../consumo_apollo_client/clienteOpiniones";

const OpinionCont = styled.div`
  height: calc(100vh - 190px - env(safe-area-inset-top));
  overflow: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .opinionsCont{
    min-height: ${props => props.minHeightOpinion + 22}px;
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
`;
const Opinion = ({ nombre, refresh, setRefresh, section }) => {
  const [opiniones, setOpiniones] = useState(null);
  useEffect(() => {

    if (refresh === true) {
      opionesDiaDeHoy({ tipo: nombre })
        .then((res) => {
          if (res) {
            if (refresh) {
              setOpiniones(res);
              setRefresh(false)
            }
          } else {
            if (refresh) {
              setOpiniones(null);
            }
          }
        })
        .catch((e) => {
          if (refresh) {
            setOpiniones(null);
          }
        });
    }

    var mounted = true;
    opionesDiaDeHoy({ tipo: nombre })
      .then((res) => {
        if (res) {
          if (mounted) {
            setOpiniones(res);
          }
        } else {
          if (mounted) {
            setOpiniones(null);
          }
        }
      })
      .catch((e) => {
        if (mounted) {
          setOpiniones(null);
        }
      });
    return function cleanup() {
      mounted = false;
    };// eslint-disable-next-line
  }, [nombre, refresh]);

  if (opiniones === null)
    return <SlideTest text={"No hay información para mostrar"} />;
  if (opiniones.length === 0)
    return <SlideTest text={"No hay información para mostrar"} />;

  return (
    <OpinionCont minHeightOpinion={(opiniones ? opiniones.length : 5) * 70}>
      <div className='opinionsCont'>
        {opiniones.map((opinion, i) => (
          <ArticuloOpinion 
            title={opinion.titulo}
            by={opinion.autor}
            text={opinion.cuerpo}
            key={i}
            section={section}
          />
        ))}
      </div>
    </OpinionCont>
  );
};

export default Opinion;
