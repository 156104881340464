import React from 'react';
import { IonModal, IonContent } from "@ionic/react";

import { ModalTerminosCont } from "../../styles";
import { BackButton } from "../modules";
import { leaveAnimation, enterAnimation } from "../../utils";

const ModalTerminos = ({ navegation, setNavegation }) => {
  return(
    <IonContent>
      <IonModal
        isOpen={navegation === "terminos" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="fullscreen"
      >
        <ModalTerminosCont>
          <div className="modalTerminosCont">
            <div className="modalTerminosCont-header">
              <BackButton onClick={() => setNavegation("register-data")} />
            </div>
            <div className="modalTerminosCont-body">
              <div className="modalTerminosCont-body-title">
                <h3>Términos y Condiciones</h3>
              </div>
              <div className="modalTerminosCont-body-text">
                <p>
                  Este contrato describe los términos y condiciones generales
                  (los "Términos y Condiciones") aplicables al uso de los
                  contenidos, productos y servicios ofrecidos a través del
                  sitio www.elmediodemedios.com (el "Sitio Web"), del cual es
                  titular SR & Friends S.A. de C.V. ("SR & Friends"), una
                  sociedad mercantil constituida de conformidad con las leyes
                  de los Estados Unidos Mexicanos (“México”), la cual tiene su
                  domicilio establecido en Calle Cadetes del 47 número 6, Piso
                  1, departamento 2, colonia San Miguel Chapultepec II
                  Sección, Alcaldía Miguel Hidalgo, código postal 11850,
                  Ciudad de México, México.
                </p>
                <p>
                  Cualquier persona que desee acceder o hacer uso del Sitio
                  Web o los servicios que en él se ofrecen (el “Usuario”),
                  podrá hacerlo sujetándose a los presentes Términos y
                  Condiciones. En todo caso, cualquier persona que no acepte
                  los presentes Términos y Condiciones, deberá abstenerse de
                  utilizar el Sitio Web y/o adquirir los productos y servicios
                  que en su caso sean ofrecidos.
                </p>
                <p>I. OBJETO.</p>
                <p>
                  El objeto de los presentes Términos y Condiciones es regular
                  el acceso y la utilización del Sitio Web, entendiendo por
                  éste, cualquier tipo de contenido, producto o servicio que
                  se encuentre a disposición del público en general dentro del
                  dominio: www.elmediodemedios.com.
                </p>
                <p>
                  El Titular se reserva la facultad de modificar en cualquier
                  momento y sin previo aviso, los presente Términos y
                  Condiciones, la presentación, los contenidos, la
                  funcionalidad, los productos, los servicios, y la
                  configuración que pudiera estar contenida en el Sitio Web;
                  en este sentido, el Usuario reconoce y acepta que SR &
                  Friends S.A. de C.V. en cualquier momento podrá interrumpir,
                  desactivar o cancelar cualquiera de los elementos que
                  conforman el Sitio Web o el acceso a los mismos.
                </p>
              </div>
            </div>
          </div>
        </ModalTerminosCont>
      </IonModal>
    </IonContent>
  )
};

export default ModalTerminos;