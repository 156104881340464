import React, { useState } from 'react'
import styled from 'styled-components'
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BackButton } from '../components/modules'
import MY_SERVICE from '../services/stripeServices'
import { getStripeId } from '../ModuloFirebaseCliente/SignUpAndLogin'
import { useStorageSQLite } from 'react-data-storage-sqlite-hook/dist'

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import stripeLogo from "../assets/icons/poweredByStripe.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const override = css`
  display: block;
  margin: 2;
  border-color: white;
`

const NewPaymentMethodContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 45px 5% 75px 5%;
  .back-button {
    align-self: flex-start;
  }
  .infoSection{
    display: flex;
    flex-direction: column;
    h1 {
      font-family: Open Sans;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 10%;
      margin-top: 15%;
    }
    p {
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.95px;
      margin-bottom: 10%;
      padding: 20px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 90%;
    label {
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 5px;
      text-align: left;
    }
    input {
      height: 46px;
      border-radius: 2px;
      border: 0.5px solid #868585;
      outline: none;
      text-align: left;
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 22px;
    }
    .card {
      height: 46px;
      width: 100%;
      border-radius: 2px;
      border: 0.5px solid #868585;
      padding: 15px 15px;
      margin-bottom: 22px;
    }
    .inLine{ 
      display: flex;
      justify-content: space-between;
      div {
        width: 100%;
        text-align: left;
      }
      .cvc {
        margin-right: 5%;
      }
    }
  }
  .errorMessage{
    color: red;
    font-size: 10px;
    font-weight: 600;
  }
  .loader {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .stripeLogo {
    position: absolute;
    right: 0%;
    width: 125px;
    top: 40px;
  }
`

const ButtonCont = styled.button`
  margin-top: 5%;
  height: 57px;
  width: 100%;
  border: none;
  background: #235789;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
  .buttonText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  &:disabled{
    background: grey;
    cursor: not-allowed;
  }
`

const CardForm = ({ history }) => {

  const [cardName, setCardName] = useState('')
  const [errorMessage] = useState('Metodo de pago rechazado, por favor intenta de nuevo')

  const [showError, setShowError] = useState(false)
  const [cardNumberStatus, setCardNumberStatus] = useState(false)
  const [cvcStatus, setCvcStatus] = useState(false)
  const [periodStatus, setPeriodStatus] = useState(false)
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const { openStore, getItem } = useStorageSQLite()

  const handleSubmit = async () => {
    setLoading(true)
    const store = await openStore({})
    if (store) {
      const uid = await getItem('uid')
      const stripeCustomer = await getStripeId(uid)
      const cardNumber = elements.getElement(CardNumberElement)
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          name: cardName
        },
        card: cardNumber
      })
      if (error) {
        setShowError(true)
        setLoading(false)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      } else {
        try {
          const attachInfo = { customerID: stripeCustomer, paymethMethodID: paymentMethod.id }
          await MY_SERVICE.attachPaymethMethod(attachInfo)
          await MY_SERVICE.setDefaultPayment(stripeCustomer, paymentMethod.id)
          history.push('/payment-failure', 'modify')
          setLoading(false)
        } catch ({ message }) {
          setShowError(true)
          setLoading(false)
          setTimeout(() => {
            setShowError(false)
          }, 3000)
        }
      }
    }
  }

  const formReady = () => {
    if (loading) return true
    if (cardNumberStatus && cvcStatus && periodStatus && cardName.length > 3) return false
    else return true
  }

  return (
    <NewPaymentMethodContainer>

      <div className='back-button'>
        <BackButton onClick={() => history.push('/payment-failure')} />
        <img
          src={stripeLogo}
          alt='StripeLogo'
          className='stripeLogo'
        />
      </div>

      <div className='infoSection'>
        <h1>Nuevo método de pago</h1>
        <p>Por favor ingresa los datos de tu nuevo método de pago</p>
      </div>

      <div className='content'>

        <label>Nombre del titular</label>
        <input
          onChange={e => setCardName(e.target.value)}
          placeholder='Ingresa el nombre del titular'
        />

        <label>Número de tarjeta</label>
        <CardNumberElement className='card' onChange={e => setCardNumberStatus(e.complete)} />

        <div className='inLine'>
          <div className='cvc'>
            <label>CVC</label>
            <CardCvcElement className='card' onChange={e => setCvcStatus(e.complete)} />
          </div>
          <div>
            <label>Vigencia</label>
            <CardExpiryElement className='card' onChange={e => setPeriodStatus(e.complete)} />
          </div>
        </div>

        {showError &&
          <p className='errorMessage'>{errorMessage}</p>
        }
      </div>

      <ButtonCont
        disabled={formReady()}
        onClick={() => handleSubmit()}
      >
        {loading ?
          <div className='loader'>
            <BeatLoader color={"#ffffff"} loading={true} css={override} size={15} margin={2} />
          </div>
          :
          <p className="buttonText">
            Continuar
          </p>
        }
      </ButtonCont>
    </NewPaymentMethodContainer>
  )
}

const NewPaymentMethod = (props) => (
  <Elements stripe={stripePromise}>
    <CardForm {...props} />
  </Elements>
)

export default NewPaymentMethod