import React from 'react'
import styled from 'styled-components'

import successSVG from "../assets/icons/success.svg";

const SuccessfulPaymentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
`

const MessageContainer = styled.div`
    width: 90%;
    margin-bottom: 60%;
    margin-top: 30%;
    img {
      margin-bottom: 30px;
    }
    p {
    margin-bottom: 15px;
    &.tittle{
      font-weight: 700;
      font-size: 14px;
    }
    &.message {
      font-weight: 400;
      font-size: 14px;
    }
  }
`

const ButtonContainer = styled.button`
  width: 90%;
  height: 57px;
  border: none;
  background: #235789;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
  .buttonText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
`

const SuccessfulPayment = ({ history }) => {

  return (
    <SuccessfulPaymentContainer>
      <MessageContainer>
        <img
          src={successSVG}
          alt='successSVG'
        />
        <p className='tittle'><strong>Tu pago se realizó exitosamente</strong></p>
        <p className='message'>Ahora puedes seguir disfrutando de las notas más relevantes del día</p>
      </MessageContainer>

      <ButtonContainer onClick={() => history.push('/app')}>
        <p className="buttonText">ACEPTAR</p>
      </ButtonContainer>
    </SuccessfulPaymentContainer>
  )
}

export default SuccessfulPayment