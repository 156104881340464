import React from "react";
import styled from "styled-components";
import chroma from "chroma-js";

const ButtonCont = styled.button`
  ${'' /* align-self: center; */}
  background: ${(props) => props.bg};
  border-radius: 50px;
  border: none;
  height: 46px;
  width: 193px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
  }
  .buttonText {
    color: ${(props) => props.colorB};
    font-size: 18px;
    font-weight: 700;
  }
`;
const Button = ({
  text = "Continuar",
  onClick,
  bg = "#235789",
  disabled,
  className = "buttonNext",
}) => {
  var color = chroma.contrast(bg, "white") > 2 ? "white" : "black";
  return (
    <ButtonCont
      onClick={onClick}
      bg={bg}
      disabled={disabled}
      colorB={color}
      className={className}
    >
      <p className="buttonText">{text}</p>
    </ButtonCont>
  );
};

export default Button;
