import React from 'react';
//import TerminosHome from '../components/terminos/Terminos'
import TerminosAlt from '../components/terminos/TerminosAlt'

const Terminos = () => {
  return(
    <TerminosAlt />
  )
};

export default Terminos;