const meses = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};
const dias = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const DiaDeHoy = () => {
  var fecha = new Date();
  var dia = fecha.getDay();
  var diames = fecha.getUTCDate();
  var mes = fecha.getMonth();
  var años = fecha.getFullYear();
  return dias[dia] + " " + diames + "/" + meses[mes] + "/" + años;
};

export default DiaDeHoy;
