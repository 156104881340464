import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowThumbR } from '../../assets/icons/arrowThumbRight.svg';

const ArrowThumbRightCont = styled.div`
  svg{
    width: 6px;
    height: 10px;
    -webkit-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
    opacity: ${(props) => (props.state ? 0 : 1)};
    position: absolute;
    right: 6PX;
    top: 18px;
    z-index: 50;
  }
`;
const ArrowThumbRight = ({state}) => {
  return(
    <ArrowThumbRightCont state={state}>
      <ArrowThumbR />
    </ArrowThumbRightCont>
  )
};

export default ArrowThumbRight;