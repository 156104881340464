import React, { useState, useContext } from "react";
import styled from "styled-components";
import { IonModal } from "@ionic/react";
import draftToHtml from "draftjs-to-html";

import { colors, fontSize, leaveAnimation, enterAnimation } from "../../utils";
import { ReactComponent as NextButton } from "../../assets/icons/nextButton.svg";
import { BackButton, ShareButton, Hr } from "../modules";
import contexto from "../../Contexto/contexto";

const ArticuloOpinionCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: ${colors.white};
  width: 100%;
  padding: 0px 5%;
  hr{
    width: 60%;
    max-width: 420px;
    margin: 0 auto;
    border: 1px solid #E5E5E5;
    background-color: #E5E5E5;
  }
  .articuloOpinionCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 90px;
    width: 100%;
    max-width: 640px;
    margin: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    &-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      display: -webkit-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 100%;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      h3 {
        font-size: ${fontSize.titleSmall};
        text-align: left;
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        width: 90%;
      }
    }
    &-text {
      padding-bottom: 5px;
      p {
        font-size: ${fontSize.textSmall}!important;
        text-align: justify;
        color: #323232;
      }
    }
    &-arrow {
      position: absolute;
      top: 50%;
      height: 20px;
      right: 0%;
      color: ${colors.blueDark};
      svg {
        height: 20px;
        -webkit-transform: translate(0px, -50%);
        -ms-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
      }
    }
  }
`;
const ModalCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .modalCont {
    display: -webkit-box;
    display: -ms-flexbox;
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 60px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 5%;
      border-radius: 0 0 12px 12px;
      -webkit-box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
      box-shadow: 2px 2px 8px rgb(0 0 0 / 8%);
    }
    &-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 80px - env(safe-area-inset-top));
      width: 100%;
      max-width: 680px;
      margin: auto;
      overflow: auto;
      &-share {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        justify-content: flex-end;
        align-items: center;
        padding: 0 5%;
        height: 70px;
      }
      &-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 15px 5%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .date {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          .iconDate {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: 10px;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          .spanDate {
            font-family: Crimson Pro;
            font-size: ${fontSize.textRegular};
            line-height: 16px;
          }
        }
        .by {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          p {
            font-family: Crimson Pro;
            font-style: italic;
            font-weight: 600;
          }
        }
      }
      &-title {
        width: 100%;
        h3 {
          text-align: left;
          font-size: ${fontSize.titleBig};
          font-family: DM Serif Display;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 115.87%;
          padding: 0 5%;
          margin: 10px 0;
        }
      }
      &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0 5%;
        margin: 20px 0;
        * {
          margin-bottom: 10px;
          text-align: justify;
          font-size: ${(props) =>
            props.appFontSize ? props.appFontSize : "10px"} !important;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modalCont {
      &-header {
        padding: 0 10%;
      }
    }
  }
`;

const ArticuloOpinion = ({ title, by, date, text , section}) => {
  const [showModal, setShowModal] = useState(false);
  const { appFontSize } = useContext(contexto);
  const titulos = {
    agenda: "AGENDA",
    internacional: "INTERNACIONAL",
    politica: "POLÍTICA Y LEGISLATIVO",
    economia: "ECONOMÍA Y NEGOCIOS",
    sociedad: "SOCIEDAD Y SEGURIDAD",
    profile: "CONFIGURACIÓN",
  };
  return (
    <>
      <ArticuloOpinionCont>
        <div className="articuloOpinionCont" onClick={() => setShowModal(true)}>
          <div className="articuloOpinionCont-title">
            <h3> {title} </h3>
          </div>
          <div className="articuloOpinionCont-text">
            <p>Por: {by} </p>
          </div>
          <div className="articuloOpinionCont-arrow">
            <NextButton />
          </div>
        </div>
        <Hr/>
      </ArticuloOpinionCont>
        <IonModal
          isOpen={showModal}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="fullscreen"
        >
          <ModalCont appFontSize={appFontSize}>
            <div className="modalCont">
              <div className="modalCont-header">
                <BackButton onClick={() => setShowModal(false)} />
                <div className="title">
                  <h3> {titulos[section]} </h3>
                </div>
                <div className=""></div>
              </div>
              <div className="modalCont-body">
                <div className="modalCont-body-share">
                  <ShareButton />
                </div>
                <div className="modalCont-body-title">
                  <h3> {title} </h3>
                </div>
                <div
                  className="modalCont-body-text"
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(JSON.parse(text)),
                  }}
                ></div>
              </div>
            </div>
          </ModalCont>
        </IonModal>
    </>
  );
};

export default ArticuloOpinion;
