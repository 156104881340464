import React, { useState, useContext } from "react";
import styled from "styled-components";

import { colors } from "../../utils";
import ModalArticulo from "./ModalArticulo";
import contexto from "../../Contexto/contexto";
import { Hr } from '../modules'

const ArticuloPieCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: ${colors.white};
  height: 120px;
  max-width: 800px;
  width:100%;
  margin: 0 auto;
  padding: 0 5%;
  .articuloPieCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    align-items:center;
    justify-content:space-between;
    .porcentaje{
      width:calc(100% - 105px);
      height: 80%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content:space-between;
      .title{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        align-items:flex-start;
        h3{
          font-family: DM Serif Display;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 22px;
          text-align:left;
        }
      }
      &-box{
        width:100%;
        background: #E5EDF4;
        height:7px;
        border-radius: 25px;
        display:flex;
        .boxBlue {
          width: ${(props) => props.porcentaje}% ;
          height: 100%;
          background: ${(props) => props.colorBar};
          border-radius: 25px;
        }
      } 
    }
    .image{
      height:90px;
      width:90px;
      position:relative;
      img{
        width:100%;
        height:100%;
        object-fit:cover;
        border-radius:5px;
      }
      .porcentaje{
        position:absolute;
        bottom:0px;
        left:0px;
        background:white;
        height:23px;
        width:35px;
        display:flex;
        display: -webkit-box;
        display: -ms-flexbox;
        align-items:center;
        justify-content:center;
        p{
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
          color: #235789;
        }
      }
    }
  }
  @media (min-width:768px){
    .articuloPieCont {
      .porcentaje{
        .title{
          align-items:center;
        }
        &-box{
          height:9px;
        }
      }
    }
  }
`;

const reCalculo = (fontSizeVal) => {
  switch(fontSizeVal) {
    case "30px":
      return "25px";
    case "20px":
      return "20px";
    case "12px":
      return "12px";
    default:
      return "10px"
  }
}
const ArticuloPie = ({
  porcentaje,
  colorBar = colors.blueDark,
  articulo,
  section
}) => {
  // const [size, setSize] = useState("")
  const [showModal, setShowModal] = useState(false);
  const { appFontSize } = useContext(contexto);

  return (
    <>
      <ArticuloPieCont appFontSize={reCalculo(appFontSize)} porcentaje={porcentaje} colorBar={colorBar}>
        <div className="articuloPieCont" onClick={() => setShowModal(true)}>
          <div className="porcentaje">
            <div className='title'>
              <h3>{articulo.titulo}</h3>
            </div>
            <div className="porcentaje-box">
              <span className="boxBlue"></span>
            </div>
          </div>
          <div className="image">
            <img src={articulo.photoURL} alt=""/>
            <span className="porcentaje">
              <p> {porcentaje}% </p>
            </span>
          </div>
        </div>
        <Hr/>
      </ArticuloPieCont>
      <ModalArticulo 
        img={articulo.photoURL}
        titulo={articulo.titulo}
        cuerpo={articulo.cuerpo}
        state={showModal}
        setSate={setShowModal}
        section={section}
      />
    </>
  );
};

export default ArticuloPie;
