import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import draftToHtml from "draftjs-to-html";

import { colors } from "../../utils";
import { obtenerPortadaDiaDeHoy } from "../../consumo_apollo_client/clientePortadas";
import SlideTest from "../auth/SlideTest";
import contexto from "../../Contexto/contexto";

const PanoramaCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 190px - env(safe-area-inset-top));
  background: ${colors.white};
  overflow: auto;
  .panorameCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &-title{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 10px 5%;
      h3{
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 115.87%;
      }
    }
    &-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 10px 5%;
      * {
        margin-bottom: 10px;
        text-align: justify;
        font-size: ${(props) => props.appFontSize ? props.appFontSize : "10px"} !important;
      }
    }
  }
  @media (min-width:768px){
    .panorameCont {
      &-title{
        width:100%;
        max-width:640px;
        margin:auto;
        padding: 15px 0%;
      }
      &-text {
        width:100%;
        max-width:640px;
        margin:auto;
        padding: 15px 0%;
      }
    }
  }
  @media (min-width: 1025px){
    height: calc(100vh - 110px - env(safe-area-inset-top));
  }
`;

const Panorama = ({ target = "nacional", nombre, refresh, setRefresh }) => {
  const [texto, setTexto] = useState(null);
  const { appFontSize } = useContext(contexto);
  useEffect(() => {
    if (refresh === true) {
      obtenerPortadaDiaDeHoy({ tipo: nombre })
        .then((res) => {
          if (target === "Panorama nacional") {
            setTexto(res.panoramaNac);
            setRefresh(false)
          } else {
            setTexto(res.panoramaInt);
          }
        })
        .catch((e) => {
          setTexto(null);
        });
    }

    obtenerPortadaDiaDeHoy({ tipo: nombre })
      .then((res) => {
        if (target === "Panorama nacional") {
          setTexto(res.panoramaNac);
        } else {
          setTexto(res.panoramaInt);
        }
      })
      .catch((e) => {
        setTexto(null);
      });// eslint-disable-next-line
  }, [nombre, target, refresh]);

  if (texto == null)
    return <SlideTest text={"No hay información para mostrar"} />;
  return (
    <PanoramaCont appFontSize={appFontSize}>
      <div className="panorameCont">
        <div className='panorameCont-title'>
          <h3> {target} </h3>
        </div>
        <div
          className="panorameCont-text"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(JSON.parse(texto)),
          }}
        ></div>
      </div>
    </PanoramaCont>
  );
};

export default Panorama;
