import React from "react";
import styled from "styled-components";
// import chroma from "chroma-js";
import { Link } from "react-router-dom";

import { fontSize } from "../../utils";

const ButtonLogInCont = styled.div`
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border: 1px solid ${(props) => props.borderColor};
  cursor: pointer;
  background: ${(props) => props.bg};
  position: relative;
  .buttonLogInCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    &-logo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-left: 8px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    &-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      a {
        text-decoration: none;
      }
      p {
        color: ${(props) => props.textColor};
        font-family: Open Sans;
        font-size: ${fontSize.textRegular};
        font-weight: 700;
        line-height: 19.07px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .buttonLogInCont {
      &-text {
        p {
          font-size: ${fontSize.textMedium};
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .buttonLogInCont {
      &-text {
        p {
          font-size: ${fontSize.textLarge};
        }
      }
      &-logo {
        margin-left: 30px;
      }
    }
  }
`;
const ButtonLogIn = ({
  bg,
  borderColor = bg,
  logo,
  text,
  onClick,
  className = "buttonLog",
  to,
  textColor,
}) => {
  // let color = chroma.contrast(bg, "white") > 2 ? "white" : "black";
  return to ? (
    <ButtonLogInCont
      bg={bg}
      borderColor={borderColor}
      onClick={onClick}
      className={className}
      textColor={textColor}
    >
      <div className="buttonLogInCont">
        <div className="buttonLogInCont-logo">{logo}</div>
        <div className="buttonLogInCont-text">
          <Link to={to}>
            <p> {text} </p>
          </Link>
        </div>
      </div>
    </ButtonLogInCont>
  ) : (
    <ButtonLogInCont
      bg={bg}
      borderColor={borderColor}
      onClick={onClick}
      className={className}
      textColor={textColor}
    >
      <div className="buttonLogInCont">
        <div className="buttonLogInCont-logo">{logo}</div>
        <div className="buttonLogInCont-text">
          <p> {text} </p>
        </div>
      </div>
    </ButtonLogInCont>
  );
};

export default ButtonLogIn;
