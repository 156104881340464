import React, { useState, useContext, useEffect } from "react";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";

import { useHistory } from "react-router-dom";
import { ModalTextCont } from "../../styles";
import contexto from "../../Contexto/contexto";
import { BackButton } from "../modules";

import fire from "firebase/app";
import "@firebase/firestore";

const db = fire.firestore()

const ModalText = () => {

  const history = useHistory()

  const { openStore, getItem, setItem } = useStorageSQLite()

  const { setAppFontSize } = useContext(contexto);

  const [uidV, setUidV] = useState('')
  const [fontSize, setFontSize] = useState('');

  useEffect(() => {
    const fontSize = async () => {
      const store = await openStore({})
      if (store) {
        const uid = await getItem('uid')
        setUidV(uid)
        const textSize = await db.collection('usuario').doc(uid).get()
        if (textSize.data().fontSize !== undefined) setFontSize(textSize.data().fontSize)
        else setFontSize('regular')
      }
    }
    fontSize()
  }, [getItem, openStore])


  const handleFontSize = async (option) => {
    await db.collection('usuario').doc(uidV).update({
      fontSize: option
    })
    setFontSize(option)
  }

  const sizes = {
    small: "12px",
    regular: "20px",
    big: "30px",
  };

  const tamanos = {
    "12px": "small",
    "20px": "regular",
    "30px": "big",
    small: "small",
    regular: "regular",
    big: "big",
  };

  return (
    <ModalTextCont fontS={tamanos[fontSize]}>
      <div className="modalTextCont">
        <div className="modalTextCont-header">
          <BackButton
            onClick={async () => {
              const resOpen = await openStore({});
              if (resOpen) {
                await setItem("letra", sizes[fontSize]);
                setAppFontSize(sizes[fontSize]);
                history.push('/profile')
              }
            }}
          />
        </div>
        <div className="modalTextCont-body">
          <div className="modalTextCont-body-title">
            <h3>Título de muestra</h3>
          </div>
          <div className="modalTextCont-body-text">
            <p>Texto de muestra</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              ornare urna nec augue tristique, vel sollicitudin ligula finibus.
              Donec dignissim metus sem, eget imperdiet risus lacinia non. Ut
              bibendum purus non blandit pretium. Cras lobortis eu odio eu
              posuere. Quisque urna enim, imperdiet sit amet felis sed,
              malesuada egestas turpis. Donec tempus accumsan commodo. Aliquam
              enim quam, lacinia nec tristique sed, tincidunt a nibh.
              Pellentesque ultrices fermentum odio, sed suscipit neque sagittis
              sed. Quisque semper porta lorem. Pellentesque a dolor non quam{" "}
            </p>
          </div>
          <div className="modalTextCont-body-textSmall">
            <p>Aumenta o reduce el tamaño de texto en la pantalla</p>
          </div>
        </div>
        <div className="modalTextCont-footer">
          <div className="modalTextCont-footer-buttons">
            <div
              className={`modalTextCont-footer-buttons-button small${tamanos[fontSize] === "small" ? " on" : ""
                }`}
              onClick={() => handleFontSize("small")}
            >
              T
            </div>
            <div
              className={`modalTextCont-footer-buttons-button regular${tamanos[fontSize] === "regular" ? " on" : ""
                }`}
              onClick={() => handleFontSize("regular")}
            >
              T
            </div>
            <div
              className={`modalTextCont-footer-buttons-button big${tamanos[fontSize] === "big" ? " on" : ""
                }`}
              onClick={() => handleFontSize("big")}
            >
              T
            </div>
          </div>
        </div>
      </div>
    </ModalTextCont>
  );
};

export default ModalText;
