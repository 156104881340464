import React, { useState, useEffect } from "react";
import fire from "firebase/app";
import { cfaSignInGoogle, cfaSignInFacebook } from "capacitor-firebase-auth";
// import fire from "firebase/app";

import ButtonLogIn from "../auth/ButtonLogIn";

import { LoginCont } from "../../styles/index";
import { agregarNombre } from "../../ModuloFirebaseCliente/SignUpAndLogin";

import { ReactComponent as LogoMDM } from "../../assets/LogoPhone.svg";
import { ReactComponent as IconGoogle } from "../../assets/google-icon.svg";
import { ReactComponent as IconFacebook } from "../../assets/icons/Facebook.svg";
import { ReactComponent as IconMLigthMDM } from "../../assets/icons/MDMIcon.svg";
import { ReactComponent as IconMDM } from "../../assets/logoM.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";

import share from "../../assets/icons/ios_share.svg"
import add from "../../assets/icons/ios_add.svg"
import { isMobile } from "react-device-detect";

import { useHistory } from "react-router-dom";


import styled from "styled-components";
import STRIPE_SERVICE from "../../services/stripeServices";

const BannerToInstallApp = styled.div`
  position: absolute;
  top: 0;
  left:0;
  width: 100vw;
  height: 50px;
  display: flex;
  background-color: #FFE990;
  padding: 0 5%;
  align-items: center;
  justify-content: space-evenly;
  svg{
    width: 40px;
  }
  p{
    font-size: 12px;
    width: 200px;
  }
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .share {
    width: 15px;
    height: 15px;
  }
  .add {
    width: 15px;
    height: 15px;
  }
`



const LoginHome = ({ validarInner, openStore, setItem, setNavegation }) => {
  const db = fire.firestore()

  const history = useHistory();

  const [beforeInstall, setBeforeInstall] = useState(null)
  const [showIosBanner, setshowIosBanner] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  const handleCreateStripeCustomer = async (uid) => {
    await STRIPE_SERVICE.createCustomer(uid);
  }

  const procesarData = (user, displauName, token, tipo) => {
    agregarNombre(user, displauName)
      .then(() => {
        loadData(token, tipo, displauName, user)
          .then((res) => {
            validarInner(user)
          })
          .catch((e) => {
            console.error(e)
          });
      })
      .catch((e) => { console.error(e)});
  };

  const loadData = async (token, tipo, displauName, uid) => {
    const resOpen = await openStore({});
    console.log('entro a load data')
    const user = await db.collection('usuario').doc(uid).get()
    console.log('info del user', user.data())

    if(!user.data().stripeId){
      await handleCreateStripeCustomer(uid)
    }
    if (resOpen) {
      const sessionTokenArray = user.data().sessionToken;
      if(sessionTokenArray.length){
        await setItem('sessionToken', sessionTokenArray[sessionTokenArray.length - 1])
      }
      await setItem("uid", uid);
      await setItem("token", token);
      await setItem("tipoUsuario", tipo);
      await setItem("nombre", displauName);
      return "OK";
    }
    return "NO";
  };

  const procesarLoginSocial = (user) => {
    console.log('usuario', user)
    user.getIdToken().then((token) => {
      procesarData(user.uid, user.displayName, token, "OA");
    });
  };

  const ErrorManager = (e) => {
    if (e.code === "auth/user-disabled") {
      alert("La cuenta fue cancelada.");
    }
  };

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isInStandaloneMode()) {
      setshowIosBanner(true)
    }

  }, [])

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.W
      setBeforeInstall(e)
      // Update UI notify the user they can install the PWA
      setShowBanner(true)
    });
  }, [])

  const handleAppInstall = () => {
    if (beforeInstall !== null) {
      beforeInstall.prompt();
      beforeInstall.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setBeforeInstall(null)
      });
    }
  }

  return (
    <LoginCont>
      <div className="loginCont">
        <div className="loginCont-logo">
          <LogoMDM className="logoMDM" />
        </div>
        <div className="loginCont-logButtons">
          <p className="buttonTitle"> Accede para ver el contenido </p>
          <ButtonLogIn
            logo={<IconGoogle />}
            text="Acceder con Google"
            borderColor="#E0E0E0"
            bg="#fff"
            textColor="#575757"
            onClick={() => {
              cfaSignInGoogle()
                .toPromise()
                .then((user) => {
                  procesarLoginSocial(user);
                })
                .catch(async (e) => {
                  ErrorManager(e);
                });
            }}
          />
          <ButtonLogIn
            logo={<IconFacebook />}
            text="Acceder con Facebook"
            borderColor="#E0E0E0"
            bg="#fff"
            textColor="#575757"
            onClick={() => {
              cfaSignInFacebook()
                .toPromise()
                .then((user) => {
                  procesarLoginSocial(user);
                })
                .catch((e) => {
                  ErrorManager(e);
                });
            }}
          />
          <ButtonLogIn
            logo={<IconMLigthMDM />}
            text="Acceder con Mail"
            borderColor="#E0E0E0"
            bg="#fff"
            textColor="#575757"
            onClick={() => history.push('/login')}
          />
          <div className="loginCont-register">
            <p>¿Aún no eres usuario?</p>
            <p
              className="registerLog"
              onClick={() => history.push('/register')}
            >
              Regístrate aquí
            </p>
          </div>
        </div>
      </div>
      {isMobile &&
        showBanner &&
        <BannerToInstallApp onClick={() => handleAppInstall()}>
          <IconMDM />
          <p>Instala la aplicación en tu celular haciendo click aquí.</p>
          <Close onClick={() => setShowBanner(false)} />
        </BannerToInstallApp>
      }
      {isMobile &&
        showIosBanner &&
        <BannerToInstallApp
          onClick={() => setshowIosBanner(false)}
        >
          <IconMDM />
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <p style={{
              textAlign: 'left',
              fontSize: '11px'
            }}>
              Instala esta app en tu iPhone: Presiona
            </p>
            <p style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '11px'
            }}>
              <img src={share} className='share' alt='share' />
              y añádela a tu pantalla de inicio
              <img src={add} className='add' alt='add' />.
            </p>
          </div>
          <div
            onClick={() => setshowIosBanner(false)}
            style={{
              width: '20px',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Close
              style={{
                width: '10px',
                height: '10px'
              }}
            />

          </div>
        </BannerToInstallApp>
      }
    </LoginCont>
  );
};

export default LoginHome;
