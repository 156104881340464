import React from 'react';
import { EncabezadoCont } from '../../styles';
import { Plugins, Capacitor } from "@capacitor/core";
import { Hr } from '../modules';


const Encabezado = ({ img, url, title, fontSizeValue }) => {
    const { App } = Plugins;
  const platform = Capacitor.getPlatform();

  const openURL = async () => {
    const ua = navigator.userAgent.toLowerCase();
    let ventana = null;
    if (ua.indexOf("safari") !== -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        ventana = window.open(url, "about:blank", "_blank");
      }
    }

    if (platform === "web") {
      if (ua.indexOf("safari") !== -1) {
        if (ua.indexOf("chrome") > -1) {
          window.open(
            url,
            "_blank",
            "status=yes, toolbar=no, menubar=no, location=no,addressbar=no"
          );
        } else {
          ventana.location = url;
        }
      }
    } else {
      await App.openUrl(url);
    }
  };
  return(
    <EncabezadoCont fontSizeValue={fontSizeValue} onClick={openURL}>
      <div className="encabezadoCont">
        <h3 className="encabezadoCont-title"> {title} </h3>
        <div className="encabezadoCont-link">
          <img className="encabezadoCont-link-image" src={img} alt="logo"/>
        </div>
      </div>
      <Hr />
    </EncabezadoCont>
  )
};

export default Encabezado;