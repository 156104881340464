import React from 'react';
import { IonModal, IonContent } from "@ionic/react";

// import { BackButton } from "../modules";
import { ModalSuccessCont } from "../../styles";

import { ReactComponent as IconSuccess } from "../../assets/icons/successIcon.svg";
import { leaveAnimation, enterAnimation } from "../../utils";
import Button from './Button';

const ModalSuccess = ({ navegation, setNavegation }) => {
  return(
    <IonContent>
      <IonModal
        isOpen={navegation === "success" && true}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass='fullscreen'
      >
        <ModalSuccessCont>
          <div className="modalSuccessCont">
            <div className="modalSuccessCont-header">
            </div>
            <div className="modalSuccessCont-body">
              <div className="modalSuccessCont-body-cont">
                <div className="modalSuccessCont-body-cont-icon">
                  <IconSuccess />
                </div>
                <div className="modalSuccessCont-body-cont-title">
                  <p>Tu contraseña se cambió exitosamente</p>
                </div>
              </div>
              <Button onClick={() => setNavegation("")} />
            </div>
          </div>
        </ModalSuccessCont>
      </IonModal>
    </IonContent>
  )
};

export default ModalSuccess;