import React from "react";
import styled from "styled-components";


const IndicadorEconomicoCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 30px;
  padding: 0 5%;
  max-width: 640px;
  margin: auto;
  .incadorCont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    text-align:center;
    &-tipo {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }
    &-arrow {
      margin-left: 30px;
      font-size: 14px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 149.63%;
    }
    &-price {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      margin-left: 40px;
      width: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
  @media (min-width: 768px){
    padding:0;
  }
`;
const IndicadorEconomicoMetales = ({ tipo, aumento, price }) => {
  return (
    <IndicadorEconomicoCont>
      <div className="incadorCont">
        <div className="incadorCont-tipo">{tipo}</div>
        <div className="incadorCont-arrow">USD/MT</div>
        <div className="incadorCont-price">{price}</div>
      </div>
    </IndicadorEconomicoCont>
  );
};

export default IndicadorEconomicoMetales;
