import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { useHistory } from "react-router-dom";

import { ModalLoginCont } from "../../styles";
import { validarSuscripcion } from "../../ModuloFirebaseCliente/SignUpAndLogin";
import Button from "../auth/Button";
import Input from "../auth/Input";
import InputPass from "../auth/InputPass";
import { BackButton } from "../modules";
import { login } from "../../ModuloFirebaseCliente/SignUpAndLogin";


import fire from "firebase/app";
import "@firebase/firestore";

const db = fire.firestore()

const ModalLogin = () => {

  const { openStore, getItem, setItem } = useStorageSQLite();
  const history = useHistory();

  const validationLogin = Yup.object().shape({
    correo: Yup.string().required("Ingresa tu correo."),
    password: Yup.string().required("Ingresa una contraseña."),
  });
  const {
    handleSubmit: handleSubLogin,
    register: registerLogin,
    errors: errorsLogin,
    setError: setErrorLogin,
  } = useForm({
    resolver: yupResolver(validationLogin),
  });
  const submitLogin = (data) => {
    login(data.correo, data.password)
      .then(async (res) => {
        const resOpen = await openStore({});
        if (resOpen) {
          const user = await db.collection("usuario").doc(res.uid).get();
          console.log('data en login', user.data())
          await setItem("uid", res.uid);
          await setItem("token", res.token);
          await setItem("tipoUsuario", "correo");
          await setItem("nombre", res.nombre);
          await setItem("correo", data.correo);
          await setItem('sessionToken', res.sessionToken)
          validarInner(res.uid);
        }
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          setErrorLogin("correo", {
            type: "manual",
            message:
              "No hemos encontrado una cuenta registrada con ese correo.",
          });
        }
        if (e.code === "auth/wrong-password") {
          setErrorLogin("password", {
            type: "manual",
            message: "Contraseña incorrecta.",
          });
        }
        if (e.code === "auth/too-many-requests") {
          setErrorLogin("password", {
            type: "manual",
            message: "Espera unos instantes antes de intentarlo de nuevo.",
          });
        }
        if (e.code === "auth/user-disabled") {
          setErrorLogin("correo", {
            type: "manual",
            message: "Tu cuenta ha sido cancelada.",
          });
        }
      });
  };
  useEffect(() => {
    const validarInner = async (uid) => {
      if (uid) {
        await validarSuscripcion(uid);
      }
    };
    const validar = async () => {
      const enter = await openStore({});
      if (enter) {
        const user = await getItem("uid");
        validarInner(user);
      }
    };
    validar();
  }, [openStore, getItem, history]);

  const validarInner = async (uid) => {
    if (uid) {
      const res = await validarSuscripcion(uid);
      switch (res) {
        case 'failed':
          history.push('/membership');
          break;
        case 'success':
          history.push('/app');
          break;
        case 'stripePortal':
          history.push('/payment-failure')
          break;
        default:
          history.push('/');
          break;
      }
    }
  };

  return (
    <ModalLoginCont>
      <div className="modalLogCont">
        <div className="modalLogCont-header">
          <BackButton onClick={() => history.push('/')} />
        </div>
        <div className="modalLogCont-body">
          <div className="modalLogCont-body-title">
            <p>Ingresa</p>
          </div>
          <form
            className="modalLogCont-body-inputs"
            onSubmit={handleSubLogin(submitLogin)}
          >
            <Input
              name="correo"
              placeholder="Ingresa tu correo"
              title="Correo"
              register={registerLogin()}
              refT={false}
              error={errorsLogin.correo}
              textError={errorsLogin.correo?.message}
            />
            <InputPass
              name="password"
              placeholder="Ingresa tu contraseña"
              title="Contraseña"
              register={registerLogin()}
              refT={false}
              error={errorsLogin.password}
              textError={errorsLogin.password?.message}
            />
            <div className="modalLogCont-body-inputs-rem">
              <p className="remName"
                onClick={() => { history.push('/remember-pass'); }}
              >
                Olvidé mi contraseña
              </p>
            </div>
            <div className="buttonContLog">
              <Button />
            </div>
          </form>
        </div>
      </div>
    </ModalLoginCont>
  );
};

export default ModalLogin;
