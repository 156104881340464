import { crearCliente } from "./clientGenerator";
const { gql } = require("@apollo/client");
const client = crearCliente();
/**
 * Solo llama el metodo y resuelve con promesa o async
 * @param datos
 * datos={id:"id de la opinion"}
 * @return Todos los campos de la opinion
 **/
const obtenerOpinionporId = (datos) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query opinionById($id: MongoID!) {
            opinionById(_id: $id) {
              _id
              titulo
              cuerpo
              categoria
              font
            }
          }
        `,
        variables: datos,
      })
      .then((result) => {
        resolve(result.data.opinionById);
      });
  });
};

const opionesDiaDeHoy = (data) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query opinionDiaDeHoy($tipo: String) {
            opinionDiaDeHoy(tipo: $tipo) {
              _id
              titulo
              cuerpo
              categoria
              autor
            }
          }
        `,
        variables: data,
      })
      .then((result) => {
        resolve(result.data.opinionDiaDeHoy);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { obtenerOpinionporId, opionesDiaDeHoy };
