import React, { useState, useRef, useEffect } from "react";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import ProfileHome from "../components/profile/Profile";
import ModalPass from "../components/profile/ModalPass";
import ModalSuccess from "../components/auth/ModalSuccess";
import { Capacitor } from "@capacitor/core";
import { validarSuscripcion } from "../ModuloFirebaseCliente/SignUpAndLogin";

const Profile = () => {
  const { openStore, getItem, removeItem, clear } = useStorageSQLite();
  const [navegation, setNavegation] = useState("");
  const inputFileRef = useRef(null);
  const [correo, setCorreo] = useState(null);
  const [nombre, setNombre] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      const validarInner = async (uid) => {
        if (uid) {
          const res = await validarSuscripcion(uid);
          if (res === 'failed') {
            history.push("/");
          }
        }
      };
      const validar = async () => {
        var enter = await openStore({});
        if (enter) {
          var user = await getItem("uid");
          if (user) {
            validarInner(user);
          } else {
            history.push("/");
          }
        }
      };
      validar();
    }
    const cargarCorreo = async () => {
      const resOpen = await openStore({});
      if (resOpen) {
        var email = await getItem("correo");
        setCorreo(email ? email : null);
      }
    };
    const cargarNombre = async () => {
      const resOpen = await openStore({});
      if (resOpen) {
        var name = await getItem("nombre");
        setNombre(name ? name : null);
      }
    };
    cargarCorreo();
    cargarNombre();
  }, [getItem, openStore, history]);
  console.log();
  return (
    <>
      <Navbar title="profile" />
      <ProfileHome
        setNavegation={setNavegation}
        inputFileRef={inputFileRef}
        removeItem={removeItem}
        correo={correo}
        nombre={nombre}
        clear={clear}
        history={history}
      />
      <ModalPass
        navegation={navegation}
        setNavegation={setNavegation}
        getItem={getItem}
      />
      <ModalSuccess navegation={navegation} setNavegation={setNavegation} />
    </>
  );
};

export default Profile;
