import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SwiperCore, { Thumbs, Pagination } from "swiper";
import { validarSuscripcion } from "../ModuloFirebaseCliente/SignUpAndLogin";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { Capacitor } from "@capacitor/core";
import { isMobile } from "react-device-detect";

import MenuFooter from "../components/MenuFooter";
import ButtonThumbs from "../components/auth/ButtonThumbs";
import ArrowThumbLeft from "../components/auth/ArrowThumbLeft";
import ArrowThumbRight from "../components/auth/ArrowThumbRight";
import { params } from "../utils/infoDummy";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";

import Destacado from "../components/index/Destacado";
import AgendaDia from "../components/index/AgendaDia";
import Panorama from "../components/index/Panorama";
import Encabezados from "../components/index/Encabezados";
import Semaforo from "../components/index/Semaforo";
import Opinion from "../components/index/Opinion";
import OtrosArticulos from "../components/index/OtrosArticulos";
import IndicadoresEconomicos from "../components/index/IndicadoresEconomicos";

const IndexCont = styled.div`
  height: calc(100vh - 140px - env(safe-area-inset-top));
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: white;
    p {
      font-family: Crimson Pro;
      font-size: 14px;
    }
  }
  .swiper-slide {
    overflow: hidden;
  }
  .swiper-desktop {
    margin: 0% 10%;
  }
  .indexCont {
    
  }
  @media (min-width: 1025px) {
    width: calc(100% - 144px);
    margin-left: 144px;
    overflow: hidden;
    height: calc(100vh - 60px - env(safe-area-inset-top));
    .indexCont {
      padding: 0;
    }
  }
`;

SwiperCore.use([Thumbs, Pagination]);
const Index = () => {
  const history = useHistory();
  const width = window.innerWidth;
  const isMobileWidth = width <= 768 ? true : false;

  const [refreshData, setRefreshData] = useState(false);
  const { openStore, getItem } = useStorageSQLite();
  const [slideState, setSlideState] = useState(null);
  const [thumbState, setThumbState] = useState(0);
  const [isFirstThumb, setIsFirstThumb] = useState(true);
  const [isLastThumb, setIsLastThumb] = useState(false);
  const [menuFooter, setMenuFooter] = useState("agenda");
  const [ready, setReady] = useState(null);

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      const validarInner = async (uid) => {
        if (uid) {
          const res = await validarSuscripcion(uid);
          switch (res) {
            case "failed":
              history.push("/membership");
              break;
            case "success":
              const token = await getItem("token");
              window.vars = {
                token: token,
              };
              setReady(true);
              break;
            case "stripePortal":
              history.push("/payment-failure");
              break;
            default:
              history.push("/");
              break;
          }
        }
      };
      const validar = async () => {
        var enter = await openStore({});
        if (enter) {
          const user = await getItem("uid");
          if (user) {
            validarInner(user);
          } else {
            history.push("/");
          }
        }
      };
      validar();
    }
    // eslint-disable-next-line
  }, [openStore, getItem]);

  const handleGetDataOnRefresh = async () => {
    if (Capacitor.getPlatform() === "web") {
      const validarInner = async (uid) => {
        if (uid) {
          const res = await validarSuscripcion(uid);
          switch (res) {
            case "failed":
              history.push("/membership");
              break;
            case "success":
              const token = await getItem("token");
              window.vars = {
                token: token,
              };
              setReady(true);
              break;
            case "stripePortal":
              history.push("/payment-failure");
              break;
            default:
              history.push("/");
              break;
          }
        }
      };

      setRefreshData(true);

      const validar = async () => {
        var enter = await openStore({});
        if (enter) {
          const user = await getItem("uid");
          if (user) {
            validarInner(user);
          } else {
            history.push("/");
          }
        }
      };
      validar();
    }
  };

  if (!ready) return <></>;

  return (
    <>
      <PullToRefresh
        onRefresh={() => handleGetDataOnRefresh()}
        isPullable={true}
      >
        <Navbar title={menuFooter} width/>
        <IndexCont>
          <div className="indexCont">
            <Swiper
              spaceBetween={10}
              slidesPerView={
                isMobileWidth
                  ? params[menuFooter].length === 1
                    ? 1
                    : params[menuFooter].length === 2
                    ? 2
                    : params[menuFooter].length >= 3 && 3
                  : params[menuFooter].length
              }
              onSwiper={(data) => {
                setSlideState(data);
              }}
              onSlideChange={(e) => {
                setIsLastThumb(e.isEnd);
                setIsFirstThumb(e.isBeginning);
              }}
              watchSlidesProgress
              centeredSlides
              centeredSlidesBounds
              className={isMobileWidth ? "" : "swiper-desktop"}
              freeMode={true}
            >
              {params[menuFooter].map((thumbs, i) => (
                <SwiperSlide key={i}>
                  <ButtonThumbs
                    text={thumbs.title}
                    state={i === thumbState ? true : false}
                  />
                </SwiperSlide>
              ))}
              <ArrowThumbLeft state={isMobileWidth ? isFirstThumb : true} />
              <ArrowThumbRight state={isMobileWidth ? isLastThumb : true} />
            </Swiper>
            <Swiper
              slidesPerView={1}
              initialSlide={0}
              thumbs={{ swiper: slideState }}
              onSlideChange={(e) => setThumbState(e.realIndex)}
              allowTouchMove={isMobile ? true : false}
            >
              {params[menuFooter].map((slide, i) => (
                <SwiperSlide key={i}>
                  {slide.id === "destacado" && (
                    <Destacado
                      nombre={slide.nombre}
                      id={slide.id}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                    />
                  )}
                  {slide.id === "agendaDia" && (
                    <AgendaDia
                      key={"ss"}
                      nombre={slide.nombre}
                      id={slide.id}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                      section={menuFooter}
                    />
                  )}
                  {slide.id === "panoramaNa" && (
                    <Panorama
                      nombre={slide.nombre}
                      target="Panorama nacional"
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                    />
                  )}
                  {slide.id === "panoramaIn" && (
                    <Panorama
                      nombre={slide.nombre}
                      target="Panorama internacional"
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                    />
                  )}
                  {slide.id === "encabezados" && (
                    <Encabezados
                      id={slide.id}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                    />
                  )}
                  {slide.id === "semaforo" && (
                    <Semaforo
                      id={slide.id}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                      section={menuFooter}
                    />
                  )}
                  {slide.id === "opinion" && (
                    <Opinion
                      nombre={slide.nombre}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                      id={slide.id}
                      section={menuFooter}
                    />
                  )}
                  {slide.id === "otrosArticulos" && (
                    <OtrosArticulos
                      nombre={slide.nombre}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                      id={slide.id}
                      section={menuFooter}
                    />
                  )}
                  {slide.id === "indicadoresEconomicos" && (
                    <IndicadoresEconomicos
                      nombre={slide.nombre}
                      refresh={refreshData}
                      setRefresh={setRefreshData}
                      id={slide.id}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </IndexCont>
      </PullToRefresh>
      <MenuFooter stateFooter={menuFooter} setStateFooter={setMenuFooter} />
    </>
  );
};

export default Index;
