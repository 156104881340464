import React, { useState, useEffect } from "react";
import { Plugins, Capacitor } from "@capacitor/core";
import { useStorageSQLite } from "react-data-storage-sqlite-hook/dist";
import { ReactComponent as IconCamera } from "../../assets/icons/iconCamera.svg";
import { ReactComponent as NextButton } from "../../assets/icons/nextButton.svg";
import { ProfileCont } from "../../styles";
import ImgFileDummy from "../../assets/images/dummy-user.jpg";
import { obtenerImagenURL } from "../../consumo_apollo_client/clienteImagen";
import STRIPE_SERVICE from '../../services/stripeServices';
import {
  actualizarImagen,
  obtenerImagen,
} from "../../ModuloFirebaseCliente/SignUpAndLogin";
import { deshabilitarCuenta } from "../../consumo_apollo_client/clienteCuenta";

const { App } = Plugins;

const Profile = ({
  setNavegation,
  inputFileRef,
  removeItem,
  correo,
  nombre,
  clear,
  history
}) => {
  const { openStore, getItem, setItem } = useStorageSQLite();
  const [imgFile, setImgFile] = useState('#eee');
  useEffect(() => {
    const cargarImagen = async () => {
      try {
        await openStore({});
        var uid = await getItem("uid");
        var imagen = await obtenerImagen(uid);
        if (imagen) {
          setImgFile(imagen);
        } else {
          setImgFile(ImgFileDummy);
        }
      } catch (e) {
        setImgFile(ImgFileDummy);
      }
    };
    cargarImagen();
  }, [openStore, getItem]);
  const readURL = async () => {
    if (inputFileRef.current.files && inputFileRef.current.files[0]) {
      var reader = new FileReader();
      reader.onload = async (e) => {
        var imagen = await obtenerImagenURL({
          Imagen: e.target.result,
        });
        var uid = await getItem("uid");
        if (await actualizarImagen(uid, imagen)) {
          setImgFile(imagen);
          await setItem("img", imagen);
        }
      };
      reader.readAsDataURL(inputFileRef.current.files[0]);
    }
  };
  // eslint-disable-next-line
  const deshabilitarCuentaFunc = async () => {
    var uid = await getItem("uid");
    if (uid) {
      deshabilitarCuenta({ uid: uid }).then(async () => {
        await clear();
        alert("Cuenta cancelada");
        history.push("/");
      });
    }
  };


  const handleRedirectStripe = async (uid) => {
    return await STRIPE_SERVICE.redirectToStripePortal({ uid })
  }

  const redirectToStripePortal = async () => {
    const platform = Capacitor.getPlatform()
    const uid = await getItem('uid')
    const data = await handleRedirectStripe(uid);
    const { data: { url } } = data;

    var ua = navigator.userAgent.toLowerCase();
    let ventana = null;
    if (ua.indexOf("safari") !== -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        ventana = window.open("about:blank", "_blank");
      }
    }

    if (platform === "web") {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") !== -1) {
        if (ua.indexOf("chrome") > -1) {
          window.open(
            url,
            "_blank",
            "status=yes, toolbar=no, menubar=no, location=no,addressbar=no"
          );
        } else {
          ventana.location = url;
        }
      }else if(ua.indexOf("firefox") > -1){
        window.open(
          url,
          "_blank",
          "status=yes,popup,toolbar=no, menubar=no, location=no,addressbar=no"
        );
      }
    } else {
      await App.openUrl(url);
    }
  }
  // console.log(async () => await getItem('nombre'));
  return (
    <ProfileCont imgFile={imgFile}>
      <div className="profileCont">
        <div className="profileCont-profile">
          <div className="profileCont-profile-fotoCover">
            <div className="profileCont-profile-fotoCover-contButton">
              {correo && (
                <div className="profileCont-profile-fotoCover-contButton-button">
                  <span className="upload">
                    <IconCamera />
                  </span>
                  <span className="upload">
                  </span>
                  <input
                    ref={(e) => {
                      inputFileRef.current = e;
                    }}
                    name="imageCover"
                    id="test1"
                    type="file"
                    onChange={readURL}
                    accept="image/png, image/jpeg"
                  />
                </div>
              )}
              <div className="profileCont-profile-fotoCover-contButton-img"></div>
            </div>
            <div className="profileCont-profile-fotoCover-text">
              <h3> {nombre} </h3>
              {correo && <span> {correo} </span>}
            </div>
          </div>
          {correo !== null && (
            <div
              className="profileCont-profile-pass"
              onClick={() => setNavegation("pass")}
            >
              <div className="profileCont-profile-pass-cont">
                <p>Cambiar mi contraseña</p>
                <NextButton />
              </div>
            </div>
          )}
          <div
            className="profileCont-profile-letra"
            onClick={() => history.push('/profile/text')}
          >
            <div className="profileCont-profile-letra-cont">
              <p>Configuración de letra</p>
              <NextButton />
            </div>
          </div>
          <div
            className="profileCont-profile-letra"
            onClick={() => redirectToStripePortal()}
          >
            <div className="profileCont-profile-letra-cont">
              <p>Gestionar mi cuenta</p>
              <NextButton />
            </div>
          </div>
        </div>

        <div
          style={{
            padding: '30px 5%',
            fontSize: '13px',
          }}
        >
          <p>¿Necesitas factura?</p>
          <p>Escíbenos a
            <a
              href='mailto:facturacion@elmediodemedios.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{
                color: '#235789',
                fontWeight: '600'
              }}
            >
              facturacion@elmediodemedios.com
            </a>
          </p>
        </div>
        <div className="profileCont-cancelAcout">
          <div
            onClick={async () => {
              await removeItem("uid");
              await removeItem("token");
              await removeItem("tipoUsuario");
              await removeItem("correo");
              await removeItem("letra");
              await removeItem("img");
              await removeItem("sessionToken");
              await clear();
              history.push("/");
            }}
            className="profileCont-cancelAcout-cont">
            <p>Cerrar sesión</p>
          </div>
        </div>
      </div>
    </ProfileCont>
  );
};

export default Profile;
